const styles = {
  header: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
  },
  iconTitle: {
    width: '19px',
    height: '19px',
    color: 'primary.greyMetal',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'auto',
    color: 'primary.greyMetal',
  },
  buttonShare: {
    height: '32px',
  },
  buttonShareIcon: {
    width: '16px',
    height: '16px',
    marginRight: '6px',
  },
  description: {
    marginTop: '20px',
    fontSize: '14px',
    fontWeight: 300,
    color: 'primary.greyMetal',
  },
};

export default styles;
