import i18n from '../../i18n';
import defaultTranslations from '../../translations.json';

export const loadCompanyTranslations = async (clientSpecificTranslation) => {
  if (!clientSpecificTranslation) {
    Object.keys(defaultTranslations).forEach((lang) => {
      if (defaultTranslations[lang]?.translation) {
        i18n.addResourceBundle(lang, 'translation', defaultTranslations[lang].translation, true, true);
      }
    });
    return defaultTranslations;
  }

  try {
    const companyTranslations = await import(`./${clientSpecificTranslation}.json`);
    const mergedTranslations = deepMerge(defaultTranslations, companyTranslations.default);
    updateTranslations(mergedTranslations);
    return mergedTranslations;
  } catch (error) {
    console.warn(`Failed to load company translations for ${clientSpecificTranslation}:`, error);
    updateTranslations(defaultTranslations);
    return defaultTranslations;
  }
};

const updateTranslations = (translations) => {
  const availableLanguages = Object.keys(translations);
  availableLanguages.forEach((lang) => {
    if (translations[lang]?.translation) {
      i18n.addResourceBundle(lang, 'translation', translations[lang].translation, true, true);
    }
  });

  i18n.reloadResources();
};

const deepMerge = (target, source) => {
  const output = { ...target };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = deepMerge(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output;
};

const isObject = (item) => item && typeof item === 'object' && !Array.isArray(item);
