import React from 'react';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import { Box, Typography, CircularProgress } from '@mui/material';
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';
import styles from './ThemesCard.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThemesGrid from 'ui/components/ThemesGrid/ThemesGrid';
import ButtonFullScreen from 'ui/components/ButtonFullScreen/ButtonFullScreen';

const themesEmptySvg = `${process.env.PUBLIC_URL}/uploads/images/theme-empty.svg`;

const ThemesCard = ({ themes, isLoading, isAuthorizeScroll = false, isShowFullScreenButton = false, sxCard = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickRow = (theme) => {
    navigate(`/programs#theme-${theme.id}`);
  };

  const content = () => {
    if (isLoading) {
      return (
        <Box sx={styles.loadingContainer}>
          <CircularProgress color="primary" size="32px" />
        </Box>
      );
    }

    if (!themes?.length) {
      return (
        <EmptyStateCard
          hiddenCard
          description={t('themes_card.empty_state.description')}
          isLoading={isLoading}
          src={themesEmptySvg}
          subtitle={t('themes_card.empty_state.subtitle')}
        />
      );
    }

    return <ThemesGrid isAuthorizeScroll={isAuthorizeScroll} themes={themes} onClickRow={onClickRow} />;
  };

  const onClickFullScreen = () => {
    navigate('/themes');
  };

  return (
    <DashboardCard sx={{ ...styles.card, ...sxCard }}>
      <Box sx={styles.header}>
        <Typography sx={styles.title}>{t('themes_card.title')}</Typography>
        {isShowFullScreenButton && <ButtonFullScreen onClick={onClickFullScreen} />}
      </Box>

      {content()}
    </DashboardCard>
  );
};

export default ThemesCard;
