import React, { useState, useEffect } from 'react';
import { useDashboardListQuery, useMeQuery, useListFormatThemeQuery } from 'hooks/queries';
import { Box, Stack, Typography, Grid } from '@mui/material';
import RolesDropdown from 'ui/components/RolesDropdown/RolesDropdown';
import StatisticsCard from '../components/StatisticsCard/StatisticsCard';
import { useTranslation } from 'react-i18next';
import ToDoListShortcut from '../components/ToDoListShortcut/ToDoListShortcut';
import Resources from 'ui/pages/program/components/Resources/Resources.jsx';
import SharingLinksCard from '../components/SharingLinksCard/SharingLinksCard';
import HighlightedResourceCard from '../components/HighlightedResourceCard/HighlightedResourceCard';
import ThemesCard from '../components/ThemesCard/ThemesCard';
import styles from './HomeRh.styles';
import { formatDate } from 'utils/dateUtils';

const programLaunchedIcon = `${process.env.PUBLIC_URL}/uploads/images/program-launched.svg`;
const activeProgramsIcon = `${process.env.PUBLIC_URL}/uploads/images/active-programs.svg`;
const completedSessionsIcon = `${process.env.PUBLIC_URL}/uploads/images/completed-sessions.svg`;
const expertsIcon = `${process.env.PUBLIC_URL}/uploads/images/experts.svg`;
const clientsIcon = `${process.env.PUBLIC_URL}/uploads/images/clients.svg`;

const HomeRh = () => {
  const { data: user } = useMeQuery();
  const { t } = useTranslation();
  const [toStatistics, setToStatistics] = useState('');

  const { data: listThemeData, isLoading: isLoadingThemes } = useListFormatThemeQuery(user.company.id);

  const { data: statisticsData, isLoading: isLoadingStatistics } = useDashboardListQuery(
    {
      from: user?.company?.createdAt,
      to: toStatistics,
    },
    { enabled: !!(toStatistics && user?.company?.createdAt) }
  );

  const { activePairing, launchedPairings, registred_coachees, registred_coaches } =
    statisticsData?.pairingReport || {};
  const { completedSessions } = statisticsData?.sessionReport || {};

  const statisticsConfig = [
    { id: 'program_launched', value: launchedPairings || 0, icon: <Box component="img" src={programLaunchedIcon} /> },
    { id: 'active_programs', value: activePairing || 0, icon: <Box component="img" src={activeProgramsIcon} /> },
    {
      id: 'completed_sessions',
      value: completedSessions || 0,
      icon: <Box component="img" src={completedSessionsIcon} />,
    },
    { id: 'experts', value: registred_coaches || 0, icon: <Box component="img" src={expertsIcon} /> },
    { id: 'clients', value: registred_coachees || 0, icon: <Box component="img" src={clientsIcon} /> },
  ];

  const company = user?.company || {};
  const { expertLabel, clientLabel } = company;

  useEffect(() => {
    setToStatistics(new Date().toISOString());
  }, []);

  return (
    <Stack sx={styles.container}>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Typography sx={styles.welcomeTitle}>{t('home_page.user.title', { userName: user.firstname })}</Typography>

        <RolesDropdown user={user} />
      </Box>

      <Grid container spacing={2}>
        {statisticsConfig.map(({ id, value, icon }) => {
          return (
            <Grid key={id} item md={4} sm={12} xs={12} lg={2.4}>
              <StatisticsCard
                isLoading={isLoadingStatistics}
                subtitle={t('statistics_rh.subtitle', { date: formatDate(company.createdAt) })}
                title={t(`statistics_rh.${id}.title`, { count: value, expertLabel, clientLabel })}
                value={value}
                icon={icon}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={4}>
        <Grid item md={12} sm={12} xs={12} lg={8}>
          <ThemesCard themes={listThemeData?.slice(0, 3)} isLoading={isLoadingThemes} isShowFullScreenButton />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={4}>
          <ToDoListShortcut />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={6}>
          <SharingLinksCard company={company} />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={6}>
          <HighlightedResourceCard
            company={user.company}
            deleteIcon
            isLargeVersion
            role={user.role}
            sxContainer={{ padding: '32px' }}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} lg={12}>
          <Typography sx={styles.resourcesTitle}>{t('company_resources')}</Typography>

          <Resources isRh={true} companyId={user.companyId} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HomeRh;
