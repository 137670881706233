const borderColor = '#EAECF0';

const styles = (theme) => ({
  container: {
    marginTop: '46px',
    position: 'relative',
    display: 'flex',
  },
  card: {
    borderRadius: '8px',
    border: `1px solid ${borderColor}`,
    padding: '24px',
    position: 'relative',
  },
  generalInformationCard: {
    paddingTop: '110px',
    position: 'relative',
  },
  avatar: {
    height: '140px',
    width: '140px',
    position: 'absolute',
    top: '-55px',
    background: 'white',
    border: `1px solid ${borderColor}`,
  },
  name: {
    fontSize: '26px',
    fontWeight: '600',
  },
  title: {
    fontSize: '16px',
    color: `${theme.palette.primary.black}CC`,
  },
  chips: {
    paddingTop: '16px',
    flexWrap: 'wrap',
    display: 'flex',
    gap: '8px',
  },
  chipIcon: {
    fontSize: '0.9rem',
    marginRight: '4px',
  },
  containerContactDetails: {
    marginTop: '24px',
  },
  contactDetailsTitle: {
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '16px',
    marginBottom: '12px',
  },
  iconContractDetails: {
    width: '16px',
    marginRight: '6px',
    color: '#0000008A',
  },
  valueContractDetails: {
    fontSize: '14px',
  },
  contactDetailsLine: {
    height: '30px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  containerMeet: {
    marginTop: '24px',
    marginBottom: '8px',
  },
  secondaryTitle: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: 'primary.grey',
    marginBottom: '8px',
  },
  contentMeet: {
    flexWrap: 'wrap',
    display: 'flex',
    gap: '16px',
  },
  meetItem: {
    display: 'flex',
    alignItems: 'center',
  },
  meetItemIcon: {
    color: 'primary.grey',
    marginRight: '4px',
  },
  aboutMeTitle: {
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '16px',
  },
  aboutMeValue: {
    fontSize: {
      xs: '13px',
      sm: '14px',
    },
  },
  directionalArrowsContainer: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  directionalLeft: {
    marginRight: '10px',
  },
  directionalRight: {
    marginLeft: '5px',
  },
  directionArrow: {
    minWidth: '32px !important',
    height: '32px !important',
    padding: '0  !important',
    color: '#475467',
    borderColor: 'primary.border',
  },
  containerEditButton: {
    display: 'flex',
    gap: '12px',
    position: 'absolute',
    right: '0px',
    top: '-55px',
  },
});

export default styles;
