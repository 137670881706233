import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DataGrid from 'ui/components/DataGrid';
import { parseJsonTranslate } from 'utils/parse';

// Configs
import { tableSchemaBase as columns } from './ThemesGrid.config';

const ThemesGrid = ({ onClickRow, themes, isAuthorizeScroll = false }) => {
  const { t } = useTranslation();

  const updateList = (themes) =>
    themes.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        name: parseJsonTranslate(currentItem.name),
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const themesEnriched = updateList(themes);

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        height: '100%',
        borderBottomLeftRadius: '8px !important',
        borderBottomRightRadius: '8px !important',
      }}>
      <DataGrid
        isShowPagination={false}
        isShowBorder={false}
        isAuthorizeScroll={isAuthorizeScroll}
        pagination={false}
        columns={columns}
        rows={themesEnriched}
        totalItems={themes.length}
        onRowClicked={(theme, event) => {
          if (!event.defaultMuiPrevented) {
            onClickRow(theme);
          }
        }}
        currentPage={1}
        totalPages={1}
      />
    </Stack>
  );
};

export default ThemesGrid;
