import React, { Suspense, useState, useEffect } from 'react';
import { useEditProfile, useEditPassword, useActiveReminders } from 'hooks/usecase/useProfile';
import GoBackButton from 'ui/components/shared/GoBackButton';
import Box from 'ui/components/system/Box';
import { Paper, Typography, Grid, List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import ChangePasswordForm from './ChangePasswordForm';
import ActiveRemindersForm from './ActiveRemindersForm';
import ProfileForm from './ProfileForm';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import { useTranslation } from 'react-i18next';
import { roleList } from 'utils/constUtils';
import { useMeQuery } from 'hooks/queries';
import {
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_MY_ACCOUNT,
  PROFILE_SETTINGS_MY_COMPANY,
  LABELS,
  LABELS_TAB_INFORMATIONS,
  SECTORS,
  DEPARTMENTS,
  SENIORITY_LEVELS,
  PROFILE_SETTINGS_SECTIONS,
  PROFILE_SETTINGS_DESC,
  PROFILE_SETTINGS_LINKS_SURVEYS,
  SETTINGS_LINKS_SURVEYS_DESCRIPTION,
} from 'litterals';
import Departments from '../settings/components/departments/Departments';
import SeniorityLevels from '../settings/components/seniorityLevels/SeniorityLevels';
import Scopes from '../settings/components/scopes/Scopes';
import Labels from '../settings/components/labels/Labels';
import LinksSurveys from '../settings/components/links-surveys/LinksSurveys';
import SelfRegistration from '../settings/components/self-registration/SelfRegistration';
import CalendarConnection from '../settings/components/calendarConnection/CalendarConnection';
import { useNavigate, useLocation } from 'react-router-dom';

function ProfilePage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state: stateProfile, actions: actionsProfile } = useEditProfile();
  const { state: statePassword, actions: actionsPassword } = useEditPassword();
  const { state: stateReminders, actions: actionsReminders } = useActiveReminders();
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const { data: user } = useMeQuery();
  const [selectedSupTab, setSelectedSupTab] = useState(roleList.RH_MANAGER === user.role ? 'tab-1' : 'tab-7');
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash === '#calendar') {
      setSelectedTab('tab-2');
      setSelectedSupTab('tab-7');
    } else if (hash === '#sector') {
      setSelectedTab('tab-2');
      setSelectedSupTab('tab-2');
    } else if (hash === '#department') {
      setSelectedTab('tab-2');
      setSelectedSupTab('tab-3');
    } else if (hash === '#seniority') {
      setSelectedTab('tab-2');
      setSelectedSupTab('tab-4');
    }
  }, [hash]);

  if (stateProfile.query.isLoading) return <p>Loading...</p>;

  const renderSupComponent = () => {
    switch (selectedSupTab) {
      case 'tab-1':
        return <LinksSurveys />;
      case 'tab-2':
        return <Scopes disableInformationText />;
      case 'tab-3':
        return <Departments disableInformationText />;
      case 'tab-4':
        return <SeniorityLevels disableInformationText />;
      case 'tab-5':
        return <Labels />;
      case 'tab-6':
        return (
          <SelfRegistration
            selfRegistrationLinkCode={user?.company?.selfRegistrationLink}
            applicationLinkCode={user?.company?.applicationLink}
            companyId={user?.companyId}
          />
        );
      case 'tab-7':
        return <CalendarConnection />;

      default:
        return null;
    }
  };

  const isSelfRegistrationActivated = user?.company?.selfRegistrationActivated;

  let tabs = [];

  if (roleList.RH_MANAGER === user.role) {
    tabs = [
      { id: 'tab-1', text: t(PROFILE_SETTINGS_LINKS_SURVEYS), description: t(SETTINGS_LINKS_SURVEYS_DESCRIPTION) },
      { id: 'tab-2', text: t(SECTORS), description: t('settings_sectors_tab_info') },
      { id: 'tab-3', text: t(DEPARTMENTS), description: t('settings_departments_tab_info') },
      { id: 'tab-4', text: t(SENIORITY_LEVELS), description: t('settings_seniority_levels_tab_info') },
      { id: 'tab-5', text: t(LABELS), description: t(LABELS_TAB_INFORMATIONS) },
    ];

    if (isSelfRegistrationActivated) {
      tabs.push({
        id: 'tab-6',
        text: t('settings_self_registration_title'),
        description: t('settings_self_registration_info'),
      });
    }
  } else {
    tabs = [
      { id: 'tab-7', text: t('profile_calendar_connection.title'), description: t('profile_calendar_connection.desc') },
    ];
  }

  const selectedSupTabData = tabs.find((tab) => tab.id === selectedSupTab);

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return (
          <>
            <Grid item xs={12} md={6}>
              <ProfileForm control={stateProfile.controlForm} onSubmit={actionsProfile.submit} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChangePasswordForm
                control={statePassword.controlForm}
                isSsoConnection={stateProfile.query?.data?.isSsoConnection}
                onSubmit={actionsPassword.submit}
                newPasswordValue={statePassword.newPassswordValue}
              />
            </Grid>
            <Grid
              sx={{ paddingTop: '0 !important' }}
              item
              xs={12}
              md={12}
              component="form"
              onChange={actionsReminders.submit}>
              <ActiveRemindersForm control={stateReminders.controlForm} />
            </Grid>
          </>
        );

      case 'tab-2':
        return (
          <>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: 'Karla',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  lineHeight: '150%', // 24px
                  letterSpacing: '0.15px',
                  color: 'subtitle.main',
                  marginTop: '24px',
                }}>
                {t(PROFILE_SETTINGS_DESC)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Karla',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '150%', // 24px
                  letterSpacing: '0.15px',
                }}>
                {t(PROFILE_SETTINGS_SECTIONS)}
              </Typography>
              <List sx={{ width: '100%', minWidth: '100%' }}>
                {tabs.map(({ id, text }) => (
                  <ListItemButton
                    selected={id === selectedSupTab}
                    key={id}
                    disableGutters
                    onClick={() => {
                      setSelectedSupTab(id);
                    }}
                    sx={{ padding: '15px', borderRadius: '5px', borderBottom: '1px solid rgb(237, 237, 237)' }}>
                    <ListItemText primary={text} />
                  </ListItemButton>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={9}>
              <Suspense fallback={<div>Loading...</div>}>
                <Box>
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: 'Karla',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '150%',
                      letterSpacing: '0.15px',
                    }}>
                    {selectedSupTabData.text}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: 'Karla',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: '150%',
                      letterSpacing: '0.15px',
                      color: 'subtitle.main',
                    }}>
                    {selectedSupTabData.description}
                  </Typography>
                </Box>
                {renderSupComponent()}
              </Suspense>
            </Grid>
          </>
        );

      default:
        return null;
    }
  };

  const tabsConfiguration = [
    { id: 'tab-1', text: t(PROFILE_SETTINGS_MY_ACCOUNT), selected: selectedTab === 'tab-1', disabled: false },
  ];

  if (roleList.RH_MANAGER === user.role) {
    tabsConfiguration.push({
      id: 'tab-2',
      text: t('profile_settings_my_company'),
      selected: selectedTab === 'tab-2',
      disabled: false,
    });
  } else {
    tabsConfiguration.push({
      id: 'tab-2',
      text: t('user_settings_tab'),
      selected: selectedTab === 'tab-2',
      disabled: false,
    });
  }

  return (
    <Box>
      <GoBackButton sx={{ mt: '12px', ml: '12px' }} />
      <Paper
        sx={{
          width: '90%',
          alignSelf: 'center',
          mt: '24px',
          overflowY: 'auto',
          p: '32px',
          marginX: '24px',
        }}>
        <Typography sx={theme.fontHeader}>{t(PROFILE_SETTINGS)}</Typography>
        {tabsConfiguration.length > 1 && (
          <TabNavigation
            key={selectedTab}
            options={tabsConfiguration}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
          />
        )}
        <Grid container spacing={4}>
          <Suspense fallback={<div>Loading...</div>}>{renderComponent()}</Suspense>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ProfilePage;
