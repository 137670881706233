import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListThemesQuery } from 'hooks/queries';

const useThemes = (companyId) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingTheme, setEditingTheme] = useState(null);
  const [themeAdded, setThemeAdded] = useState(null);
  const [isLoadingAddTheme, setIsLoadingAddTheme] = useState(null);
  const [selectedTabCreate, setSelectedTabCreate] = useState('tab-1');
  const [selectedTabEdit, setSelectedTabEdit] = useState('tab-1');
  const [tabOptions, setTabOptions] = useState([
    { id: 'tab-1', text: 'General infos', selected: true, disabled: false },
    { id: 'tab-2', text: 'Session Templates', selected: false, disabled: true },
  ]);
  const [tabOptionsEdit, setTabOptionsEdit] = useState([
    { id: 'tab-1', text: 'General infos', selected: true },
    { id: 'tab-2', text: 'Session Templates', selected: false },
  ]);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      active: false,
      groupLabel: '',
      type: 'MENTORING',
      nbSessions: 0,
      content1: '<p></p>',
      content2: '<p></p>',
      content3: '<p></p>',
      description: '<p></p>',
      marketingPicture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/2560px-Placeholder_view_vector.svg.png',
      backgroundPicture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/2560px-Placeholder_view_vector.svg.png',
    },
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: themeList, isLoading } = useListThemesQuery({});

  const mutationDelete = useMutation(api.Theme.delete, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-themes']);
      setStateAlert({ open: true, message: 'theme successfully deleted' });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onRemoveTheme = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.Theme.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-themes']);
      setStateAlert({ open: true, message: 'theme successfully created' });
      setThemeAdded(data);
      setTabOptions([
        { id: 'tab-1', text: 'General infos', selected: false, disabled: false },
        { id: 'tab-2', text: 'Session Templates', selected: true, disabled: false },
      ]);
      setSelectedTabCreate('tab-2');
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onAddTheme = handleSubmit(async (data) => {
    if (!companyId) {
      if (themeAdded != null) {
        const { documentLinked, ...rest } = themeAdded;
        mutationEdit.mutate({
          id: themeAdded.id,
          data: { ...rest, active: rest.active == 'TRUE', companyId: companyId },
        });
        setSelectedTabCreate('tab-1');
      } else {
        mutationAdd.mutate({ ...data });
      }
    } else {
      if (themeAdded != null) {
        const { documentLinked, ...rest } = themeAdded;
        mutationEdit.mutate({
          id: themeAdded.id,
          data: { ...rest, active: rest.active == 'TRUE', companyId: companyId },
        });
        setSelectedTabCreate('tab-1');
      } else {
        setIsLoadingAddTheme(true);
        mutationAdd.mutate({ ...data, companyId: companyId });
      }
    }
  });

  const mutationDuplicate = useMutation(api.Theme.duplicate, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-themes']);
      setStateAlert({ open: true, message: 'theme successfully duplicated' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onDuplicateTheme = async (themeId) => {
    mutationDuplicate.mutate(themeId);
  };

  const mutationEdit = useMutation(api.Theme.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-themes']);
      setStateAlert({ open: true, message: 'theme successfully edited' });
      setEditingTheme(false);
      setIsOpenAddModal(false);
      setTabOptionsEdit([
        { id: 'tab-1', text: 'General infos', selected: true },
        { id: 'tab-2', text: 'Session Templates', selected: false },
      ]);
      setSelectedTabEdit('tab-1');
      setTabOptions([
        { id: 'tab-1', text: 'General infos', selected: true, disabled: false },
        { id: 'tab-2', text: 'Session Templates', selected: false, disabled: true },
      ]);
      setSelectedTabCreate('tab-1');
      setThemeAdded(null);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  useEffect(() => {
    if (editingTheme) {
      setValue('id', editingTheme.id);
      setValue('name', editingTheme.name);
      setValue('backgroundPicture', editingTheme.backgroundPicture);
      setValue('description', editingTheme.description);
      setValue('content1', editingTheme.content1);
      setValue('content2', editingTheme.content2);
      setValue('content3', editingTheme.content3);
      setValue('groupLabel', editingTheme.groupLabel);
      setValue('marketingPicture', editingTheme.marketingPicture);
      setValue('nbSessions', editingTheme.nbSessions);
      setValue('active', editingTheme && editingTheme.active === 'TRUE' ? true : false);
      setIsOpenEditModal(true);
    }
  }, [editingTheme]);

  const onEditTheme = handleSubmit(async (data) => {
    mutationEdit.mutate({
      id: editingTheme.id,
      data: { ...data, active: data.active, companyId: companyId },
    });
  });

  const submitEditMarketingPicture = async () => {
    const data = getValues();
    if (!!data.marketingPicture && typeof data.marketingPicture !== 'string') {
      const { url } = await api.Users.upload(data.marketingPicture);
      data.marketingPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('marketingPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.marketingPicture;
    }
  };

  const submitEditBackgroundPicture = async () => {
    const data = getValues();
    if (!!data.backgroundPicture && typeof data.backgroundPicture !== 'string') {
      const { url } = await api.Users.upload(data.backgroundPicture);
      data.backgroundPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('backgroundPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.backgroundPicture;
    }
  };

  const handleCloseModalAdd = () => {
    setThemeAdded(null);
    setIsOpenAddModal(false);
    setSelectedTabCreate('tab-1');
    setTabOptions([
      { id: 'tab-1', text: 'General infos', selected: true, disabled: false },
      { id: 'tab-2', text: 'Session Templates', selected: false, disabled: true },
    ]);
    reset();
  };

  return {
    isLoading: isLoading,
    themes: themeList,
    control,
    watch,
    reset,
    getValues,
    onRemoveTheme,
    onAddTheme,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingTheme,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditTheme,
    editingTheme,
    submitEditMarketingPicture,
    submitEditBackgroundPicture,
    onDuplicateTheme,
    isLoadingAddTheme,
    themeAdded,
    tabOptions,
    setTabOptions,
    setSelectedTabCreate,
    selectedTabCreate,
    handleCloseModalAdd,
    selectedTabEdit,
    setSelectedTabEdit,
    tabOptionsEdit,
    setTabOptionsEdit,
  };
};

export default useThemes;
