import React, { useState, useRef } from 'react';
import ViewModal from 'ui/components/shared/ViewModal';
import styles from './UploaderContainer.styles';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploaderContainer = ({ sx, disabled, fileType = 'image', onChangeFile }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dragCounter = useRef(0);
  const [file, setFile] = useState(null);
  const [isDrag, setIsDrag] = useState(false);
  const fileInputRef = useRef(null);
  const [isHover, setIsHover] = useState(false);

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      onChangeFile(newFile);
      setFile(newFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    dragCounter.current++;
    if (dragCounter.current === 1) {
      setIsDrag(true);
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDrag(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    const newFile = event.dataTransfer.files[0];
    if (newFile) {
      onChangeFile(newFile);
      setFile(newFile);
    }
    dragCounter.current = 0;
    setIsDrag(false);
  };

  const handleClick = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const themedStyles = styles(theme);
  const style = {
    ...themedStyles.container,
    ...(isDrag && !disabled ? themedStyles.dragContainer : {}),
    ...(isHover && !disabled ? themedStyles.hoverContainer : {}),
    ...(disabled ? themedStyles.disabledContainer : {}),
    ...sx,
  };

  const mappingFileType = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    image: 'image/*',
  };

  return (
    <div
      style={style}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      {file ? (
        <Box sx={themedStyles.uploadContainer}>
          <UploadFileIcon sx={themedStyles.uploadIcon} />
          <Typography>
            {t('uploader_container.image_selected')} {file.name}
          </Typography>
        </Box>
      ) : (
        <Box sx={themedStyles.uploadContainer}>
          <UploadFileIcon sx={themedStyles.uploadIcon} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={themedStyles.uploadText}>{t('uploader_container.click_upload')}</Typography>
            <Typography>{t('uploader_container.drag_drop')}</Typography>
          </Box>
          <Typography sx={themedStyles.warningText}>{t(`uploader_container.warning.${fileType}`)}</Typography>
        </Box>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={mappingFileType[fileType]}
      />
    </div>
  );
};

export default UploaderContainer;
