import React, { useEffect, useState } from 'react';

import { CircularProgress, Box, TextField, Typography, Stack, Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';
import Divider from '@mui/material/Divider';
import ViewModal from 'ui/components/shared/ViewModal';
import styles from './AddUsersModal.styles';
import Button from 'ui/components/Button/Button';
import UploaderContainer from 'ui/components/UploaderContainer/UploaderContainer';
import { useGenerateMenteeImportXlsx, useGenerateCoachImportXlsx } from 'hooks/queries';
import FileSaver from 'file-saver';
import { randomFileId } from 'utils/uploader';
import { useQueryClient } from '@tanstack/react-query';
import api from 'api';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AddUsersModal = ({ companyId, userType, open, applicationLink, expertLabel, clientLabel, onClose }) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [authorizeExportMenteeDownload, setAuthorizeExportMenteeDownload] = useState(false);
  const [authorizeExportCoachDownload, setAuthorizeExportCoachDownload] = useState(false);
  const [importResults, setImportResults] = useState({});
  const [sendEmail, setSendEmail] = useState(true);
  const [verificationOnly, setVerificationOnly] = useState(true);
  const [saveFile, setSaveFile] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    setImportResults({});
  }, [open]);

  const {
    data: menteeXlsx,
    isLoading: isLoadingGenerateMenteeXlsx,
    isFetched: isFetchedGenerateMenteeXlsx,
  } = useGenerateMenteeImportXlsx(companyId, { enabled: authorizeExportMenteeDownload });
  const {
    data: coachXlsx,
    isLoading: isLoadingGenerateCoachXlsx,
    isFetched: isFetchedGenerateCoachXlsx,
  } = useGenerateCoachImportXlsx(companyId, { enabled: authorizeExportCoachDownload });

  const translateKey = `add_users_modal.${userType}`;

  const userLabel = userType === 'coach' ? `${expertLabel}s` : `${clientLabel}s`;
  const userLabelSingular = userType === 'coach' ? expertLabel : clientLabel;

  const onXlsxFile = async (file) => {
    setSaveFile(file);
  };

  const uploadXlsx = async () => {
    setIsLoading(true);

    console.log('saveFile', saveFile);
    const formData = new FormData();
    formData.append('file', saveFile);
    formData.append('folder', `uploads/${companyId}/xlsx/`);
    formData.append('fileId', randomFileId());

    if (userType === 'coachee') {
      const result = await api.Companies.uploadCoacheeXlsx(companyId, formData, sendEmail, verificationOnly);
      setImportResults(result);

      if (!verificationOnly) {
        queryClient.invalidateQueries(['get-list-guests-params']);
      }
    } else {
      const result = await api.Companies.uploadCoachXlsx(companyId, formData, sendEmail, verificationOnly);
      setImportResults(result);

      if (!verificationOnly) {
        queryClient.invalidateQueries(['get-list-coaches-params']);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (saveFile) {
      uploadXlsx();
    }
  }, [saveFile]);

  useEffect(() => {
    if (!verificationOnly && saveFile) {
      uploadXlsx();
    }
  }, [verificationOnly]);

  const onValidateImport = async () => {
    setIsLoading(true);
    setVerificationOnly(false);
  };

  const onGenerateXlsxClick = () => {
    if (userType === 'coachee') {
      queryClient.removeQueries(['get-generate-mentee-import-xlsx']);
      setAuthorizeExportMenteeDownload(true);
    } else {
      queryClient.removeQueries(['get-generate-coach-import-xlsx']);
      setAuthorizeExportCoachDownload(true);
    }
  };

  const onBackToFirstStep = () => {
    setSaveFile(null);
    setVerificationOnly(true);
    setImportResults({});
  };

  useEffect(() => {
    if (menteeXlsx && !isLoadingGenerateMenteeXlsx && isFetchedGenerateMenteeXlsx && authorizeExportMenteeDownload) {
      FileSaver.saveAs(menteeXlsx, `Import_Mentees.xlsx`);
      setAuthorizeExportMenteeDownload(false);
    }
  }, [menteeXlsx, isLoadingGenerateMenteeXlsx, isFetchedGenerateMenteeXlsx, authorizeExportMenteeDownload]);

  useEffect(() => {
    if (coachXlsx && !isLoadingGenerateCoachXlsx && isFetchedGenerateCoachXlsx && authorizeExportCoachDownload) {
      FileSaver.saveAs(coachXlsx, `Import_Mentors.xlsx`);
      setAuthorizeExportCoachDownload(false);
    }
  }, [coachXlsx, isLoadingGenerateCoachXlsx, isFetchedGenerateCoachXlsx, authorizeExportCoachDownload]);

  const isImportCompleted = importResults?.totalRows != null && !isLoading;

  const formatErrorInfos = (item) => {
    const rowInfo = item.rowNumber ? `${t('add_users_modal.line')} ${item.rowNumber}` : '';
    const columnInfo = item.column ? `${t('add_users_modal.column')} "${item.column}"` : '';
    const value = item.value ? `${t('add_users_modal.value')} "${item.value}"` : '';

    const results = [];

    if (rowInfo) results.push(rowInfo);
    if (columnInfo) results.push(columnInfo);
    if (value) results.push(value);

    return `(${results.join(' / ')})`;
  };

  const loadingWording = verificationOnly
    ? t('add_users_modal.import_verification_in_progress')
    : t('add_users_modal.import_in_progress');

  const importCompletedContent = () => {
    if (!verificationOnly) {
      if (!!importResults.totalRows && importResults.totalRows === importResults.totalSuccessRows) {
        return (
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleOutlineIcon
              sx={{ margin: '0 12px 12px 0', width: '70px', height: '70px', color: 'primary.main' }}
            />

            <Box>
              {t('add_users_modal.import_valid.desc', {
                countUsers: importResults.totalSuccessRows,
                userLabel: userLabelSingular,
              })}
            </Box>
            <Box>{t(`add_users_modal.import_valid.info.${userType}`, { expertLabel, clientLabel })}</Box>

            <Box sx={styles.backFirstStepContainer}>
              <Button variant="secondary" onClick={onBackToFirstStep}>
                {t('add_users_modal.back_first_step')}
              </Button>
            </Box>
          </Box>
        );
      }

      if (!importResults.totalRows || importResults.totalRows !== importResults.totalSuccessRows) {
        return (
          <Box>
            {!!importResults.totalSuccessRows && (
              <Box sx={{ display: 'flex', marginBottom: '12px', marginTop: '12px' }}>
                <CheckCircleOutlineIcon
                  sx={{ margin: '0 12px 0 0', width: '50px', height: '50px', color: 'primary.main' }}
                />

                <Box sx={{ alignContent: 'center' }}>
                  <Box>
                    {t('add_users_modal.import_valid.desc', { countUsers: importResults.totalSuccessRows, userLabel })}
                  </Box>
                  <Box>{t(`add_users_modal.import_valid.info.${userType}`, { expertLabel, clientLabel })}</Box>
                </Box>
              </Box>
            )}

            {!!importResults.errors.length && (
              <Box sx={{ display: 'flex', marginTop: '12px' }}>
                <HighlightOffIcon sx={{ margin: '0 12px 0 0', width: '50px', height: '50px', color: '#d52e1f' }} />

                <Box sx={{ alignContent: 'center' }}>
                  <Box>{t(`add_users_modal.partial_valid`)}</Box>

                  <ul style={{ marginBottom: 0 }}>
                    {importResults.errors.map((item) => {
                      return (
                        <li key={`${item.rowNumber}_${item.type}`}>
                          {t(`add_users_modal.errors.${item.type}`)} {formatErrorInfos(item)}
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              </Box>
            )}

            <Box sx={styles.backFirstStepContainer}>
              <Button variant="secondary" onClick={onBackToFirstStep}>
                {t('add_users_modal.back_first_step')}
              </Button>
            </Box>
          </Box>
        );
      }
    } else {
      if (importResults.errors.find(({ type }) => type === 'COLUMNS_NOT_CORRESPOND')) {
        return (
          <Box sx={{ textAlign: 'center' }}>
            <HighlightOffIcon sx={{ margin: '0 12px 12px 0', width: '70px', height: '70px', color: '#d52e1f' }} />

            <Box>{t('add_users_modal.errors.COLUMNS_NOT_CORRESPOND')}</Box>
          </Box>
        );
      } else {
        return (
          <Box>
            <Box sx={{ textAlign: 'left', margin: '16px 0 32px 0' }}>
              <Box>
                {t('add_users_modal.confirmation_step.number_of_row')}
                <span style={{ fontWeight: 600 }}>{` ${importResults.totalRows} ${userLabel}.`}</span>
              </Box>
              <Box>{t('add_users_modal.confirmation_step.warning')}</Box>
              <Box sx={{ marginTop: '12px' }}>{t(`add_users_modal.confirmation_step.email_option.${sendEmail}`)}</Box>
              <Box sx={{ marginTop: '12px' }}>{t(`add_users_modal.confirmation_step.question`)}</Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'right' }}>
              <Button variant="secondary" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="primary" onClick={onValidateImport}>
                {t('add_users_modal.confirmation_step.valid_import_button', { userLabel })}
              </Button>
            </Box>
          </Box>
        );
      }
    }
  };

  const renderTitle = () => {
    if (isImportCompleted) {
      if (!verificationOnly) {
        if (importResults.errors.length) return t('add_users_modal.result_step.title.error');

        return t('add_users_modal.result_step.title.valid');
      }

      return t('add_users_modal.confirmation_step.title');
    }

    return t(`${translateKey}.title`, { expertLabel, clientLabel });
  };

  const isLoadingXlsxUser =
    userType === 'coachee'
      ? authorizeExportMenteeDownload && isLoadingGenerateMenteeXlsx
      : authorizeExportCoachDownload && isLoadingGenerateCoachXlsx;

  return (
    <ViewModal open={open} onClose={onClose} customStyleBox={styles.container}>
      <Box>
        <Typography variant="h1" sx={styles.title}>
          {renderTitle()}
        </Typography>
      </Box>

      {!isLoading && !isImportCompleted && (
        <>
          {!!applicationLink && (
            <Box sx={styles.content}>{t(`${translateKey}.desc`, { expertLabel, clientLabel })}</Box>
          )}

          {!!applicationLink && (
            <Box sx={{ marginTop: '48px' }}>
              <Typography variant="h2" sx={styles.titleCategory}>
                {t(`${translateKey}.first.title`, { expertLabel, clientLabel })}
              </Typography>

              <Box sx={styles.content}>{t(`${translateKey}.first.desc`, { expertLabel, clientLabel })}</Box>

              <Stack direction={'row'} sx={{ alignItems: 'center', gap: '8px' }}>
                <TextField
                  sx={styles.textField}
                  value={window.location.origin + '/program-application?applicationLink=' + applicationLink}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                />
                <Button
                  sx={styles.copyButton}
                  variant="secondary"
                  onClick={() => {
                    clipboardCopy(window.location.origin + '/program-application?applicationLink=' + applicationLink)
                      .then(() => {
                        console.log('Text copied successfully: RAMI');
                      })
                      .catch((err) => {
                        console.error('Copy failed:', err);
                      });
                    setStateAlert({ open: true, message: t('invitation_link_copied') });
                  }}>
                  {t('copy')}
                </Button>
              </Stack>
            </Box>
          )}

          {!!applicationLink && <Divider sx={styles.divider} />}

          <Box>
            <Typography variant="h2" sx={styles.titleCategory}>
              {t(`${translateKey}.second.title`, { expertLabel, clientLabel })}
            </Typography>

            <Box sx={styles.content}>{t(`${translateKey}.second.desc`, { expertLabel, clientLabel })}</Box>

            <Box sx={{ textAlign: 'center' }}>
              <Button loading={isLoadingXlsxUser} disabled={isLoadingXlsxUser} onClick={onGenerateXlsxClick}>
                {t(`add_users_modal.file-button`, { expertLabel, clientLabel })}
              </Button>

              <FormControlLabel
                sx={{ marginTop: '12px', marginLeft: '0', display: 'block', width: '100%' }}
                control={<Switch checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} />}
                label={t('add_users_modal.send_email')}
                labelPlacement="start"
              />

              <UploaderContainer
                sx={styles.uploaderXlsx}
                fileType="xlsx"
                disabled={isLoading}
                onChangeFile={onXlsxFile}
              />
            </Box>
          </Box>
        </>
      )}

      {isLoading && !isImportCompleted && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress sx={{ marginBottom: '24px' }} />
          <Box>{loadingWording}</Box>
        </Box>
      )}

      {isImportCompleted && importCompletedContent()}
    </ViewModal>
  );
};

export default AddUsersModal;
