const styles = (theme) => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '16px',
    height: '1107px',
    minWidth: {
      xl: '888px',
      sm: '888px',
      xs: '100%',
    },
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 77px',
    borderTop: '1px solid #dfe0e0',
  },
  btnBack: {
    minWidth: '107px',
    height: '39px',
  },
  btnSave: {
    backgroundColor: 'primary.dark',
    minWidth: '107px',
    height: '39px',
  },
  containerInputsSessionDialog: {
    padding: '20px 0px',
  },
  titleContent: {
    color: theme.palette.primary.dark,
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '46.76px',
  },
  themeContent: {
    fontSize: '16px',
    fontWeight: 600,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  title: {
    color: '#000000DE',
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '28.06px',
    paddingBottom: '15px',
  },
  subTitle: {
    color: '#000000DE',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18.7px',
  },
  value: {
    color: '#000000DE',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18.7px',
  },
  containerTitle: {
    minHeight: '78px',
  },
  content: {
    overflow: 'hidden',
    padding: '0px 88px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentEmail: {
    width: {
      xl: '655px',
      sm: '600px',
      xs: '100%',
    },
    height: '100%',

    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 91px',
    margin: '0px',
  },
});
export default styles;
