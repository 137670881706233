/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React from 'react';

import Stack from '@mui/material/Stack';
import Button from 'ui/components/Button/Button';

import { useTranslation } from 'react-i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionEditButton({ row, onEdit }) {
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack>
      <Button
        disabled={!row.isValidFormat}
        variant="secondary"
        onClick={(e) => {
          e.stopPropagation();
          onEdit(row);
        }}>
        {t('edit')}
      </Button>
    </Stack>
  );
}

export default CellActionEditButton;
