import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import MuiTextField from '@mui/material/TextField';
import TextField from 'ui/components/form/TextField';
import { InputAdornment, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useMeQuery } from 'hooks/queries';
import { getUrlApplicationLink, getUrlEvergreenLink } from 'utils/company';
import CircularProgress from '@mui/material/CircularProgress';
import { useCompanyData } from 'hooks/usecase/useCompany';
import Button from 'ui/components/Button/Button';
import Switch from 'ui/components/form/Switch';

import {
  LABEL_INPUT_LINK_TERMS,
  LABEL_INPUT_LINK_FEEDBACK,
  LABEL_INPUT_LINK_APPLICATION,
  LABEL_INPUT_LINK_EVERGREEN,
  TOOLTIP_EVERGREEN,
  SAVE_CHANGES,
} from 'litterals';

const LinksSurveys = () => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();

  const { isLoading, control, watch, submitEditCompany, company: companyData } = useCompanyData(user.company.id);

  const refLinkTerms = useRef();

  const styleInput = { maxWidth: '500px', shrink: 'true' };

  if (isLoading)
    return (
      <Box sx={{ textAlign: 'center', marginTop: '32px' }}>
        <CircularProgress size={25} />
      </Box>
    );

  const applicationLink = companyData?.applicationLink ? getUrlApplicationLink(companyData.applicationLink) : '';
  const evergreenLink = companyData?.evergreenLink ? getUrlEvergreenLink(companyData.evergreenLink) : '';

  const manualValidationLabel = watch('manualValidation')
    ? t('manual_validation_activated')
    : t('manual_validation_deactivated');

  return (
    <Box component={'form'} display={'flex'} flexDirection={'column'} gap={'20px'} sx={{ marginTop: '32px' }}>
      <TextField
        ref={refLinkTerms}
        control={control}
        name="linkTerms"
        label={t(LABEL_INPUT_LINK_TERMS)}
        placeholder="https://app.pathline.fr/terms_and_conditions"
        type="text"
        style={styleInput}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('tooltip_link_terms')}>
                <IconButton edge="end">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        control={control}
        name="preprogramLink"
        label={t('label_input_link_preprogram')}
        placeholder="https://www.surveymonkey.com/akdiq-84ds"
        type="text"
        style={styleInput}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('tooltip_preprogram_survey')}>
                <IconButton edge="end">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        control={control}
        name="feedbackLink"
        label={t(LABEL_INPUT_LINK_FEEDBACK)}
        placeholder="https://www.surveymonkey.com/akdiq-84ds"
        type="text"
        style={styleInput}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('tooltip_feedback_link')}>
                <IconButton edge="end">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <MuiTextField
        value={applicationLink}
        name="sessionLabel"
        label={t(LABEL_INPUT_LINK_APPLICATION)}
        placeholder=""
        type="text"
        style={styleInput}
        inputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('tooltip_application_link')}>
                <IconButton edge="end">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <MuiTextField
        value={evergreenLink}
        name="sessionLabel"
        label={t(LABEL_INPUT_LINK_EVERGREEN)}
        placeholder=""
        type="text"
        style={styleInput}
        inputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t(TOOLTIP_EVERGREEN)}>
                <IconButton edge="end">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <Switch
        control={control}
        variant="outlined"
        label={manualValidationLabel}
        labelPlacement="end"
        name="manualValidation"
        fullWidth
      />

      <Button
        sx={{ width: 'max-content' }}
        onClick={() => {
          submitEditCompany();
        }}>
        {isLoading ? <CircularProgress size="1rem" color="secondary" /> : t(SAVE_CHANGES)}
      </Button>
    </Box>
  );
};

export default LinksSurveys;
