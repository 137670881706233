import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdminNote } from '../AdminNote/AdminNote';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import { useForm } from 'react-hook-form';
import api from 'api';

export const AdminNoteCoachee = ({ open, source, coacheeId, adminNote, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const mutateProgramAdminNote = useMutation(source === 'User' ? api.Users.edit : api.Guests.edit, {
    onSuccess: () => {
      setStateAlert({
        message: t('admin_note_coachee.alert'),
        open: true,
      });

      queryClient.invalidateQueries(['get-list-guests-params']);
    },
  });

  const { control: internalControl, watch: internalWatch } = useForm({ mode: 'all', defaultValues: { adminNote } });

  return (
    <AdminNote
      open={open}
      title={t('admin_note_coachee.title')}
      editorName="adminNote"
      handleSubmit={() => {
        const adminNote = internalWatch('adminNote');
        mutateProgramAdminNote.mutate({ id: coacheeId, data: { adminNote } });
        onClose();
      }}
      control={internalControl}
      onClose={onClose}
    />
  );
};
