import React from 'react';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import styles from './StatisticsCard.styles';

const StatisticsCard = ({ value, title, subtitle, icon, isLoading }) => {
  return (
    <DashboardCard sx={styles.card}>
      <Stack sx={styles.container}>
        {isLoading && <CircularProgress color="inherit" size="20px" />}
        {!isLoading && <Typography sx={styles.value}>{value}</Typography>}
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.title}>{title}</Typography>
          <Typography sx={styles.subtitle}>{subtitle}</Typography>
        </Box>
        {icon}
      </Stack>
    </DashboardCard>
  );
};

export default StatisticsCard;
