const styles = (theme) => ({
  container: {
    boxShadow: 'none',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: 'primary.border',
    '&:hover': {
      borderColor: 'primary.main',
    },
  },
  containerHeader: {
    position: 'relative',
    height: '148px',
  },
  picture: {
    position: 'absolute',
    width: '100%',
  },
  dropdownActions: {
    position: 'absolute',
    right: '6px',
    top: '6px',
  },
  picturePlaceholder: {
    background: 'rgba(0, 0, 0, 0.04)',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  iconPlaceholder: {
    color: 'rgba(0, 0, 0, 0.26)',
    height: '64px',
    width: '64px',
  },
  chips: {},
  chip: {
    height: '24px',
  },
  typeChip: {
    border: '1px solid',
    borderColor: '#B18225',
    color: '#B18225',
    backgroundColor: '#FEBA361F',
    marginRight: '8px',
  },
  chipProgram: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    backgroundColor: `${theme.palette.primary.main}14`,
  },
  chipCompany: {
    border: '1px solid',
    borderColor: '#3D4752',
    color: '#3D4752',
    backgroundColor: '#3D475214',
  },
  typeBadge: {
    position: 'absolute',
    top: 20,
    left: '20%',
    '& .MuiBadge-badge': {
      color: 'white',
      backgroundColor: '#EE6C4D',
      borderRadius: '5px',
      width: '60.87px',
    },
  },
  content: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: '15px',
    fontFamily: 'Karla',
    fontWeight: 500,
    lineHeight: '17.54px',
    margin: '14px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  containerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '35px',
  },

  containerLanguage: {
    display: 'flex',
  },

  languageBadge: {
    backgroundColor: '#1880381A',
    color: '#126F3D',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '12px',
    height: '23px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    padding: '0.5px 10px',
  },
});

export default styles;
