const styles = {
  card: {
    alignContent: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
  container: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: '12px',
    color: 'primary.grey',
    fontWeight: 400,
  },
  titleContainer: {
    flex: 1,
    paddingLeft: '12px',
    paddingRight: '8px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
  },
  value: {
    color: 'primary.greyMetal',
    fontSize: '26px',
    fontWeight: 800,
  },
};

export default styles;
