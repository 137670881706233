import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from 'ui/components/system/Box';
import { Paper } from '@mui/material';
import ProgramsTable from './ProgramsTable';
import { useListFormatThemeQuery } from 'hooks/queries';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { NEW_PROGRAM, PROGRAMS } from 'litterals';
import useViewSubscription from 'hooks/usecase/useViewSubscription';
import { roleList } from 'utils/constUtils';
import Button from 'ui/components/Button/Button';
import ButtonWithMenu from 'ui/components/ButtonWithMenu/ButtonWithMenu';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import api from 'api';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import GoBackButton from 'ui/components/shared/GoBackButton';
import Select from 'ui/pages/application/components/Select/Select';
import { parseJsonTranslate } from 'utils/parse';
import { useForm } from 'react-hook-form';
import { Roles } from 'utils/roles';

const CoacheeTableHeader = ({
  isLoadingExportData,
  isShowCreateProgram,
  user,
  themeIdSelected,
  themes = [],
  onChangeTheme,
  onExportData,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const refTheme = useRef();

  const itemsExportCsv = [
    { id: 'programs', name: t('export_data_programs.default') },
    { id: 'programs-sessions', name: t('export_data_programs.sessions') },
    { id: 'programs-objectives', name: t('export_data_programs.objectives') },
  ];

  const { control: themeControl, watch: themeWatch } = useForm({ mode: 'all', defaultValues: { themeIdSelected } });

  const watchedValues = themeWatch();

  useEffect(() => {
    onChangeTheme(watchedValues);
  }, [watchedValues]);

  const themesOptions = themes.map(({ name, id }) => {
    return { label: parseJsonTranslate(name), value: id };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pl: '12px',
        pr: '12px',
        marginBottom: '12px',
        alignItems: 'center',
      }}>
      <Box sx={{ display: 'flex', marginTop: '8px', minWidth: '280px', gap: '12px', alignItems: 'center' }}>
        <Box
          sx={{
            marginTop: '-12px',
            width: '100%',

            '#selector, #selector-label': {
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'karla',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '29.64px',
              letterSpacing: '0.25px',
            },

            '#selector-label.MuiInputLabel-shrink': {
              display: 'none',
            },

            '.MuiInput-underline::before': {
              border: 'none',
            },
            '.MuiSelect-icon': {
              fontSize: '1.7rem',
            },
          }}>
          <Select
            options={themesOptions}
            label={t(PROGRAMS)}
            name="themeIdSelected"
            ref={refTheme}
            control={themeControl}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px' }}>
        {(user.role === roleList.RH_MANAGER || user.role === roleList.RH) && (
          <ButtonWithMenu
            id="export-data-program"
            items={itemsExportCsv}
            loading={isLoadingExportData}
            disabled={isLoadingExportData}
            variant="secondary"
            sxItem={{ textTransform: 'uppercase' }}
            onClickItem={onExportData}>
            {!isLoadingExportData && <DownloadIcon sx={{ marginRight: '4px' }} />}
            {t('export_data')}
          </ButtonWithMenu>
        )}
        {isShowCreateProgram && (
          <Button
            onClick={() => navigate('/create-program')}
            sx={{ minWidth: '170px' }}
            startIcon={<RocketLaunchIcon />}>
            {t(NEW_PROGRAM)}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const extraColumns = ['Coach', 'Scope-Coach', 'Scope-Coachee', 'Coachee', 'Status'];
const initParamsActivePrograms = { page: 1, size: 25, status: ['done', 'draft', 'pending', 'in progress'] };
const initParamsEvergreenPrograms = { page: 1, size: 25, type: 'EVERGREEN' };
const initParamsDeactivatedPrograms = {
  page: 1,
  size: 25,
  status: ['deactivated', 'archived'],
  isStatusBeforeDeactivation: true,
};
const disabledColumnsForTableActivePrograms = ['scopeMentee', 'scopeMentor', 'deactivatedAt', 'daysToDeletion'];
const disabledColumnsForTableDeactivatedPrograms = [
  'scopeMentee',
  'scopeMentor',
  'status',
  'progress',
  'upcomingSessions',
];

const HomeRH = ({
  user,
  company,
  containerElevation = 3,
  containerMargin = '24px',
  isShowBackButton = true,
  isShowCreateProgram = true,
  isDeactivatesClickingOnProgram = false,
}) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();
  const [queryParams, setQueryParams] = useState(initParamsActivePrograms);
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [themeIdSelected, setThemeIdSelected] = useState('');
  const { hash } = useLocation();

  const { state, actions, query } = useViewSubscription({ initialFilterState: queryParams });

  const { data: themes } = useListFormatThemeQuery(company.id);

  useEffect(() => {
    if (queryParams) {
      actions.updateFilterSubscription(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (company?.id) {
      setQueryParams({ ...queryParams, companyId: company.id });
    }
  }, [company]);

  useEffect(() => {
    if (hash.startsWith('#theme-')) {
      const hashSplit = hash.split('-');
      hashSplit.shift();

      const themeId = hashSplit.join('-');
      setThemeIdSelected(themeId);
      setQueryParams(getParamsWithTab(selectedTab, themeId));
    } else {
      setThemeIdSelected('');
      setQueryParams(getParamsWithTab(selectedTab));
    }
  }, [hash]);

  const mutateProgramStatus = useMutation(api.Programs.update, {
    onSuccess: (data) => {
      const type = data.status === 'deactivated' ? 'deactivation' : 'reactivation';
      setStateAlert({
        message: t(`program_${type}_success`),
        open: true,
      });

      actions.reloadListPrograms();
    },
  });

  const onDeactivateProgram = ({ programId, sendEmailToCoach, sendEmailToCoachee, isArchived }) => {
    const status = isArchived ? 'archived' : 'deactivated';
    mutateProgramStatus.mutate({ id: programId, body: { status, sendEmailToCoach, sendEmailToCoachee } });
  };

  const onReactivateProgram = ({ programId, sendEmailToCoach, sendEmailToCoachee }) => {
    const status = 'reactivated';
    mutateProgramStatus.mutate({ id: programId, body: { status, sendEmailToCoach, sendEmailToCoachee } });
  };

  const disabledColumnsForTableActiveProgramsEnriched =
    Roles.ADMIN === user.role
      ? disabledColumnsForTableActivePrograms
      : [...disabledColumnsForTableActivePrograms, 'coacheeLastLogin', 'coachLastLogin', 'adminNote'];
  const disabledColumnsForTableDeactivatedProgramsEnriched =
    Roles.ADMIN === user.role
      ? disabledColumnsForTableDeactivatedPrograms
      : [...disabledColumnsForTableDeactivatedPrograms, 'coacheeLastLogin', 'coachLastLogin', 'adminNote'];

  const disableColumnsRh = (selectedTab, themeIdSelected) => {
    switch (selectedTab) {
      case 'tab-1':
        return deleteThemeColumns(disabledColumnsForTableActiveProgramsEnriched, !!themeIdSelected);
      case 'tab-2':
        return deleteThemeColumns([], !!themeIdSelected);
      case 'tab-3':
        return deleteThemeColumns(disabledColumnsForTableDeactivatedProgramsEnriched, !!themeIdSelected);
    }
  };

  const deleteThemeColumns = (columnDeleted, isThemeDeleted) => {
    if (isThemeDeleted) return [...columnDeleted, 'theme'];

    return columnDeleted;
  };

  const onExportData = (type) => {
    actions.downloadCsv(type);
  };

  const renderComponent = (selectedTab, themeIdSelected) => {
    return (
      <>
        {selectedTab === 'tab-2' ? (
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0288d12b', // Blue background color
              padding: '11px',
              boxShadow: 'none', // Remove box-shadow
              width: 'fit-content', // Adjust width for content
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <InfoOutlinedIcon sx={{ color: '#0288D1', marginRight: '8px' }} />
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'var(--Light-Info-Shades-160p, #0288D1)',
                  fontFamily: 'Karla',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '150%', // 24px
                  letterSpacing: '0.15px',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                }}>
                {t('info_history_evergreen_requests')}
              </Typography>
            </Box>
          </Paper>
        ) : (
          <></>
        )}

        <ProgramsTable
          isActiveEvergreenColumns={selectedTab === 'tab-2'}
          isDisablesRowClick={selectedTab === 'tab-2' || isDeactivatesClickingOnProgram}
          user={user}
          setQueryParams={(params) => {
            const initParams = getParamsWithTab(selectedTab, themeIdSelected);
            setQueryParams({ ...initParams, ...params });
          }}
          disableColumnsRh={disableColumnsRh(selectedTab, themeIdSelected)}
          queryParams={queryParams}
          extraColumns={extraColumns}
          programs={query.data ? query.data.rows : []}
          isLoading={query.isLoading}
          updateFilter={actions.updateFilterSubscription}
          filterState={state.filterSubscriptionState}
          sx={{ height: '75vh' }}
          role={roleList.RH}
          scopeOption={state.scopeOption}
          totalPage={query.data ? query.data.totalPages : 0}
          totalItems={query.data && query.data.totalItems}
          onDeactivateProgram={onDeactivateProgram}
          onReactivateProgram={onReactivateProgram}
        />
      </>
    );
  };

  const tabsConfiguration = [
    { id: 'tab-1', text: t('active_programs'), selected: true, disabled: false },
    {
      id: 'tab-2',
      text: t('mentee_request', { sessionLabel: company?.sessionLabel }),
      selected: false,
      disabled: false,
    },
    { id: 'tab-3', text: t('disabled_programs'), selected: false, disabled: false },
  ];

  const getParamsWithTab = (tab, themeIdSelected) => {
    const initParamsDynamic = themeIdSelected ? { themeId: themeIdSelected } : {};

    if (tab === 'tab-1') return { ...initParamsActivePrograms, companyId: company.id, ...initParamsDynamic };
    if (tab === 'tab-2') return { ...initParamsEvergreenPrograms, companyId: company.id, ...initParamsDynamic };
    if (tab === 'tab-3') return { ...initParamsDeactivatedPrograms, companyId: company.id, ...initParamsDynamic };
  };

  const onClickBackProgram = () => {
    setThemeIdSelected('');
    setQueryParams(getParamsWithTab(selectedTab));
  };

  const onChangeTheme = (value) => {
    if (value?.themeIdSelected !== themeIdSelected) {
      setThemeIdSelected(value.themeIdSelected);
      setQueryParams(getParamsWithTab(selectedTab, value.themeIdSelected));
    }
  };

  return (
    <Box>
      {!!themeIdSelected && isShowBackButton && (
        <Box sx={{ marginTop: '12px', marginLeft: '20px' }}>
          <GoBackButton text="back_all_program" onClick={onClickBackProgram} />
        </Box>
      )}
      <Paper elevation={containerElevation} sx={{ m: containerMargin, p: '12px', pb: '24px', position: 'relative' }}>
        <CoacheeTableHeader
          key={themeIdSelected}
          isShowCreateProgram={isShowCreateProgram}
          isLoadingExportData={state.isLoadingExportData}
          user={user}
          themes={themes}
          themeIdSelected={themeIdSelected}
          onChangeTheme={onChangeTheme}
          onExportData={onExportData}
        />

        <Stack sx={{ p: '0 10px 0 10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TabNavigation
              sx={{ padding: '10px' }}
              options={tabsConfiguration}
              onChange={(tab) => {
                setQueryParams(getParamsWithTab(tab, themeIdSelected));
                setSelectedTab(tab);
              }}
            />
          </Box>

          <Suspense fallback={<div>Loading...</div>}>{renderComponent(selectedTab, themeIdSelected)}</Suspense>
        </Stack>
      </Paper>
    </Box>
  );
};

export default HomeRH;
