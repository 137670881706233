const styles = (theme) => ({
  card: {
    backgroundColor: `${theme.palette.primary.greyMetal}05`,
    padding: '32px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '12px',
  },
  divider: {
    borderTop: '1px solid',
    borderColor: 'dashboard.borderSecondary',
    margin: '24px 0',
  },
});

export default styles;
