import {
  APPLICATION_FORM_FIELD_CALENDAR_URL,
  APPLICATION_FORM_FIELD_CURRENT_LOCATION,
  APPLICATION_FORM_FIELD_DEPARTMENT,
  APPLICATION_FORM_FIELD_GENDER_IDENTITY,
  APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE,
  APPLICATION_FORM_FIELD_SENIORITY_LEVEL,
  APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES,
  APPLICATION_FORM_FIELD_TITLE,
  APPLICATION_FORM_FIELD_VIDEO_URL,
  EMAIL,
  FIELD_EMPLOYEEID,
  FIELD_LINKEDINURL,
  FIRSTNAME,
  LASTNAME,
  PHONE_NUMBER,
  APPLICATION_FORM_TITLE_MATCHING_INFO,
  APPLICATION_FORM_FIELD_AFFILIATION,
} from 'litterals';
const separateData = (data, userRole) => {
  const ProfileInfo = {
    info: {
      firstname: { Label: FIRSTNAME },
      lastname: { Label: LASTNAME },
      email: { Label: EMAIL },
      platformContentLanguage: { Label: 'field_platform_content_language' },
      phoneNumber: { Label: PHONE_NUMBER },
      linkedinURL: { Label: FIELD_LINKEDINURL },
      employeeID: { Label: FIELD_EMPLOYEEID, Tooltip: 'tooltip_employee_id' },
    },
    title: 'ProfileInfo',
  };

  if (data.find(({ fieldName }) => fieldName === 'idNumber_secondary')) {
    ProfileInfo.info.idNumber_secondary = { Label: 'field_id_number_secondary' };
  }

  const MatchingInfo = {
    info: {
      currentLocation: { Label: APPLICATION_FORM_FIELD_CURRENT_LOCATION, Tooltip: 'tooltip_current_location' },
      sector: { Label: APPLICATION_FORM_FIELD_AFFILIATION, Tooltip: 'tooltip_sector' },
      seniorityLevel: { Label: APPLICATION_FORM_FIELD_SENIORITY_LEVEL, Tooltip: 'tooltip_seniority_level' },
      genderIdentity: { Label: APPLICATION_FORM_FIELD_GENDER_IDENTITY },
      spokenLanguages: { Label: APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES },
      department: { Label: APPLICATION_FORM_FIELD_DEPARTMENT, Tooltip: 'tooltip_department' },
      timeZone: { Label: 'application_form_field_time_zone' },
    },
    title: 'MatchingInfo',
  };

  const FormPic = {
    info: {
      picture: { Label: 'picture', Tooltip: 'tooltip_photo' },
    },

    title: 'picture',
  };

  let internal;
  let maxCapacity;
  let bio;
  let managerEmail;

  let objectives_expertises = {
    info: {
      objectives_expertises: { Label: 'objectives', Tooltip: 'tooltip_objectives' },
    },
    title: 'objectives',
  };

  const expectations = {
    info: {
      expectations: { Label: 'expectations_label', Tooltip: 'tooltip_expectations' },
    },
    title: 'expectations_label',
  };

  if (userRole === 'Coachee') {
    managerEmail = {
      info: {
        managerEmail: { Label: 'EmailManager', Tooltip: 'tooltip_manager_email' },
      },
      title: 'EmailManager',
    };
  }

  if (userRole === 'Caoch') {
    ProfileInfo.info = {
      ...ProfileInfo.info,
      title: { Label: APPLICATION_FORM_FIELD_TITLE, Tooltip: 'tooltip_title' },
      calendarURL: { Label: APPLICATION_FORM_FIELD_CALENDAR_URL, Tooltip: 'tooltip_calendar_url' },
      videoURL: { Label: APPLICATION_FORM_FIELD_VIDEO_URL, Tooltip: 'tooltip_video_url' },
      hoursOfExperience: { Label: APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE, Tooltip: 'tooltip_hours_of_experience' },
    };

    internal = {
      info: {
        internal: { Label: 'internal_or_external', Tooltip: 'tooltip_internal_external' },
      },
      title: 'internal_or_external',
    };
    maxCapacity = {
      info: {
        maxCapacity: { Label: 'maxCapacity', Tooltip: 'tooltip_maxCapacity' },
      },
      title: 'maxCapacity',
    };

    objectives_expertises = {
      info: {
        objectives_expertises: { Label: 'expertises', Tooltip: 'tooltip_expertises' },
      },
      title: 'expertises',
    };

    bio = {
      info: {
        bio: { Label: 'bio_label', Tooltip: 'tooltip_bio' },
      },
      title: 'bio_label',
    };
  }

  data.forEach((item) => {
    const { fieldName } = item;
    const fieldObject = { ...item };
    switch (fieldName) {
      case 'firstname':
      case 'lastname':
      case 'email':
      case 'platformContentLanguage':
      case 'phoneNumber':
      case 'linkedinURL':
      case 'employeeID':
      case 'title':
      case 'calendarURL':
      case 'videoURL':
      case 'idNumber_secondary':
      case 'hoursOfExperience':
        ProfileInfo.info[fieldName] = { ...ProfileInfo.info[fieldName], ...fieldObject };
        break;
      case 'currentLocation':
      case 'sector':
      case 'seniorityLevel':
      case 'genderIdentity':
      case 'spokenLanguages':
      case 'timeZone':
      case 'department':
        MatchingInfo.info[fieldName] = { ...MatchingInfo.info[fieldName], ...fieldObject };
        break;
      case 'picture':
        FormPic.info[fieldName] = { ...FormPic.info[fieldName], ...fieldObject };
        break;
      case 'internal':
        if (internal) {
          internal.info[fieldName] = { ...internal.info[fieldName], ...fieldObject };
        }
        break;
      case 'maxCapacity':
        if (maxCapacity) {
          maxCapacity.info[fieldName] = { ...maxCapacity.info[fieldName], ...fieldObject };
        }
        break;
      case 'objectives_expertises':
        objectives_expertises.info[fieldName] = { ...objectives_expertises.info[fieldName], ...fieldObject };
        break;
      case 'bio':
        if (bio) {
          bio.info[fieldName] = { ...bio.info[fieldName], ...fieldObject };
        }
        break;
      case 'expectations':
        expectations.info[fieldName] = { ...expectations.info[fieldName], ...fieldObject };
        break;
      case 'managerEmail':
        if (managerEmail) {
          managerEmail.info[fieldName] = { ...managerEmail.info[fieldName], ...fieldObject };
        }
      default:
        break;
    }
  });

  return {
    ProfileInfo,
    MatchingInfo,
    FormPic,
    internal,
    objectives_expertises,
    bio,
    expectations,
    managerEmail,
    maxCapacity,
  };
};

export default separateData;
