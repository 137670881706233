/* eslint-disable react/react-in-jsx-scope */
/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
import React from 'react';
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import RoleFilterOperators from 'ui/components/DataGrid/Filters/RoleFilter/RoleFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

const tableSchemaBase = [
  { field: 'user', flex: 1, headerName: 'name', minWidth: 250, fontSize: '2px', type: 'user', resizable: true },
  {
    field: 'roles',
    headerName: 'Roles',
    type: 'text',
    sortable: false,
    filter: false,
    filterable: false,
    minWidth: 130,
    filterOperators: RoleFilterOperators,
    resizable: true,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'email',
    type: 'text',
    minWidth: 130,
    resizable: true,
    flex: 1,
  },
  {
    field: 'scopeName',
    headerName: 'scope',
    type: 'text',
    minWidth: 250,
    filterOperators: ScopeFilterOperators,
    resizable: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'creation_date',
    resizable: true,
    minWidth: 180,
    type: 'text',
    filter: false,
    filterable: false,
    flex: 1,
    filterOperators: DateFilterOperators,
  },
  {
    field: 'isMainCompanyAdmin',
    headerName: 'is_main_company_admin',
    resizable: true,
    minWidth: 224,
    type: 'actionMainCompanyAdmin',
    sortable: false,
    filter: false,
    filterable: false,
    align: 'center',
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 120,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsAdmins',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    disableColumnMenu: true,
    resizable: true,
  },
];

export { tableSchemaBase };
