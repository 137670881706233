/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import { Tooltip } from '@mui/material';

import parse from 'html-react-parser';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellAdminNote({ adminNote }) {
  if (!adminNote) return '-';

  /* ******************************** RENDERING ******************************* */
  return (
    <Tooltip placement="bottom" title={parse(adminNote) || ''}>
      <span style={{ width: '100%' }}>{parse(adminNote)}</span>
    </Tooltip>
  );
}

export default CellAdminNote;
