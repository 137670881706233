import i18next from 'i18next';

const allVariables = Object.keys(i18next.t('email_template_editor.variables.values'));

const configVariable = {
  PROGRAM_DEACTIVATION: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  COACH_DEACTIVATION_NO_REACTIVATION: ['coach_firstname'],
  XLSX_MENTEE_CREATION: ['coachee_firstname', 'coachee_lastname'],
  XLSX_MENTOR_CREATION: ['coach_firstname', 'coach_lastname'],
  USER_IMPORTED_FIRST_CONNEXION: ['coachee_firstname', 'coachee_lastname'],
  MENTEE_EVERGREEN_VALIDATED: ['coachee_firstname', 'coachee_lastname', 'theme'],
  ADMIN_NEW_EVERGREEN_REQUEST_TO_VALIDATE: ['coachee_firstname', 'coachee_lastname', 'theme'],
  USER_SUBSCRIPTION_NOTIFICATION: allVariables,
  COACH_REACTIVATION: ['coach_firstname', 'coach_lastname'],
  COACH_DEACTIVATION: ['coach_firstname', 'coach_lastname'],
  ADMIN_NOTIFICATION_PENDING_PROGRAM_LAUNCHED: [
    'coachee_firstname',
    'coachee_lastname',
    'coach_firstname',
    'coach_lastname',
  ],
  MENTOR_SR_ONBOARDING: ['coach_firstname', 'coach_lastname'],
  COACH_EVERGREEN_REQUEST_CANCELLED: ['coach_firstname', 'coach_lastname', 'coachee_firstname', 'coachee_lastname'],
  SR_SUMMARY_MENTOR: [
    'coach_firstname',
    'coach_lastname',
    'coachee_firstname',
    'coachee_lastname',
    'program_expectations',
  ],
  SR_SUMMARY_MENTEE: [
    'theme',
    'coachee_firstname',
    'coachee_lastname',
    'coach_firstname',
    'coach_lastname',
    'program_expectations',
  ],
  MENTEE_SR_VALIDATED: ['theme', 'coachee_firstname', 'coachee_lastname'],
  ADMIN_NEW_SR_REQUEST: ['theme', 'admin_firstname', 'coachee_firstname', 'coachee_lastname'],
  MENTOR_SESSION_VALIDATION_REMINDER: ['coach_firstname', 'coach_lastname'],
  PROGRAM_COACH_TO_CHOOSE_REMINDER: ['theme', 'coachee_firstname', 'coachee_lastname'],
  MENTEE_SESSION_VALIDATION_REMINDER: ['coachee_firstname', 'coachee_lastname'],
  MENTOR_VALIDATE_OBJECTIVE_REMINDER: ['coach_firstname', 'coach_lastname', 'coachee_firstname', 'coachee_lastname'],
  MENTEE_VALIDATE_OBJECTIVE_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTEE_SET_OBJECTIVE_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTOR_SET_OBJECTIVE_REMINDER: ['coach_firstname', 'coach_lastname', 'coachee_firstname', 'coachee_lastname'],
  MENTOR_SESSION_SCHEDULING_REMINDER: ['coach_firstname', 'coach_lastname', 'coachee_firstname', 'coachee_lastname'],
  MENTEE_SESSION_SCHEDULING_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTOR_SESSION_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTEE_SESSION_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTOR_PROGRAM_SCHEDULING_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  MENTEE_PROGRAM_SCHEDULING_REMINDER: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
  ADMIN_COACHING_SUMMARY: [
    'admin_firstname',
    'admin_lastname',
    'theme',
    'coachee_firstname',
    'coachee_lastname',
    'coachesList',
  ],
  COACH_ONBOARDING: ['coach_firstname', 'coach_lastname'],
  FORGOT_PASSWORD: ['firstname', 'authentication_link'],
  COACHEE_INVITATION: [
    'admin_firstname',
    'admin_lastname',
    'coachee_firstname',
    'coachee_lastname',
    'authentication_link',
  ],
  EXPERT_CHOSEN_FOR_PROGRAM: [
    'admin_firstname',
    'admin_lastname',
    'coach_firstname',
    'coach_lastname',
    'coachee_firstname',
    'coachee_lastname',
    'authentication_link',
  ],
  RH_INVITATION: [''],
  COACHEE_PROGRAM_ENDING: ['coachee_firstname', 'coachee_lastname', 'theme'],
  COACH_PROGRAM_ENDING: ['coach_firstname', 'coach_lastname', 'theme'],
  LEADER_PROGRAM_ENDING: ['coachee_firstname', 'coachee_lastname', 'theme'],
  LEADER_NEW_PROGRAM_LAUNCHED: ['coachee_firstname', 'coachee_lastname', 'theme'],
  COACHEE_EVERGREEN_REQUEST: [
    'coachee_firstname',
    'coachee_lastname',
    'coach1_firstname',
    'coach1_lastname',
    'coach2_firstname',
    'coach2_lastname',
    'coach3_firstname',
    'coach3_lastname',
    'theme',
  ],
  COACH_EVERGREEN_ANSWER_REQUEST: ['coach_firstname', 'coach_lastname', 'authentication_link'],
  COACH_EVERGREEN_ANSWER_REQUEST_REMINDER1: ['coach_firstname', 'coach_lastname', 'authentication_link'],
  COACH_EVERGREEN_ANSWER_REQUEST_REMINDER2: ['coach_firstname', 'coach_lastname', 'authentication_link'],
  COACHEE_EVERGREEN_ABORTED: ['coachee_firstname', 'coachee_lastname'],
  COACHEE_EVERGREEN_VALIDATION: ['coachee_firstname', 'coachee_lastname', 'coach_firstname', 'coach_lastname'],
};

export const getTemplateEmailVariableAuthorized = (templateName) => {
  return configVariable[templateName];
};
