import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Modal from '@mui/material/Modal';
import IconButton from 'ui/components/system/IconButton';
import { useTranslation } from 'react-i18next';
import { useMeQuery, useListGuestsWithParams, useListCoachesWithParams } from 'hooks/queries';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import { getContrastTextColor } from 'common/utils';
import {
  ACCOUNT,
  COACHEE,
  COACH,
  EDIT_PROFILE,
  LANGUAGES,
  PROFILE,
  ROLE_COACH,
  ROLE_COACHEE,
  RESSOURCES,
  SETTINGS,
  MY_PROGRAMS,
} from 'litterals';
import { t } from 'i18next';
import { Badge, Tooltip, Typography } from '@mui/material';
import { useEditProfile } from 'hooks/usecase/useProfile';
import useLogin from 'hooks/usecase/useLogin';
import { roleList } from 'utils/constUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useController } from 'react-hook-form';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useIntercom } from 'react-use-intercom';
import { deleteUserIntercom } from 'utils/intercom';
import styles from './NavBar.styles';
import { languagesAccepted, srcLanguage } from 'utils/languages';
import { Roles } from 'utils/roles';
import { ReactComponent as AccountIcon } from 'svg/AccountCircleFilled.svg';
import { useTheme } from '@mui/material/styles';

const FlagIcon = ({ country }) => <Avatar src={srcLanguage(country)} sx={{ width: '26px', height: '26px' }} />;

const LanguageMenuItem = ({ current, handleSelect }) => {
  return languagesAccepted
    .filter((lng) => lng !== current)
    .map((lng, idx) => {
      return (
        <MenuItem onClick={() => handleSelect(lng)} key={`select-lng-key-${current}-${idx}`}>
          <FlagIcon country={lng} />
        </MenuItem>
      );
    });
};

const LanguageButton = ({ actions, user }) => {
  const { i18n, t } = useTranslation();
  const [lng, setLng] = React.useState(user?.platformContentLanguage || i18n.language.split('-')[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  if (lng !== i18n.language.split('-')[0]) {
    i18n.changeLanguage(lng);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (lng) => {
    handleClose();
    actions.changeLanguage(lng);
    i18n.changeLanguage(lng);
    setLng(lng);
  };

  return (
    <>
      <IconButton onClick={handleClick} icon={<FlagIcon country={lng} />} tooltip={t(LANGUAGES)} />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <LanguageMenuItem current={lng} handleSelect={handleSelect} />
      </Menu>
    </>
  );
};

const ProfileMenuButton = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (path) => () => {
    navigate(path);
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <>
      <IconButton tooltip={t(PROFILE)} icon={<AccountCircleIcon sx={{ fontSize: '28px' }} />} onClick={handleClick} />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem onClick={goTo('/profile')}>{t(ACCOUNT)}</MenuItem>
        <MenuItem onClick={goTo(`/coach/${user.id}`)}>{t(PROFILE)}</MenuItem>
        <MenuItem onClick={goTo(`coach/edit/${user.id}`)}>{t(EDIT_PROFILE)}</MenuItem>
      </Menu>
    </>
  );
};

const RoleName = ({ role }) => {
  return (
    <div style={{ padding: '6px 16px' }}>
      <span style={styles.roleName}>{role}</span>
    </div>
  );
};

const ResourceItem = () => {
  return (
    <NavButton
      onClick={() => window.open(`${process.env.REACT_APP_PATHLINE_RESSOURCES_LINK}`, '_blank')}
      label={
        <>
          {t(RESSOURCES)}
          <OpenInNewIcon
            sx={{
              ml: 1,
            }}
          />
        </>
      }
    />
  );
};

const Settings = ({ children, user, role: currentRole, autoTextColor }) => {
  const theme = useTheme();
  const { shutdown } = useIntercom();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    state: { query, controlForm },
    actions,
  } = useEditProfile();

  const navigate = useNavigate();
  const {
    actions: { onLogout },
  } = useLogin();

  const onClick = () => {
    shutdown();
    deleteUserIntercom();
    onLogout();
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);

  const handleCloseModal = () => setIsOpen(false);

  const { t } = useTranslation();
  let options = useMemo(() => {
    const availableRole = query.data?.availableRoles;
    if (!availableRole || availableRole?.length === 0) {
      return [];
    }
    return availableRole.map((role) => ({
      label:
        role === roleList.COACH
          ? user?.company?.expertLabel || t(ROLE_COACH)
          : role === roleList.COACHEE
          ? user?.company?.clientLabel || t(ROLE_COACHEE)
          : role === roleList.RH_MANAGER
          ? roleList.ADMIN
          : role === roleList.RH
          ? roleList.ADMIN
          : role
          ? role
          : '',
      value: role,
    }));
  }, [query.data?.availableRoles, t]);

  const defaultValue = useMemo(() => user?.role || '', [user?.role]);

  const getRoleLabel = () => {
    switch (currentRole.toLowerCase()) {
      case 'coach':
        return user?.company?.expertLabel;
      case 'coachee':
        return user?.company?.clientLabel;
      default:
        return currentRole;
    }
  };

  const colorText = getContrastTextColor(autoTextColor || '#ffffff', 'rgb(0 0 0 / 54%)');

  const {
    field: { onChange, value },
  } = useController({ control: controlForm, name: 'role', defaultValue: defaultValue });

  const defaultAvatarColor = colorText === 'white' || colorText === '#ffffff' ? 'white' : theme.palette.primary.main;

  const menuItemSettings = (
    <MenuItem
      onClick={() => navigate('/settings')}
      style={{ backgroundColor: location.pathname == '/settings' ? '#f3f3f3' : 'inherit' }}>
      <SettingsIcon sx={{ mr: 1 }} />
      {t(SETTINGS)}
    </MenuItem>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Modal
        sx={{ outline: 'none' }}
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.containerDisconnect}>
          <Box sx={{ padding: '16px 24px' }}>
            <Typography
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'karla',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '32px',
              }}>
              {t('label_disconnect')}
            </Typography>
          </Box>
          <Box sx={{ padding: '8px 24px' }}>
            <Typography
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'karla',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}>
              {t('message_disconnect')}
            </Typography>
          </Box>
          <Box sx={{ padding: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseModal} size="small" variant="text">
              {t('no')}
            </Button>
            <Button onClick={onClick} size="small" variant="text">
              {t('yes')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <LanguageButton user={user} actions={actions} />
      {children}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon sx={{ color: colorText }} />}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                gap: '10px',
              }}>
              {!user?.picture && <AccountIcon style={{ fill: defaultAvatarColor }} />}
              {user?.picture && (
                <Avatar
                  src={user?.picture}
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: 'white',
                    margin: 'auto',
                    bgcolor: 'transparent',
                    borderRadius: '0px',
                  }}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                <span style={{ fontSize: '16px', color: colorText }}>{t('my_profil')}</span>
                <span style={{ fontSize: '12px', color: colorText }}>
                  <span style={{ fontSize: '12px', color: colorText }}>{getRoleLabel()}</span>
                </span>
              </div>
            </div>
          </div>
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {user?.email && user?.firstname && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '6px 16px' }}>
              <span
                style={{
                  minWidth: '120px',
                  backgroundColor: 'inherit',
                  cursor: 'auto',
                  fontSize: '16px',
                  fontFamily: 'Karla',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.15000000596046448px',
                  textAlign: 'left',
                }}>
                {user?.firstname} {user?.lastname}
              </span>
              <span
                style={{
                  minWidth: '120px',
                  height: '30px',
                  backgroundColor: 'inherit',
                  cursor: 'auto',
                  fontSize: '14px',
                  fontFamily: 'Karla',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.17000000178813934px',
                  textAlign: 'left',
                  color: ' var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
                }}>
                {user?.email}
              </span>
            </div>
            <Divider />
          </>
        )}

        {user?.role == roleList.COACH ? (
          <>
            <MenuItem
              onClick={() => navigate(`/coach/${user.id}`)}
              style={{ backgroundColor: location.pathname == `/coach/${user.id}` ? '#f3f3f3' : 'inherit' }}>
              <AccountCircleIcon sx={{ mr: 1 }} />
              {t(PROFILE)}
            </MenuItem>
            {menuItemSettings}
          </>
        ) : (
          <>
            {user?.role == roleList.COACHEE && (
              <MenuItem
                onClick={() => navigate(`/coachee/${user.id}`)}
                style={{ backgroundColor: location.pathname == `/coachee/${user.id}` ? '#f3f3f3' : 'inherit' }}>
                <AccountCircleIcon sx={{ mr: 1 }} />

                {t(ACCOUNT)}
              </MenuItem>
            )}
            {menuItemSettings}
          </>
        )}
        <Divider />
        {query.data?.availableRoles?.length > 1 ? (
          <>
            <div style={{ padding: '4px 8px 8px 8px' }}>{t('switch_role')}</div>
            {options.map((option) => (
              <>
                <MenuItem
                  name={'role'}
                  key={option}
                  onClick={() => {
                    onChange(option.value);
                    actions.submit();
                    navigate('/');
                  }}
                  style={{
                    minWidth: '120px',
                    height: '40px',
                    backgroundColor: value === option.value ? '#1509BF14' : 'inherit',
                  }}
                  control={controlForm}>
                  {option.label}
                </MenuItem>
              </>
            ))}
          </>
        ) : (
          <>
            <div style={{ padding: '4px 8px 8px 8px' }}>{t('role')}</div>

            <RoleName role={getRoleLabel()} />
          </>
        )}
        <Divider />
        <MenuItem onClick={handleOpenModal}>{t('log_out')}</MenuItem>
      </Menu>
    </Box>
  );
};

const HelpButton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        component="Help"
        variant="text"
        startIcon={
          <IconButton
            sx={{ padding: '8px 0px 8px 8px' }}
            tooltip={t('help_page_title')}
            icon={<HelpOutlineIcon sx={{ fontSize: '28px' }} />}
            onClick={() => window.open('https://intercom.help/pathline/fr/', '_blank')}></IconButton>
        }
        onClick={() => window.open('https://intercom.help/pathline/fr/', '_blank')}>
        <p style={{ color: '#000000DE' }}>{t('need_help')}</p>
      </Button>
    </div>
  );
};

const stylesButton = (location, typeButton) => {
  if (location.pathname === `${typeButton}`) {
    return { ...styles.navBarButton, ...styles.navBarButtonSelected };
  }

  return styles.navBarButton;
};

// eslint-disable-next-line no-unused-vars
const NavButton = ({ label, path, onClick, disabled, tooltip, children }) => (
  <Tooltip title={tooltip} arrow>
    <Button onClick={disabled ? null : onClick} sx={stylesButton(location, path)}>
      {label || children}
    </Button>
  </Tooltip>
);

const Navigation = ({ company, children, homeHidden = false, programHidden = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useMeQuery();

  const typeMenu = user?.role === Roles.RH || user?.role === Roles.RH_MANAGER ? 'rh' : 'default';

  const selectVariant = (match) => (location.pathname === match ? 'contained' : 'text');
  const buttons =
    children &&
    React.Children.toArray(children).map((child) => {
      const variant = selectVariant(child.props.path);
      const onClick = child.props
        ? child.props.onClick
          ? child.props.onClick
          : () => navigate(child.props.path)
        : null;
      const button = React.cloneElement(child, {
        ...child.props,
        variant,
        onClick,
        sx: { margin: styles.navBarButton.margin },
      });
      return button;
    });
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      {company?.logo ? (
        <Box component="img" src={company.logo} alt="Logo" sx={styles.logo} onClick={() => navigate('/')} />
      ) : null}

      {!homeHidden && <NavButton onClick={() => navigate('/')} path="/" label={t(`menu.${typeMenu}.home`)} />}
      {!programHidden && (
        <NavButton onClick={() => navigate('/programs')} path="/programs" label={t(`menu.${typeMenu}.programs`)} />
      )}
      {buttons}
    </Box>
  );
};

const PathlineBar = ({ children, isFixed, color }) => {
  const fixed = isFixed ? { position: 'fixed' } : {};

  return (
    <AppBar sx={{ zIndex: 1, bgcolor: color || 'background.paper', ...fixed, boxShadow: 'none' }} position="static">
      <Toolbar sx={{ justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD' }}>{children}</Toolbar>
    </AppBar>
  );
};

const SelfRegistrationBar = ({ company, user, isFixed }) => {
  const roleToBeDisplayed = {
    [roleList.RH]: roleList.ADMIN,
    [roleList.RH_MANAGER]: roleList.ADMIN,
  };

  return (
    <PathlineBar color={company?.navbarColor_selfInscription} isFixed={isFixed}>
      <Navigation company={company} homeHidden programHidden />

      {!!user && (
        <Settings
          autoTextColor={company?.navbarColor_selfInscription}
          user={user}
          role={roleToBeDisplayed[user?.role] || user?.role}
        />
      )}
    </PathlineBar>
  );
};

const RhBar = ({ isFixed, programType, user = {} }) => {
  const { company } = user;
  const { t } = useTranslation();
  const { data } = useListGuestsWithParams(
    { type: 'RH_Validation', page: 1, size: 10 },
    { enabled: !!(company.selfRegistrationActivated || company.manualValidation) }
  );
  const { data: coachData } = useListCoachesWithParams(
    { rhValidation: true, page: 1, size: 10 },
    { enabled: !!company.manualValidation }
  );

  const badgeContent = Number(data?.totalItems || 0) + Number(coachData?.totalItems || 0) || null;

  return (
    <PathlineBar isFixed={isFixed}>
      <Navigation company={company}>
        <NavButton path="/users">
          <Badge
            style={{ margin: 0 }}
            sx={{ '.MuiBadge-badge': { top: '-3px !important', right: '-8px' } }}
            color="error"
            badgeContent={badgeContent}
            max={9}>
            {t('users')}
          </Badge>
        </NavButton>
        {user.role === roleList.RH_MANAGER && <NavButton path="/customizaton" label={t('customization')} />}
        <NavButton path="/reporting" label={t('REPORTING')} />
        {programType && programType === 'Mentoring' && <ResourceItem />}
      </Navigation>
      <Settings user={user} role={roleList.ADMIN}>
        <HelpButton />
      </Settings>
    </PathlineBar>
  );
};

const AdminBar = ({ isFixed, user }) => {
  return (
    <PathlineBar isFixed={isFixed}>
      <Navigation company={user?.company} programHidden />
      <Settings user={user} role={roleList.ADMIN}>
        <HelpButton />
      </Settings>
    </PathlineBar>
  );
};

const CoachBar = ({ isFixed, user, programType }) => {
  return (
    <PathlineBar isFixed={isFixed}>
      <Navigation company={user?.company}>
        {/* {user.company && user.company.documentationExpert && (
          <NavButton
            onClick={() => window.open(`${user.company.documentationExpert}`, '_blank')}
            label={t(ONBOARDING_KIT)}
          />
        )} */}
        {programType && programType === 'Mentoring' && <ResourceItem />}
      </Navigation>
      <Settings user={user} role={COACH}>
        <HelpButton />
      </Settings>
    </PathlineBar>
  );
};

const CoacheeBar = ({ isFixed, user, programType }) => {
  const { t } = useTranslation();

  return (
    <PathlineBar isFixed={isFixed}>
      {user.company?.documentationCollaborator && user.company.documentationCollaborator.length > 0 && (
        <Navigation company={user?.company}>
          {/* <NavButton
            onClick={() => window.open(`${user.company.documentationCollaborator}`, '_blank')}
            label={t(ONBOARDING_KIT)}
          /> */}
          <ResourceItem />
        </Navigation>
      )}
      {!user?.company?.documentationCollaborator && (
        <Navigation company={user?.company}>
          {programType && programType === 'Mentoring' && <ResourceItem />}
        </Navigation>
      )}
      <Settings user={user} role={COACHEE}>
        <HelpButton />
      </Settings>
    </PathlineBar>
  );
};

const NavBar = ({ company, isFixed = false, isRegistrationFlowWithMyAccount = false }) => {
  const { data: user } = useMeQuery();
  //PAT- ProgramType gives the information whether a company does mentoring programs or not
  const programType = user?.company?.sessionLabel;

  if (isRegistrationFlowWithMyAccount) {
    return <SelfRegistrationBar company={company} user={user} isFixed={isFixed} />;
  }

  switch (user?.role) {
    case roleList.RH:
    case roleList.RH_MANAGER:
      return <RhBar company={company} user={user} programType={programType} isFixed={isFixed} />;
    case roleList.ADMIN:
      return <AdminBar isFixed={isFixed} />;
    case roleList.COACH:
      return <CoachBar user={user} programType={programType} isFixed={isFixed} />;
    case roleList.COACHEE:
      return <CoacheeBar user={user} programType={programType} isFixed={isFixed} />;
    default:
      return (
        <PathlineBar isFixed={isFixed}>
          <Navigation company={user?.company}></Navigation>
        </PathlineBar>
      );
  }
};

export default NavBar;
