const styles = {
  navbar: {
    bgcolor: 'background.paper',
    border: '1px solid #D0D5DD',
    boxShadow: 'none',
  },
  container: {
    backgroundColor: 'white',
    overflowY: 'scroll',
    paddingTop: '100px',
    paddingBottom: '40px',
  },
  containerForm: {
    backgroundColor: 'white',
    width: {
      xl: '610px',
      sm: '610px',
      xs: '300px',
    },
    paddingTop: '150px',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  containerCard: {
    backgroundColor: 'white',
    width: {
      xl: '1070px',
      sm: '630px',
      xs: '290px',
    },
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  containerBand: {
    height: '15px',
    backgroundColor: 'primary.main',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  containerTexts: {
    border: '0px solid purple',
    padding: '15px 20px',
  },

  containerInput: {
    backgroundColor: 'white',
    width: {
      xl: '1023px',
      sm: '580px',
      xs: '250px',
    },
    padding: '15px 25px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  containerMultiSelect: {
    backgroundColor: 'white',
    width: {
      xl: '1023px',
      sm: '580px',
      xs: '250px',
    },
    padding: '15px 20px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  containerRadio: {
    backgroundColor: 'white',
    width: {
      xl: '1023px',
      sm: '580px',
      xs: '250px',
    },
    minHeight: {
      xl: '130px',
      sm: '130px',
      xs: '150px',
    },
    padding: '15px 20px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  containerImage: {
    backgroundColor: 'white',
    width: {
      xl: '1070px',
      sm: '630px',
      xs: '290px',
    },

    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '30px',
    fontFamily: 'Karla',
    fontWeight: 700,
    lineHeight: '46.76px',
  },
  description: {
    fontSize: '14px',
    fontFamily: 'Karla',
    fontWeight: 500,
    lineHeight: '18.7px',
  },
  required: {
    color: 'red',
  },

  containerSuccess: {
    backgroundColor: 'white',
    width: {
      xl: '610px',
      sm: '610px',
      xs: '290px',
    },
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    marginBottom: '15px',
    padding: '40px 40px',
  },
  successTitle: {
    fontSize: '18px',
    fontFamily: 'Karla',
    fontWeight: 700,
    lineHeight: '46.76px',
  },
  successDescription: {
    fontSize: { xs: '11px', sm: '12px', md: '13px' },
    fontFamily: 'Karla',
    fontWeight: 500,
    lineHeight: '15.2px',
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    width: '74px',
  },
  spacerLabelTop: {
    height: '5px',
  },
  spacerBetweenRows: {
    height: '37px',
  },
};
export default styles;
