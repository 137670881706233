import React, { useState } from 'react';
import { Typography, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import Button from 'ui/components/Button/Button';

const ModalSelectLangues = ({ langues = [], open, onClose, onAddLangueClick }) => {
  const { t } = useTranslation();
  const [langueSelected, setLangueSelected] = useState('');

  const handleChange = (event) => {
    setLangueSelected(event.target.value);
  };

  return (
    <ViewModal
      customStyleBox={{
        maxWidth: '500px',
        height: 'max-content',
        borderRadius: '16px',
      }}
      open={open}
      onClose={onClose}>
      <Box>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              paddingBottom: '16px',
            }}>
            {t('tab-langues.modal.title')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', margin: 'auto', lineHeight: '25px', fontWeight: 300 }}>
          <Typography variant="p">{t('tab-langues.modal.desc')}</Typography>

          <FormControl fullWidth sx={{ margin: '16px 0px 8px 0px' }}>
            <InputLabel id="select-langue">{t('tab-langues.modal.label')}</InputLabel>
            <Select
              labelId="select-langue"
              value={langueSelected}
              label={t('tab-langues.modal.label')}
              onChange={handleChange}>
              {langues.map((lng) => {
                return (
                  <MenuItem key={lng} value={lng}>
                    {t(`lang_${lng}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            paddingTop: '20px',
            textAlign: 'right',
          }}>
          <Button onClick={onClose} variant="secondary">
            {t('cancel')}
          </Button>
          <Button
            sx={{ marginLeft: '16px' }}
            disabled={!langueSelected}
            onClick={() => {
              onClose();
              onAddLangueClick(langueSelected);
              setLangueSelected('');
            }}>
            {t('tab-langues.modal.button_validate')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
};

export default ModalSelectLangues;
