import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import { Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_OPTIONS, getLabelByValue } from 'utils/languages';
import { t } from 'i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
  };
}

const sortByAlphabetical = () => {
  return function (a, b) {
    return a.translation.localeCompare(b.translation);
  };
};

const sortedByUserLanguage = () => {
  const translatedList = LANGUAGES_OPTIONS.map((item) => ({ ...item, translation: t(item.label) }));
  return translatedList.sort(sortByAlphabetical());
};

const MultiSelect = (props) => {
  const {
    fieldState: { error },
    field: { onChange, value },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });

  const theme = useTheme();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSelectedItems(event.target.value);
    onChange(event.target.value);
  };

  const handleRemoveItem = (itemToRemove) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemToRemove));
    onChange(selectedItems.filter((item) => item !== itemToRemove));
  };

  React.useEffect(() => {
    if (value && value.length === 0) {
      setSelectedItems([]);
    } else if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  const options = props?.options || sortedByUserLanguage();

  const getLabelWithValue = (value) => {
    if (!props?.options) {
      return t(getLabelByValue(value));
    }

    return options.find((line) => line.value === value)?.label;
  };

  return (
    <div>
      <FormControl sx={{ minWidth: '100%' }}>
        <InputLabel sx={props.variant ? {} : { marginLeft: '-15px' }} error={!!error} id="demo-multiple-chip-label77">
          {props.label}
        </InputLabel>
        <Select
          error={!!error}
          id="demo-multiple-chip"
          multiple
          value={selectedItems}
          onChange={handleChange}
          disabled={props.disabled}
          label={props.variant && props.label ? props.label : undefined}
          variant={props.variant || 'standard'}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((option) => (
                <Chip
                  size="small"
                  key={option}
                  label={getLabelWithValue(option)}
                  onDelete={() => {
                    if (props.disabled) return;
                    handleRemoveItem(option);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} style={getStyles(theme)}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default MultiSelect;
