/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getContentTranslate } from 'utils/parse';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

// UI Local components
import Input from 'ui/components/Input';
import TitleInput from 'ui/components/TitleInput';
import EditorField from 'ui/components/EditorField';
import Editor from 'ui/components/Editor';
import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';
import TabLangues from 'ui/components/TabLangues/TabLangues';

import DeleteModal from 'ui/pages/home/admin/DeleteModal';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// UI Local Lib dependencies
import DataGridReorder from 'ui/components/DataGridReorder';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Hooks
import useSessionTemplates from 'hooks/usecase/useSessionTemplates';

// Style
import styles from './styles';
import { t } from 'i18next';
import { TITLE } from 'litterals';
import { truncateString } from 'common/utils';

// Configs
import { tableSchemaBase as columns } from './SessionTemplates.config';

const SessionTemplates = ({ companyId, themeId, user }) => {
  const {
    onEditsessionTemplate,
    editingsessionTemplate,
    setEditingsessionTemplate,
    sessionTemplates,
    onAddsessionTemplate,
    reset,
    control,
    watch,
    isOpenAddModal,
    isOpenEditModal,
    setIsOpenAddModal,
    setIsOpenEditModal,
    isLoading,
    onRemovesessionTemplate,
    onDuplicatesessionTemplate,
    onReorderSessionTemplates,
    actionsLanguage,
  } = useSessionTemplates(companyId, themeId);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const theme = useTheme();
  const themedStyles = styles(theme);

  // Helper
  // eslint-disable-next-line no-unused-vars
  const updateList = (sessionTemplates) =>
    sessionTemplates.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        marketingPicture: currentItem.marketingPicture + `?${Date.now()}`,
      };
      acc.push(modifiedItem);

      return acc;
    }, []);

  return (
    <Stack sx={{ height: '500px', backgroundColor: 'white', paddingTop: '50px' }}>
      <Stack sx={themedStyles.containerButton}>
        <Button
          endIcon={<AddIcon />}
          onClick={() => {
            setIsOpenAddModal(true);
          }}
          sx={themedStyles.addButton}>
          ADD SESSION TEMPLATE
        </Button>
      </Stack>
      {/** DataGrid */}
      <DataGridReorder
        onEdit={(sessionTemplate) => {
          setEditingsessionTemplate(sessionTemplate);
        }}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onDuplicate={(data) => {
          // eslint-disable-next-line no-unused-vars
          const { id, ...rest } = data;
          onDuplicatesessionTemplate(rest);
        }}
        onRowClicked={(data, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingsessionTemplate(data.row);
          }
        }}
        // eslint-disable-next-line no-unused-vars
        onPaginationChange={(data) => {}}
        onReorder={(data) => {
          onReorderSessionTemplates(data);
        }}
        rows={sessionTemplates ? sessionTemplates : []}
        columns={columns}
        loading={isLoading}
      />
      {/** Create  dialog */}
      {isOpenAddModal && (
        <CreateSessionTemplate
          actions={actionsLanguage}
          onAddsessionTemplate={onAddsessionTemplate}
          setOpen={setIsOpenAddModal}
          isOpen={isOpenAddModal}
          control={control}
          reset={reset}
          watch={watch}
          user={user}
        />
      )}
      {/**  Edit dialog */}
      {isOpenEditModal && (
        <EditSessionTemplate
          actions={actionsLanguage}
          sessionTemplateId={editingsessionTemplate?.id}
          onEditsessionTemplate={onEditsessionTemplate}
          setOpen={() => {
            setIsOpenEditModal(false);
            setEditingsessionTemplate(null);
          }}
          isOpen={isOpenEditModal}
          control={control}
          reset={reset}
          watch={watch}
          user={user}
        />
      )}

      <DeleteModal
        title="Delete session template"
        description="Are you sure you want to delete this session template"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemovesessionTemplate(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

const CreateSessionTemplate = ({ actions, control, isOpen, setOpen, onAddsessionTemplate, reset, watch }) => {
  const muiTheme = useTheme();
  const themedStyles = styles(muiTheme);
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const [languageSelected, setLanguageSelect] = useState('');

  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const {
    control: internalControl,
    setValue: internalSetValue,
    watch: internalWatch,
  } = useForm({ mode: 'all', defaultValues: { session_title: '', session_agenda: '' } });

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    actions.addLanguage(lng);
    setLanguage(lng, true);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    actions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const saveInControl = () => {
    actions.setValueLanguage(languageSelected, 'session_title', internalWatch('session_title'));
    actions.setValueLanguage(languageSelected, 'session_agenda', internalWatch('session_agenda'));
  };

  const setLanguage = (language, send = true) => {
    if (languageSelected && send) {
      saveInControl();
    }

    setLanguageSelect(language);

    const currentTitle = language ? getContentTranslate(watch('session_title'), language) : '';
    const currentContent = language ? getContentTranslate(watch('session_agenda'), language) : '';

    internalSetValue('session_title', currentTitle, { shouldDirty: true, shouldValidate: true });
    internalSetValue('session_agenda', currentContent, { shouldDirty: true, shouldValidate: true });
  };

  const handleCreate = () => {
    saveInControl();
    onAddsessionTemplate();
    setOpen(false);
  };

  const onChangeVariablePart = (value) => {
    internalSetValue('session_agenda', value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: themedStyles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={themedStyles.containerTitle}>
        <Tooltip title={watch('session_title')}>
          <Typography style={themedStyles.title}>
            {'Create Session Template ' + truncateString(`${internalWatch('session_title')}`, 30)}
          </Typography>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={themedStyles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={themedStyles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white' }}>
          <TabLangues
            languageSelected={languageSelected}
            languagesEnabled={languagesConfigured}
            onAddLangueClick={onAddLangueClick}
            onDeleteLangue={onDeleteLangue}
            onLangueClick={onLangueClick}
          />
          <TitleInput
            disabled={!languageSelected}
            // eslint-disable-next-line no-unused-vars
            exportTitle={(title) => {
              //handleChangeSession(selectedSession.id, { name: title });
            }}
            control={internalControl}
            actions={() => {}}
            session={null}
            placeholder={t(TITLE)}
            name="session_title"
            sx={themedStyles.titleContent}
            inputProps={{ style: themedStyles.titleContent }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={themedStyles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="Order"
                name="session_order"
                type="number"
              />
            </Box>
          </Box>
          {languageSelected && (
            <Box sx={themedStyles.yooptaContainer}>
              <YooptaEditor
                key={`${languageSelected}_new_session`}
                disablePlugins={['accordion']}
                folderUpload={`session-template/uploads/`}
                style={{ width: '100%' }}
                value={internalWatch('session_agenda') || {}}
                onChange={onChangeVariablePart}
              />
            </Box>
          )}
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={themedStyles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={themedStyles.btnSave}
          onClick={() => {
            handleCreate();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditSessionTemplate = ({
  actions,
  control,
  isOpen,
  setOpen,
  onEditsessionTemplate,
  sessionTemplateId,
  reset,
  watch,
  user,
}) => {
  const muiTheme = useTheme();
  const themedStyles = styles(muiTheme);
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const [languageSelected, setLanguageSelect] = useState('');

  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const sessionTitle = watch('session_title');

    if (sessionTitle && typeof sessionTitle === 'object' && sessionTitle !== null) {
      const languages = Object.keys(sessionTitle);
      setLanguagesConfigured(languages);

      if (languages.length && !languageSelected) setLanguage(languages[0]);
    } else {
      setLanguagesConfigured([]);
      setLanguage('', false);
    }
  }, [sessionTemplateId]);

  const {
    control: internalControl,
    setValue: internalSetValue,
    watch: internalWatch,
  } = useForm({ mode: 'all', defaultValues: { session_title: '', session_agenda: '' } });

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    actions.addLanguage(lng);
    setLanguage(lng, true);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    actions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const saveInControl = () => {
    actions.setValueLanguage(languageSelected, 'session_title', internalWatch('session_title'));
    actions.setValueLanguage(languageSelected, 'session_agenda', internalWatch('session_agenda'));
  };

  const setLanguage = (language, send = true) => {
    if (languageSelected && send) {
      saveInControl();
    }

    setLanguageSelect(language);

    const currentTitle = language ? getContentTranslate(watch('session_title'), language) : '';
    const currentContent = language ? getContentTranslate(watch('session_agenda'), language) : '';

    internalSetValue('session_title', currentTitle, { shouldDirty: true, shouldValidate: true });
    internalSetValue('session_agenda', currentContent, { shouldDirty: true, shouldValidate: true });
  };

  const handleEdit = () => {
    saveInControl();
    onEditsessionTemplate();
    setOpen(false);
  };

  const onChangeVariablePart = (value) => {
    internalSetValue('session_agenda', value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: themedStyles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle>
        <Tooltip title={watch('session_title')}>
          <Typography style={themedStyles.title}>
            {'Edit Session Template ' + truncateString(`${internalWatch('session_title')}`, 30)}
          </Typography>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={themedStyles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={themedStyles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white' }}>
          <TabLangues
            languageSelected={languageSelected}
            languagesEnabled={languagesConfigured}
            onAddLangueClick={onAddLangueClick}
            onDeleteLangue={onDeleteLangue}
            onLangueClick={onLangueClick}
          />
          <TitleInput
            disabled={!languageSelected}
            // eslint-disable-next-line no-unused-vars
            exportTitle={(title) => {
              //handleChangeSession(selectedSession.id, { name: title });
            }}
            control={internalControl}
            actions={() => {}}
            session={null}
            placeholder={t(TITLE)}
            name="session_title"
            sx={themedStyles.titleContent}
            inputProps={{ style: themedStyles.titleContent }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={themedStyles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="Order"
                name="session_order"
                type="number"
                disabled={!languageSelected}
              />
            </Box>
          </Box>
          {languageSelected && (
            <Box sx={themedStyles.yooptaContainer}>
              <YooptaEditor
                key={`${languageSelected}_${sessionTemplateId}`}
                disablePlugins={['accordion']}
                folderUpload={`session-template/${sessionTemplateId}/uploads/`}
                style={{ width: '100%' }}
                value={internalWatch('session_agenda') || {}}
                onChange={onChangeVariablePart}
              />
            </Box>
          )}
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={themedStyles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={themedStyles.btnSave}
          onClick={() => {
            handleEdit();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTemplates;
