import React from 'react';
import ProgramsRh from './ProgramsRh';
import ProgramsCoach from './ProgramsCoach';
import ProgramsCoachee from './ProgramsCoachee';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import { useMeQuery } from 'hooks/queries';
import { roleList } from 'utils/constUtils';

const Programs = () => {
  const { data: user } = useMeQuery();

  return (
    <Switch value={user.role}>
      <Case validate={(value) => [roleList.RH, roleList.RH_MANAGER].includes(value)}>
        <ProgramsRh user={user} company={user.company} />
      </Case>
      <Case value={roleList.COACH}>
        <ProgramsCoach user={user} />
      </Case>
      <Case value={roleList.COACHEE}>
        <ProgramsCoachee user={user} />
      </Case>
    </Switch>
  );
};

export default Programs;
