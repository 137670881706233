import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import { Box, Typography } from '@mui/material';
import styles from './ToDoListShortcut.styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from 'ui/components/Button/Button';
import { useNavigate } from 'react-router-dom';

const SHORTCUTS_CONFIG = {
  theme: '/customizaton#themes',
  coach: '/users',
  sector: '/settings#sector',
  department: '/settings#department',
  seniority: '/settings#seniority',
  objective: '/customizaton',
  registration_form: '/customizaton#registration-form',
  program: '/programs',
};

const ToDoListShortcut = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mouseInShortcut, setMouseInShortcut] = useState('');

  const onClickShortcut = (key) => {
    navigate(SHORTCUTS_CONFIG[key]);
  };

  const onMouseEnter = (key) => {
    setMouseInShortcut(key);
  };

  const onMouseLeave = (key) => {
    if (mouseInShortcut === key) setMouseInShortcut('');
  };

  return (
    <DashboardCard sx={styles.card}>
      <Box sx={styles.header}>
        <Typography sx={styles.title}>{t('to-do-list-shortcut.title')}</Typography>
      </Box>
      <Box sx={styles.container}>
        {Object.keys(SHORTCUTS_CONFIG).map((key) => {
          return (
            <Box
              key={key}
              sx={styles.line}
              onClick={() => onClickShortcut(key)}
              onMouseEnter={() => onMouseEnter(key)}
              onMouseLeave={() => onMouseLeave(key)}>
              <Box sx={styles.text}>
                <Trans
                  i18nKey={`to-do-list-shortcut.shortcuts.${key}`}
                  components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline', fontSize: '14px' }} /> }}
                />
              </Box>

              {key === mouseInShortcut && (
                <Button variant="secondary" isGrey size="xs" sx={styles.buttonGoToDetail}>
                  {t('to-do-list-shortcut.go-to')}
                  <ArrowForwardIosIcon fontSize="16px" sx={{ marginLeft: '8px' }} />
                </Button>
              )}

              {key !== mouseInShortcut && (
                <Button variant="secondary" isGrey size="xs" sx={styles.buttonGoTo}>
                  <ArrowForwardIosIcon fontSize="16px" />
                </Button>
              )}
            </Box>
          );
        })}
      </Box>
    </DashboardCard>
  );
};

export default ToDoListShortcut;
