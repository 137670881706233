import React from 'react';

import Stack from '@mui/material/Stack';
import { ThemeStatusChip } from 'ui/components/ThemeStatusChip/ThemeStatusChip';

import { useTranslation } from 'react-i18next';

function CellThemeStatus({ status }) {
  return (
    <Stack sx={{ gap: '12px', flexDirection: 'row' }}>
      <ThemeStatusChip status={status} />
    </Stack>
  );
}

export default CellThemeStatus;
