const styles = {
  card: {
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid',
    borderColor: 'dashboard.borderSecondary',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loadingContainer: {
    height: '100%',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    height: '32px',
    alignContent: 'center',
  },
};

export default styles;
