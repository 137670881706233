import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import styles from './SharingLinkBlock.styles';
import Button from 'ui/components/Button/Button';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const SharingLinkBlock = ({ type, clientLabel, expertLabel, onClick }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={styles.header}>
        <Box sx={styles.title}>
          <ArrowCircleRightOutlinedIcon sx={styles.iconTitle} />

          <Trans
            i18nKey={`sharing_links_card.${type}.title`}
            components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline', color: 'primary.main' }} /> }}
            values={{ clientLabel, expertLabel }}
          />
        </Box>

        <Button isGrey variant="secondary" size="xs" sx={styles.buttonShare} onClick={onClick}>
          <ContentCopyOutlinedIcon sx={styles.buttonShareIcon} />
          {t('sharing_links_card.button_share')}
        </Button>
      </Box>

      {t(`sharing_links_card.${type}.description`).map((key, index) => (
        <Box key={key} sx={styles.description}>
          <Trans
            i18nKey={`sharing_links_card.${type}.description.${index}`}
            components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline' }} /> }}
            values={{ clientLabel, expertLabel }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SharingLinkBlock;
