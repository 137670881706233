const styles = {
  container: {
    height: '100%',
    padding: '20px 24px',
    boxSizing: 'border-box',
    background: '#47546705',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomContent: {
    gap: '24px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '20px',
  },
  description: {
    alignSelf: 'baseline',
    fontWeight: '300',
    color: 'primary.greyMetal',
    fontSize: '14px',
    width: '90%',
    marginBottom: '12px',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.4em',
  },
  secondDescription: {
    fontWeight: '300',
    color: 'primary.greyMetal',
    fontSize: '14px',
  },
  button: {
    paddingLeft: '18px',
    paddingRight: '18px',
    minWidth: 'max-content !important',
  },
  icon: {
    fontSize: '27px',
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    padding: '11px',
    color: 'white',
    position: 'absolute',
    top: '-6px',
    right: 0,
  },
  timeChip: {
    marginTop: '2px',
    border: '1px solid',
    borderColor: '#2B18A299',
    backgroundColor: '#F3F1FF',
    fontWeight: '500',
    height: '20px',

    '.MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  textContentWithoutPicture: {
    height: '100%',
    gap: '12px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    // overflow: 'hidden'
  },
  textContentWithPicture: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    gap: '12px',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
  },
};
export default styles;
