import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { CANCEL, TITLE } from 'litterals';
import LoadingButton from 'ui/components/LoadingButton';
import Editor from 'ui/components/Editor';
import ViewModal from 'ui/components/shared/ViewModal';

export const AdminNote = ({ open, onClose, handleSubmit, control, editorName, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewModal
        open={open}
        customStyleBox={{
          maxWidth: '800px',
          height: 'max-content',
          borderRadius: '16px',
          padding: '40px',
        }}
        onClose={onClose}>
        <Typography
          variant="h1"
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            paddingBottom: '24px',
          }}>
          {title}
        </Typography>
        <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Editor title="Content" control={control} name={editorName} actions={() => {}} sx={{ minWidth: '300px' }} />
          </Box>
        </Box>
        <Box sx={{ paddingTop: '32px', justifyContent: 'right', display: 'flex', gap: '16px' }}>
          <Button variant="secondary" onClick={onClose}>
            {t(CANCEL)}
          </Button>
          <LoadingButton type="submit" onClick={handleSubmit}>
            {t('admin_note.save_button')}
          </LoadingButton>
        </Box>
      </ViewModal>
    </>
  );
};
