import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabNavigation from 'ui/pages/program/components/TabNavigationSettings';
import Button from '../Button/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModalSelectLangues from './components/ModalSelectLangues';
import DeleteModal from 'ui/pages/program/DeleteModal';
import { languagesAccepted } from 'utils/languages';
import styles from './TabLangues.styles';

const TabLangues = ({
  hiddenAddButton = false,
  hiddenDeleteButton = false,
  languageSelected,
  languagesEnabled = [],
  languesConfigurable = languagesAccepted,
  onAddLangueClick,
  onLangueClick,
  onDeleteLangue,
  sx = {},
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const generateTabsConfiguration = () => {
    const results = [];

    languagesEnabled.map((lng) => {
      results.push({ id: lng, text: t(`lang_${lng}`), selected: lng === languageSelected, disabled: false });
    });

    return results;
  };

  const tabsConfiguration = generateTabsConfiguration();

  const languesConfigurableRemaining = languesConfigurable.filter((lng) => !languagesEnabled.includes(lng));

  return (
    <>
      <Box sx={{ ...styles.container, ...sx }}>
        <Box sx={styles.tabAndAddButton}>
          {!!tabsConfiguration.length && (
            <TabNavigation
              key={languageSelected}
              customStyle={styles.tab}
              options={tabsConfiguration}
              onChange={(tab) => {
                onLangueClick(tab);
              }}
            />
          )}
          {!hiddenAddButton && (
            <Button
              disabled={languagesEnabled.length === languesConfigurable.length}
              greyWithHover
              variant="tertiary"
              onClick={() => setOpenModal(true)}>
              <AddIcon sx={{ marginRight: '4px' }} />
              {t('tab-langues.add')}
            </Button>
          )}
        </Box>

        {!hiddenDeleteButton && (
          <Box>
            <Button
              disabled={tabsConfiguration.length <= 1}
              variant="delete-tertiary"
              onClick={() => setOpenDeleteModal(true)}>
              <DeleteOutlineIcon sx={{ marginRight: '4px' }} />
              {t('tab-langues.delete')}
            </Button>
          </Box>
        )}
      </Box>

      <DeleteModal
        title={t('tab-langues.delete_modal.title')}
        description={t('tab-langues.delete_modal.desc', { languageSelected: t(`lang_${languageSelected}`) })}
        question={t('tab-langues.delete_modal.question')}
        acceptText={t('tab-langues.delete_modal.accept_button')}
        open={openDeleteModal}
        handelModal={() => {
          setOpenDeleteModal(false);
        }}
        onDelete={onDeleteLangue}
      />

      <ModalSelectLangues
        langues={languesConfigurableRemaining}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onAddLangueClick={onAddLangueClick}
      />
    </>
  );
};

export default TabLangues;
