import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListEmailsCompanyWithParams, useListEmailsWithParams } from 'hooks/queries';
import { addLanguagePropertiesInForm, deleteLanguagePropertiesInForm, setValueLanguageInForm } from 'utils/translate';

const useTemplateEmailsCompany = (companyId, queryParams) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingTemplateEmail, setEditingTemplateEmail] = useState(null);
  const [selectedTabCreate, setSelectedTabCreate] = useState('tab-1');
  const [optionsTabCreate, setOptionsTabCreate] = useState([
    { id: 'tab-1', text: 'Rich Text', selected: true },
    { id: 'tab-2', text: 'HTML Editor', selected: false },
  ]);
  const [selectedTabEdit, setSelectedTabEdit] = useState('tab-1');
  const [optionsTabEdit, setOptionsTabEdit] = useState([
    { id: 'tab-1', text: 'Rich Text', selected: true },
    { id: 'tab-2', text: 'HTML Editor', selected: false },
  ]);

  const translatedProperties = ['content', 'subject', 'variableContent'];

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      id: '',
      name: '',
      description: undefined,
      subject: '',
      from: '',
      fromName: '',
      content: '',
      variableContent: '',
      visible: false,
      scope: '',
      category: '',
      targetRoles: [],
    },
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: TemplateEmailList, isLoading } = useListEmailsCompanyWithParams(companyId, queryParams);

  const mutationDelete = useMutation(api.Emails.delete, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-emails']);
      setStateAlert({ open: true, message: 'TemplateEmail successfully deleted' });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onRemoveTemplateEmail = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.Emails.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-emails']);
      setStateAlert({ open: true, message: 'TemplateEmail successfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onAddTemplateEmail = handleSubmit(async (data) => {
    mutationAdd.mutate({ ...data, companyId: companyId });
  });

  const mutationDuplicate = useMutation(api.Emails.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-emails']);
      setStateAlert({ open: true, message: 'TemplateEmail successfully duplicated' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onDuplicateTemplateEmail = async (data) => {
    if (!data.description) {
      delete data.description;
    }
    if (!data.from) {
      delete data.from;
    }
    if (!data.variableContent) {
      delete data.variableContent;
    }

    mutationDuplicate.mutate({
      ...data,
      name: data.name + '-copy',
      companyId: companyId,
    });
  };

  const mutationEdit = useMutation(api.Emails.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-emails']);
      setStateAlert({ open: true, message: 'TemplateEmail successfully edited' });
      setEditingTemplateEmail(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  useEffect(() => {
    if (editingTemplateEmail) {
      setValue('id', editingTemplateEmail.id);
      setValue('name', editingTemplateEmail.name);
      setValue('description', editingTemplateEmail.description);
      setValue('subject', editingTemplateEmail.subject);
      setValue('from', editingTemplateEmail.from);
      setValue('fromName', editingTemplateEmail.fromName);
      setValue('content', editingTemplateEmail.content);
      setValue('variableContent', editingTemplateEmail.variableContent);
      setValue('visible', editingTemplateEmail.visible);
      setValue('scope', editingTemplateEmail.scope);
      setValue('category', editingTemplateEmail.category);
      setValue('targetRoles', editingTemplateEmail.targetRoles);
      setIsOpenEditModal(true);
    }
  }, [editingTemplateEmail]);

  const onEditTemplateEmail = handleSubmit(async (data) => {
    const { description, from, ...rest } = data;
    mutationEdit.mutate({
      id: editingTemplateEmail.id,
      data: { ...rest, active: rest.active, companyId: companyId },
    });
  });

  const submitEditMarketingPicture = async () => {
    const data = getValues();
    if (!!data.marketingPicture && typeof data.marketingPicture !== 'string') {
      const { url } = await api.Users.upload(data.marketingPicture);
      data.marketingPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('marketingPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.marketingPicture;
    }
  };

  const submitEditBackgroundPicture = async () => {
    const data = getValues();
    if (!!data.backgroundPicture && typeof data.backgroundPicture !== 'string') {
      const { url } = await api.Users.upload(data.backgroundPicture);
      data.backgroundPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('backgroundPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.backgroundPicture;
    }
  };

  const deleteLanguage = (language) => {
    deleteLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
  };

  const addLanguage = (language) => {
    if (!language) return;
    addLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
  };

  const setValueLanguage = (language, property, content) => {
    setValueLanguageInForm(language, property, content, watch, setValue);
  };

  return {
    isLoading: isLoading,
    TemplateEmails: TemplateEmailList && TemplateEmailList.rows ? TemplateEmailList.rows : [],
    totalPages: TemplateEmailList && TemplateEmailList.totalPages,
    totalItems: TemplateEmailList && TemplateEmailList.totalItems,
    control,
    watch,
    reset,
    getValues,
    onRemoveTemplateEmail,
    onAddTemplateEmail,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingTemplateEmail,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditTemplateEmail,
    editingTemplateEmail,
    submitEditMarketingPicture,
    submitEditBackgroundPicture,
    onDuplicateTemplateEmail,
    setSelectedTabCreate,
    selectedTabCreate,
    selectedTabEdit,
    setSelectedTabEdit,
    optionsTabCreate,
    setOptionsTabCreate,
    optionsTabEdit,
    setOptionsTabEdit,
    actions: { deleteLanguage, addLanguage, setValueLanguage },
  };
};

export default useTemplateEmailsCompany;
