import React, { useEffect } from 'react';
import { useMeQuery } from 'hooks/queries';
import Router from 'ui/router/Router';
import '@calcom/atoms/globals.min.css';
import { loadCompanyTranslations } from './translations/company-translations/translations';
import { ThemeProvider } from './hooks/ThemeContext';

function App() {
  const hasToken = !!localStorage.getItem('REACT_TOKEN_AUTH');
  const { data: user, isLoading } = useMeQuery({
    enabled: hasToken,
    retry: false,
  });

  useEffect(() => {
    localStorage.removeItem('FILTER_CHOICE_INTERNAL');
    loadCompanyTranslations(user?.company?.clientSpecificTranslation);
  }, [user?.company?.clientSpecificTranslation]);

  if (hasToken && isLoading) return <p>Try loading user...</p>;
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
