/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';

// Style
import styles from './styles';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellModernNumber({ text }) {
  /* ******************************** RENDERING ******************************* */
  return <Typography sx={styles.cellText}>{text}</Typography>;
}

export default CellModernNumber;
