import React from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import AvatarMui from '@mui/material/Avatar';

import styles from './Avatar.styles';

const Avatar = ({
  lastname,
  firstname,
  picture,
  tooltipPlacement = 'bottom',
  showName = false,
  width = 48,
  sx = {},
  sxName = {},
}) => {
  const userName = `${firstname} ${lastname}`;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textWrap: 'nowrap' }}>
      <Tooltip placement={tooltipPlacement} title={showName ? '' : userName}>
        {picture && firstname && lastname ? (
          <AvatarMui
            sx={{ ...styles.avatar, ...styles.avatarPicture, width, height: width, ...sx }}
            src={picture}
            alt={userName}
          />
        ) : (
          <AvatarMui
            sx={{
              ...styles.avatar,
              width,
              height: width,
              ...sx,
            }}>
            {`${firstname?.[0]} ${lastname?.[0]}`}
          </AvatarMui>
        )}
      </Tooltip>

      {showName && <Typography sx={{ marginLeft: '3px', ...sxName }}>{userName}</Typography>}
    </Box>
  );
};

export default Avatar;
