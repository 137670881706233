import React, { useEffect, useState, Suspense } from 'react';
import { useMeQuery } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import { useListEmailsCompanyWithParams } from 'hooks/queries';
import { Grid, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import DataGrid from 'ui/components/DataGrid';
import PreviewEmail from 'ui/pages/subscription/create/Modals/PreviewEmail';
import { parseJsonTranslate } from 'utils/parse';
import { isValidFilterConfig, filterObjects } from 'utils/mui-filter';
import { useNavigate } from 'react-router-dom';
import api from 'api';

import { tableSchemaBase as columns } from './EmailTemplate.config';

import styles from './EmailTemplate.styles';

const CATEGORIES_SORTED = ['registration', 'launching_pairing', 'sessions', 'account'];

const EmailTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categorySelected, setCategorySelected] = useState('');
  const { data: user } = useMeQuery();
  const { data, isLoading } = useListEmailsCompanyWithParams(user.company.id, {
    showAllTemplate: true,
    page: 1,
    size: 100,
  });
  const [currentSize, setCurrentSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [templateMailSelected, setTemplateMailSelected] = useState(false);
  const [dataEmailTemplateSelected, setDataEmailTemplateSelected] = useState({});
  const [sortOption, setSortOption] = useState({});
  const [filterOption, setFilterOption] = useState({});
  const [emailTemplatesFilteredAndEnriched, setEmailTemplatesFilteredAndEnriched] = useState([]);

  const emailTemplates = data?.rows || [];

  const categories = [...new Set(emailTemplates.map((template) => template.category))];
  const emailTemplatesFiltered = emailTemplates.filter(({ category }) => category === categorySelected);

  const { clientLabel, expertLabel, sessionLabel, customHeaderPicture, logo, colorConfig } = user.company;

  const updateList = (list) => {
    return list.map((line) => {
      const languages = line?.content && typeof line.content === 'object' ? Object.keys(line.content) : [];

      return {
        ...line,
        category: t(`email_template.category.${line.category}`),
        scope: t(`email_template.scope.${line.scope}`),
        languages: languages.length !== 0 ? languages.map((lng) => t(`lang_${lng}`)).join(', ') : '-',
        desc: t(`email_template.template_desc.${line.name}`, { expertLabel, clientLabel, sessionLabel }),
        subject: parseJsonTranslate(line.subject),
        targetRoles: line.targetRoles
          ? line.targetRoles
              .map((role) => t(`email_template.target_role.${role}`, { clientLabel, expertLabel }))
              .join(' / ')
          : '-',
      };
    });
  };

  useEffect(() => {
    let emailTemplatesFilteredCloned = JSON.parse(JSON.stringify(emailTemplatesFiltered));

    if (isValidFilterConfig(filterOption)) {
      emailTemplatesFilteredCloned = filterObjects(emailTemplatesFilteredCloned, filterOption);
    }

    let list = updateList(emailTemplatesFilteredCloned);

    if (sortOption.orderBy) {
      if (sortOption.orderBy === 'scope') {
        list.sort((a, b) => (a.scope || '').localeCompare(b.scope || ''));
      }

      if (sortOption.orderDirection === 'DESC') {
        list.reverse();
      }
    }

    setEmailTemplatesFilteredAndEnriched(list);
  }, [JSON.stringify(emailTemplatesFiltered), sortOption, filterOption]);

  const categoriesSorted = [
    ...CATEGORIES_SORTED.filter((x) => categories.includes(x)),
    ...categories.filter((x) => !CATEGORIES_SORTED.includes(x)),
  ];

  useEffect(() => {
    if (categoriesSorted?.length && !categorySelected) {
      setCategorySelected(categoriesSorted[0]);
    }
  }, [categoriesSorted]);

  useEffect(() => {
    setCurrentPage(1);
  }, [categorySelected]);

  if (isLoading) return <p>Loading...</p>;

  const handleOnFilter = (filters, currentPage, currentSize) => {
    setFilterOption(filters);
  };

  const handleOnSort = (sort) => {
    setSortOption(sort);
  };

  const handleOnPagination = (page, nbrPages) => {
    setCurrentPage(page);
    setCurrentSize(nbrPages);
  };

  const onEditEmailTemplate = async (row) => {
    // we check that the email has a company id, otherwise this means that we first have to duplicate the email for the company
    if (row.companyId) {
      navigate(`/customizaton/email-template/edit/${row.id}`);
    } else {
      const duplicateResult = await api.Emails.duplicate(row.id);

      if (duplicateResult.id) {
        navigate(`/customizaton/email-template/edit/${duplicateResult.id}`);
      }
    }
  };

  return (
    <>
      <Typography sx={styles.title}>{t('email_template.title')}</Typography>

      <Typography sx={styles.description}>{t('email_template.desc')}</Typography>

      <Grid container spacing={4} sx={{ marginTop: '12px' }}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Karla',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%', // 24px
              letterSpacing: '0.15px',
            }}>
            {t('email_template.list-title')}
          </Typography>
          <List sx={{ width: '100%', minWidth: '100%' }}>
            {categoriesSorted.map((category) => (
              <ListItemButton
                selected={category === categorySelected}
                key={category}
                disableGutters
                onClick={() => {
                  setCategorySelected(category);
                }}
                sx={{ padding: '15px', borderRadius: '5px', borderBottom: '1px solid rgb(237, 237, 237)' }}>
                <ListItemText primary={t(`email_template.category.${category}`)} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={9}>
          <Suspense fallback={<div>Loading...</div>}>
            <DataGrid
              columns={columns(t, clientLabel, expertLabel)}
              rows={emailTemplatesFilteredAndEnriched.slice((currentPage - 1) * currentSize, currentPage * currentSize)}
              onPaginationChange={handleOnPagination}
              loading={isLoading}
              totalItems={emailTemplatesFilteredAndEnriched.length}
              totalPages={Math.ceil(emailTemplatesFilteredAndEnriched.length / currentSize)}
              onSort={handleOnSort}
              onFilter={handleOnFilter}
              onEdit={onEditEmailTemplate}
              onRowClicked={(emailTemplate, event) => {
                if (!event.defaultMuiPrevented) {
                  setTemplateMailSelected(true);
                  setDataEmailTemplateSelected(emailTemplate?.row || {});
                  return;
                }
              }}
            />

            <PreviewEmail
              title={''}
              clientEmail={''}
              isOpen={!!templateMailSelected}
              setOpen={() => setTemplateMailSelected('')}
              templateName={dataEmailTemplateSelected?.name}
              content={dataEmailTemplateSelected?.content}
              variableContent={dataEmailTemplateSelected?.variableContent}
              fromName={dataEmailTemplateSelected ? dataEmailTemplateSelected.fromName : ''}
              subject={dataEmailTemplateSelected?.subject}
              customHeaderPicture={customHeaderPicture}
              companyLogo={logo}
              targetRoles={dataEmailTemplateSelected?.targetRoles}
              expertLabel={expertLabel}
              clientLabel={clientLabel}
              sessionLabel={sessionLabel}
              colorConfig={colorConfig}
            />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailTemplate;
