import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import TabNavigation from 'ui/pages/program/components/TabNavigation';

import { useParams } from 'react-router-dom';

import Experts from './components/Experts';
import Coachees from './components/Coachees';
import Admins from './components/Admins';
import Button from 'ui/components/Button/Button';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import AddUsersModal from 'ui/pages/users/components/AddUsersModal';

const Users = ({ company, user }) => {
  const { t } = useTranslation();
  // States
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [isOpenXlsx, setIsOpenXlsx] = useState(false);

  let { companyId } = useParams();
  // Hook

  const getOptionsTab = () => {
    return [
      { id: 'tab-1', text: 'EXPERTS', selected: true },
      { id: 'tab-2', text: 'COACHEES', selected: false },
      { id: 'tab-3', text: 'ADMIN', selected: false },
    ];
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <Experts companyId={companyId} user={user} />;
      case 'tab-2':
        return <Coachees companyId={companyId} />;
      case 'tab-3':
        return <Admins companyId={companyId} />;
      default:
        return null;
    }
  };

  const { clientLabel, expertLabel } = company;

  const getXlsxButtonText = () => {
    switch (selectedTab) {
      case 'tab-1':
        return `${t('add')} ${expertLabel}(s)`;
      case 'tab-2':
        return `${t('add')} ${clientLabel}(s)`;
      default:
        break;
    }
  };

  return (
    <Stack sx={{ minHeight: '630px', backgroundColor: 'white' }}>
      <Box sx={{ paddingLeft: '90px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TabNavigation
            options={getOptionsTab()}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
          />

          {selectedTab !== 'tab-3' && (
            <Button
              sx={{ paddingLeft: '24px', paddingRight: '24px' }}
              onClick={() => {
                setIsOpenXlsx(true);
              }}
              startIcon={<AddIcon />}>
              {getXlsxButtonText()}
            </Button>
          )}
        </Box>
        {renderComponent()}
      </Box>

      <AddUsersModal
        companyId={companyId}
        userType={selectedTab === 'tab-1' ? 'coach' : 'coachee'}
        expertLabel={expertLabel}
        clientLabel={clientLabel}
        open={isOpenXlsx}
        onClose={() => setIsOpenXlsx(false)}
        onValidate={() => {
          setIsOpenXlsx(false);
        }}
      />
    </Stack>
  );
};

export default Users;
