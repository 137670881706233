import { useEffect, useState } from 'react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMeQuery } from 'hooks/queries';

import useStore from 'store';
import { useListCoachesEvergreenWithParams } from 'hooks/queries';
import { removeEmptyFields } from 'common/utils';
import { languagesAccepted } from 'utils/languages';
import { getUrlEvergreenApplicationLink } from 'utils/company.js';
import { useEditProfile } from 'hooks/usecase/useProfile';
import { Roles } from 'utils/roles';
import { useNavigate } from 'react-router-dom';
import { loadCompanyTranslations } from 'translations/company-translations/translations';
import { useTheme } from '../ThemeContext';

const useEvergreen = () => {
  const { setPrimaryColor } = useTheme();
  const { company, setCompany, setCoachee, setActiveStep, activeStep } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const [isOpenModalProgramAlreadyExists, setIsOpenModalProgramAlreadyExists] = useState(false);
  const { setStateAlert, stateAlert } = useAlert();
  const { actions: userActions } = useEditProfile();
  const navigate = useNavigate();

  const { data: user } = useMeQuery({
    enabled: !!localStorage.getItem('REACT_TOKEN_AUTH'),
  });

  const isConnected = !!user;

  useEffect(() => {
    async function editRole() {
      if (isConnected && company.manualValidation && user.role !== Roles.COACHEE) {
        await userActions.changeRole(Roles.COACHEE);
      }
    }
    editRole();
  }, [isConnected, company.manualValidation]);

  useEffect(() => {
    if (company?.clientSpecificTranslation) {
      loadCompanyTranslations(company.clientSpecificTranslation);
    }
  }, [company?.clientSpecificTranslation]);

  useEffect(() => {
    if (company?.colorConfig?.primary) {
      setPrimaryColor(company.colorConfig.primary);
    }
  }, [company?.colorConfig?.primary]);

  // Query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const evergreenLink = queryParams.get('evergreenLink');

  const { i18n } = useTranslation();

  const language = i18n.language.split('-')[0];
  const defaultLanguage = languagesAccepted.includes(language) ? language : 'en';

  const { handleSubmit, control, setValue, watch, clearErrors, reset } = useForm({
    mode: 'all',
    defaultValues: {
      /** Profile Info */
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      linkedinUrl: '',
      idNumber: '',
      idNumber_secondary: '',

      /** Picture */
      picture: '',

      /** Matching info */
      city: '',
      scopeId: '',
      seniorityLevelId: '',
      internalExternal: '',
      gender: '',
      languages: [],
      departmentId: '',
      leaderEmail: '',
      /** {{clientLabel}} Profile Info */
      targetList: '',

      /** Expectations */
      expectations: '',

      platformContentLanguage: defaultLanguage,
    },
  });

  const mutation = useMutation(api.Guests.createEvergreen, {
    onSuccess: () => {
      setTimeout(() => {
        setIsLoadingApply(false);
        setActiveStep(3);
      }, 2000);
    },
    onError: (error) => {
      setTimeout(() => {
        setIsLoadingApply(false);
      }, 2000);

      if (error.response.data.code === 20006) {
        setIsOpenModalProgramAlreadyExists(true);
      } else {
        setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      }
    },
  });

  const handleCreateEvergreen = (body) => {
    setIsLoadingApply(true);

    if (body && body.leaderEmail == '') {
      delete body.leaderEmail;
    }

    mutation.mutate({ id: evergreenLink, body: body });
  };

  const handleSubmitCoachee = handleSubmit((data) => {
    data = removeEmptyFields(data);
    setCoachee({ ...data });
    setActiveStep(2);
  });

  const handleGoBack = () => {
    setActiveStep(activeStep - 1);
  };

  const mutateGetCompany = useMutation(api.Application.getCompany, {
    onSuccess: (data) => {
      setCompany(data);
      setIsLoading(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  useEffect(() => {
    setIsLoading(true);
    mutateGetCompany.mutate(evergreenLink);
  }, []);

  const onUploadPicture = async (data) => {
    const pictureData = watch('picture');

    if (!!pictureData && typeof pictureData !== 'string') {
      const { url } = await api.Users.uploadpublic(pictureData);
      setValue('picture', process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
    }
  };

  const onClickApplicationEvergreen = (themeId) => {
    navigate(getUrlEvergreenApplicationLink(evergreenLink, themeId, company.applicationLink));
  };

  const updateImage = () => {};

  return {
    control,
    evergreenLink,
    state: {
      stateAlert,
      isLoading,
      isLoadingApply,
      setStateAlert,
      form: {
        clearErrors,
        reset,
        setValue,
      },
      isOpenModalProgramAlreadyExists,
      isConnected,
      user,
    },
    actions: {
      onClickApplicationEvergreen,
      handleSubmitCoachee,
      handleGoBack,
      onUploadPicture,
      setStateAlert,
      updateImage,
      watch,
      handleCreateEvergreen,
      setIsOpenModalProgramAlreadyExists,
    },
  };
};

const useSelectProgram = (companyId, evergreenLink) => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      hasTerms: '',
      linkTerms: '',
      documentationCollaborator: '',
      documentationExpert: '',
      feedbackLink: '',
    },
  });
  const queryClient = useQueryClient();

  // react-query get programs
  const { data, isLoading } = useQuery(['list-themes', companyId], () => api.Guests.getThemesEvergreen(evergreenLink));

  return {
    control,
    themes: data,
    isLoading,
  };
};

const useChooseExpert = (evergreenLink, queryParams) => {
  const { coachee } = useStore();

  const { control } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      hasTerms: '',
      linkTerms: '',
      documentationCollaborator: '',
      documentationExpert: '',
      feedbackLink: '',
    },
  });

  const { data, isLoading, isFetched } = useListCoachesEvergreenWithParams(evergreenLink, queryParams, {
    coacheeLanguages: coachee && coachee.languages ? coachee.languages : [],
    coacheeSkills: coachee && coachee.targetList ? coachee.targetList : [],
    coacheeScope: coachee && coachee.languages ? coachee.scopeId : [],
  });

  return {
    control,
    coaches: data && data.rows ? data.rows : data,
    totalPages: data && data.totalPages ? data.totalPages : 1,
    currentPage: data && data.currentPage ? data.currentPage : 1,
    totalItems: data && data.totalItems ? data.totalItems : 0,
    isLoadingCoaches: isLoading,
    isFetched,
  };
};

export { useEvergreen, useSelectProgram, useChooseExpert };
