/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import parse from 'html-react-parser';

import { truncateString } from 'common/utils';

// Style
import styles from './styles';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellUser({ user }) {
  /* ********************************** HOOKS ********************************* */

  const { picture, name, title, adminNote } = user;

  const tooltipTitle = () => {
    if (adminNote) {
      return parse(adminNote);
    }

    return title && title.length > 30 ? title : '';
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <Tooltip title={tooltipTitle()}>
      <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
        <img
          data-tag="allowRowEvents"
          alt="Remy Sharp"
          src={
            picture
              ? picture
              : 'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
          }
          style={styles.picture}
        />
        <Stack sx={{ paddingLeft: '10px' }}>
          <Typography sx={styles.title} data-tag="allowRowEvents">
            {truncateString(`${name}`, 30)}
          </Typography>
          <Stack sx={{ m: '2px' }} />
          <Typography sx={styles.description} data-tag="allowRowEvents">
            {typeof title === 'string' ? truncateString(`${title}`, 30) : ''}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
}

export default CellUser;
