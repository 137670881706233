import { useEffect, useState } from 'react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMeQuery } from 'hooks/queries';
import { getUrlSelfRegistrationApplicationLink } from 'utils/company.js';
import { loadCompanyTranslations } from 'translations/company-translations/translations';
import { useTheme } from '../ThemeContext';

import useStore from 'store';

const useSelfRegistration = () => {
  const { setPrimaryColor } = useTheme();
  let { selfRegistration, applicationLink, themeId } = useParams();
  const { setCompany, company, setCoachee, setActiveStep, activeStep } = useStore();
  const { setStateAlert, stateAlert } = useAlert();
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isLoadingCoaches, setIsLoadingCoaches] = useState(false);
  const [theme, setTheme] = useState({});
  const [coaches, setCoaches] = useState([]);

  const { data: user } = useMeQuery({
    enabled: !!localStorage.getItem('REACT_TOKEN_AUTH'),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (company?.clientSpecificTranslation) {
      loadCompanyTranslations(company.clientSpecificTranslation);
    }
  }, [company?.clientSpecificTranslation]);

  useEffect(() => {
    if (company?.colorConfig?.primary) {
      setPrimaryColor(company.colorConfig.primary);
    }
  }, [company?.colorConfig?.primary]);

  useEffect(() => {
    setIsLoadingCompany(true);
    mutateGetCompany.mutate(applicationLink);
    mutateGetTheme.mutate({ selfRegistration, themeId });
    mutateGetCoaches.mutate({ selfRegistration });
  }, [user]);

  const mutateGetCompany = useMutation(api.Application.getCompany, {
    onSuccess: (data) => {
      setCompany(data);
      setIsLoadingCompany(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const mutateGetTheme = useMutation(api.Guests.getSelfRegistrationTheme, {
    onSuccess: (data) => {
      setTheme(data);
      setIsLoadingTheme(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const mutateGetCoaches = useMutation(api.Guests.getSelfRegistrationCoaches, {
    onSuccess: (data) => {
      setCoaches(data?.rows || []);
      setIsLoadingCoaches(false);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onClickApplicationLinkSelfRegistration = () => {
    navigate(getUrlSelfRegistrationApplicationLink(selfRegistration, applicationLink, themeId));
  };

  return {
    state: {
      selfRegistrationLink: selfRegistration,
      coaches,
      isLoading: isLoadingTheme && isLoadingCompany && isLoadingCoaches,
      isConnected: !!user,
      theme,
      user,
    },
    actions: {
      onClickApplicationLinkSelfRegistration,
    },
  };
};

export { useSelfRegistration };
