import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';

import styles from './EmptyStateCard.styles';

const EmptyStateCard = ({ children, description, src, subtitle, title, isLoading, height, hiddenCard = false }) => {
  const ComponentWrapper = hiddenCard ? Box : DashboardCard;

  return (
    <ComponentWrapper>
      <Box sx={{ ...styles.emptyStateContainer, height }}>
        {title && <Typography sx={styles.emptyStateTitle}>{title}</Typography>}

        {!isLoading && (
          <Box sx={styles.emptyStateContent}>
            {src && <Box sx={styles.emptyStateImage} component="img" src={src} />}

            {subtitle && <Typography sx={styles.emptyStateSubtitle}>{subtitle}</Typography>}

            {description && <Typography sx={styles.emptyStateDescription}>{description}</Typography>}

            {children}
          </Box>
        )}

        {isLoading && (
          <Box sx={styles.emptyStateContent}>
            <CircularProgress size="50px" sx={{ color: 'primary.main' }} />
          </Box>
        )}
      </Box>
    </ComponentWrapper>
  );
};

export default EmptyStateCard;
