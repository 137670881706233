const styles = (theme) => ({
  container: {
    padding: '12px',
    borderBottom: '1px solid',
    borderColor: 'dashboard.borderSecondary',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}12`,
      cursor: 'pointer',
    },
  },
  containerActionNeeded: {
    background: '#6C63FF08',
  },
  contentName: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentUserClickable: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    padding: '4px',
    borderRadius: '6px',

    ':hover': {
      background: `${theme.palette.primary.main}0D`,
    },
  },
  userName: {
    fontSize: '14px',
    fontWeight: 500,
  },
  mentoringTitle: {
    fontSize: '12px',
    color: 'secondary.grey',
  },
  redirectionIcon: {
    fontSize: '16px',
    color: 'secondary.grey',
    padding: '6px',
  },
  contentStatus: {
    flex: '0.75',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
