import React, { memo, useState } from 'react';
import { Alert, Grid, Paper, Skeleton, Typography, Stack, Box as NativeBox, Button, useTheme } from '@mui/material';
import useDashboard from 'hooks/usecase/useDashboard';
import Box from 'ui/components/system/Box';
import { useTranslation } from 'react-i18next';
import { COACH, COACHEE, DATE, RESET, SCOPE, TOPIC } from 'litterals';
import StaticCard from './StaticCard';
import GroupStaticCard from './GroupStaticCard';
import MultiSelectTheme from './components/MultiSelectTheme/MultiSelectTheme';
import DatePicker from './components/Datepicker/Datepicker';
import MultiSelectScope from './components/MultiSelectScope/MultiSelectScope';
import dayjs from 'dayjs';
// Style
import styles from './styles';
import { useWatch } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const maxDate = new Date();

const SessionList = memo(({ title, cardList }) => {
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: '48px' }}>
      <Typography sx={theme.fontHeader}>{title}</Typography>
      <Box variant="flex" sx={{ marginTop: '12px', gap: '20px 30px', flexWrap: 'wrap' }}>
        {cardList.map((item, idx) => (
          <StaticCard
            Icon={item.Icon}
            src={item.src}
            label={item.title}
            ratio={item.ratio}
            staticNumber={item.nbr}
            key={idx}
            id={idx}
          />
        ))}
      </Box>
    </Box>
  );
});

const DashboardPage = () => {
  const {
    state: {
      form,
      query: { isLoading },
      nav: {
        coacheeList,
        coachList,
        objectiveList,
        sessionList,
        pairingList,
        clientLabel,
        expertLabel,
        scopeOption,
        listTheme,
      },
    },
    actions: { resetFilter },
  } = useDashboard();

  const { t } = useTranslation();

  const endDate = useWatch({ name: 'to', control: form.control });

  return (
    <Paper
      elevation={3}
      sx={{
        m: '24px',
        p: '24px',
        pb: '24px',
        position: 'relative',
        overflowY: 'scroll',
        height: '83vh',
      }}>
      <Alert style={styles.text_explanation_reporting} severity="info">
        {t('reporting_explanation')}
      </Alert>
      <NativeBox
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
          alignItems: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'center',
            xl: 'center',
          },
        }}>
        <Button
          endIcon={<RestartAltIcon />}
          sx={styles.resetButtonBashboard}
          variant="outlined"
          onClick={() => {
            resetFilter();
          }}
          size="small">
          {t(RESET)}
        </Button>
        <NativeBox
          sx={{
            width: '15px',
          }}
        />
        <DatePicker
          defaultValue={new Date().toISOString()}
          label={t('date_from')}
          control={form.control}
          name={'from'}
          maxDate={dayjs.utc(endDate)}
        />
        <NativeBox
          sx={{
            width: '15px',
          }}
        />
        <DatePicker
          defaultValue={dayjs('2021-01-01')}
          label={t('date_to')}
          control={form.control}
          name={'to'}
          value={dayjs.utc('2021-01-01')}
          minDate={dayjs.utc('2021-01-01')}
        />
        <NativeBox
          sx={{
            width: '15px',
            height: {
              xs: '10px',
              sm: '10px',
              md: '10px',
              lg: '10px',
              xl: '10px',
            },
          }}
        />
        <MultiSelectTheme label={t('theme')} name="theme" control={form.control} />
        <NativeBox
          sx={{
            width: '15px',
            height: {
              xs: '10px',
              sm: '10px',
              md: '10px',
              lg: '10px',
              xl: '10px',
            },
          }}
        />
        <MultiSelectScope
          hasCoachees
          label={t('scope') + ' ' + clientLabel}
          name="coacheescope"
          control={form.control}
        />
        <NativeBox
          sx={{
            width: '15px',
            height: {
              xs: '10px',
              sm: '10px',
              md: '10px',
              lg: '10px',
              xl: '10px',
            },
          }}
        />
        <MultiSelectScope hasCoaches label={t('scope') + ' ' + expertLabel} name="coachscope" control={form.control} />
      </NativeBox>

      {isLoading ? (
        <Box variant="flex" sx={{ marginTop: '36px', gap: '30px 30px', flexWrap: 'wrap' }}>
          {pairingList.map((elm, idx) => (
            <Skeleton variant="rounded" width={'175px'} height={'173px'} key={idx} />
          ))}
        </Box>
      ) : (
        <>
          <SessionList title={t('pairing_report')} cardList={pairingList} key="Pairing-Report" />
          <SessionList title={t('session_report')} cardList={sessionList} key="Sessions-Report" />
          <Box variant="flex" sx={{ marginTop: '36px', gap: '30px 30px', flexWrap: 'wrap' }}>
            <GroupStaticCard title={`Super ${clientLabel || 'Mentees'}`} list={coacheeList} color="#FFA900" />
            <GroupStaticCard title={`Super ${expertLabel || 'Mentors'}`} list={coachList} />
          </Box>
          <SessionList title={t('objectives_report')} cardList={objectiveList} key="Objectives-Report" />
        </>
      )}
    </Paper>
  );
};

export default DashboardPage;
