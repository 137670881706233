import emailStyles from 'ui/pages/subscription/create/Modals/PreviewEmail.styles';
const { contentEmail } = emailStyles;

const styles = (theme) => ({
  container: {
    alignSelf: 'center',
    overflowY: 'auto',
    p: '0 24px 24px 24px',
    marginX: '24px',
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    margin: '0 auto',
  },

  headerActions: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  card: {
    marginTop: '12px',
    padding: '24px',
    borderRadius: '8px',
    backgroundColor: 'rgb(253, 253, 253)',
    border: '1px solid',
    borderColor: 'primary.border',
    boxShadow: 'none',
    height: 'max-content',
    position: 'relative',
  },

  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
    marginBottom: '12px',
  },

  subtitle: {
    color: 'primary.grey',
    fontSize: '16px',
    maxWidth: '728px',
  },

  titleInput: {
    fontSize: '32px',
    fontWeight: 500,
    color: '#475467',
  },

  label: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'primary.grey',
    marginBottom: '0px!important',
  },

  subTitleInput: {
    marginTop: '16px',
    width: '100%',
    '.MuiFormLabel-root': { zIndex: 0 },
  },

  editorContainer: {
    padding: '10px 21px 0 21px',
    border: '1px solid',
    borderColor: 'primary.border',
    borderRadius: '4px',
  },

  updatedAt: {
    color: 'primary.grey',
    position: 'absolute',
    top: '6px',
    right: '12px',
    fontSize: '12px',
  },

  content: {
    display: 'flex',
    gap: '18px',
    marginTop: '12px',
  },

  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    flex: 1,
    maxWidth: '50%',
  },

  mailRender: {
    flex: 1,
  },

  contentEmail,

  variableSelectorContainer: {
    gap: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: `${theme.palette.primary.greyMetal}0F`,
    padding: '8px 16px',
    borderRadius: '4px',
  },

  variableTitle: {
    fontWeight: 600,
    fontSize: '16px',
  },

  variableSelector: {
    minWidth: '220px',
  },
});

export default styles;
