const createValidation = (additionalParams) => {
  const commonRules = {
    required: 'This field is required',
  };
  let linkedinUrl = {
    pattern: {
      value: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
      message: 'Please provide a valid LinkedIn URL',
    },
  };
  if (additionalParams['linkedinURL']) {
    linkedinUrl = { ...commonRules, ...linkedinUrl };
  }
  let calendarLink = {
    pattern: {
      value: /^(ftp|http|https):\/\/[^ "]+$/,
      message: 'Please provide a valid URL',
    },
  };
  if (additionalParams['calendarURL']) {
    calendarLink = { ...commonRules, ...calendarLink };
  }
  let videoLink = {
    pattern: {
      value: /^(ftp|http|https):\/\/[^ "]+$/,
      message: 'Please provide a valid URL',
    },
  };
  let leaderEmail = {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Please provide a valid email address',
    },
  };
  if (additionalParams['videoURL']) {
    videoLink = { ...commonRules, ...videoLink };
  }
  if (additionalParams['managerEmail']) {
    leaderEmail = { ...commonRules, ...leaderEmail };
  }

  let idNumberSecondary = {};

  if (additionalParams['idNumber_secondary']) {
    idNumberSecondary = { ...commonRules };
  }

  const validation = {
    firstname: additionalParams['firstname'] ? commonRules : {},
    lastname: additionalParams['lastname'] ? commonRules : {},
    email: {
      required: 'This field is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please provide a valid email address',
      },
    },
    phoneNumber: additionalParams['phoneNumber'] ? commonRules : {},
    linkedinUrl,
    idNumber: additionalParams['employeeID'] ? commonRules : {},
    location: additionalParams['currentLocation'] ? commonRules : {},
    scopeId: additionalParams['sector'] ? commonRules : {},
    seniorityLevel: additionalParams['seniorityLevel'] ? commonRules : {},
    gender: additionalParams['genderIdentity'] ? commonRules : {},
    languages: additionalParams['spokenLanguages'] ? commonRules : {},
    department: additionalParams['department'] ? commonRules : {},
    isMentor: {},
    isInternal: additionalParams['internal'] ? commonRules : {},
    maxCapacity: additionalParams['maxCapacity'] ? commonRules : {},
    expertise: {},
    coachingSkills: additionalParams['coachingSkills'] ? commonRules : {},
    expectations1: additionalParams['expectations'] ? commonRules : {},
    expectations2: additionalParams['expectations'] ? commonRules : {},
    hoursOfExperience: additionalParams['hoursOfExperience'] ? commonRules : {},
    title: additionalParams['title'] ? commonRules : {},
    picture: additionalParams['picture'] ? commonRules : {},
    descriptionExperience: {},
    targetList: additionalParams['objectives_expertises'] ? commonRules : {},
    timeZone: additionalParams['timeZone'] ? commonRules : {},
    platformContentLanguage: additionalParams['platformContentLanguage'] ? commonRules : {},
    videoLink,
    calendarLink,
    leaderEmail,
    idNumberSecondary,
  };

  return validation;
};

export default createValidation;
