import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip } from '@mui/material';

const statusColor = {
  'waiting-coach-reply': '#B18225',
  'coaching-accepted': '#45893F',
  failed: '#D32F2FB3',
};

const bgColor = {
  'waiting-coach-reply': '#FEBA3624',
  'coaching-accepted': '#53A24C29',
  failed: '#FED9D199',
};

export const statusConsidered = Object.keys(statusColor);

export const StatusEvergreenChip = ({ status = '', style = {} }) => {
  const { t, i18n } = useTranslation();

  const i18nStatusKey = `program_evergreen_status_${status.replaceAll('-', '_').toLowerCase()}`;
  const i18nTooltipKey = `program_evergreen_tooltip_${status.replaceAll('-', '_').toLowerCase()}`;
  const title = i18n.exists(i18nTooltipKey) ? t(i18nTooltipKey) : '';

  return (
    <Tooltip title={title}>
      <Chip
        label={t(i18nStatusKey)}
        style={{ backgroundColor: bgColor[status], color: statusColor[status], minWidth: '70px', ...style }}
      />
    </Tooltip>
  );
};
