const tableSchemaBase = [
  {
    field: 'name',
    headerName: 'themes_card.table.header.name',
    type: 'smallModernText',
    minWidth: 220,
    fontSize: '2px',
    flex: 1,
  },
  {
    field: 'nbSessions',
    headerName: 'themes_card.table.header.sessions_count',
    type: 'modernNumber',
    minWidth: 130,
    resizable: false,
    flex: 0.7,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'active',
    headerName: 'themes_card.table.header.status',
    type: 'themeStatus',
    minWidth: 130,
    flex: 0.7,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'programsCount',
    headerName: 'themes_card.table.header.program_count',
    type: 'modernNumber',
    minWidth: 130,
    flex: 0.7,
    headerAlign: 'center',
    align: 'center',
  },
].map((line) => {
  return {
    ...line,
    resizable: false,
    disableColumnMenu: true,
    disableColumnResize: true,
    disableColumnSorting: true,
    disableColumnPinning: true,
    disableChildrenSorting: true,
    disableMultipleColumnsFiltering: true,
    disableMultipleColumnsSorting: true,
    sortable: false,
  };
});

export { tableSchemaBase };
