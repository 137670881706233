import React, { useRef, useState, useEffect } from 'react';
import GoBackButton from 'ui/components/shared/GoBackButton';
import Button from 'ui/components/Button/Button';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import TabLangues from 'ui/components/TabLangues/TabLangues';
import WarningEditLanguageModal from 'ui/components/WarningEditLanguageModal/WarningEditLanguageModal';
import { useForm } from 'react-hook-form';
import Input from 'ui/components/Input';
import MultiSelect from 'ui/components/shared/MultiSelect';
import { getEmailTemplate } from 'utils/templateEmailUtils';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useEditEmailTemplate from 'hooks/usecase/useEditEmailTemplate';
import { getContentTranslate } from 'utils/parse';

import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';
import { formatDateWithSecondes } from 'utils/dateUtils';
import { useMeQuery, useEmailStructure } from 'hooks/queries';
import { parseJsonTranslate } from 'utils/parse';
import { getTemplateEmailVariableAuthorized } from 'utils/templateEmailVariableAuthorized';

import styles from './EditEmailTemplatePage.styles';

export default function EditEmailTemplatePage() {
  const theme = useTheme();
  const themedStyles = styles(theme);
  const { setStateAlert } = useAlert();
  const { emailTemplateId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state: emailTemplateState, actions: emailTemplateActions } = useEditEmailTemplate(emailTemplateId);
  const [languageSelected, setLanguageSelected] = useState('');
  const [variableSelected, setVariableSelected] = useState('');
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const emailTemplateIdRef = useRef(emailTemplateId);
  const refTargetRoles = useRef();
  const { data: user } = useMeQuery();
  const { data: TemplateEmailList, isLoading: isLoadingFooter } = useEmailStructure();

  const {
    control: internalControl,
    setValue,
    watch,
  } = useForm({ mode: 'all', defaultValues: { subject: '', variableContent: '' } });

  const emailTemplate = emailTemplateState.query.data;
  const isLoading = emailTemplateState.query.isLoading;

  useEffect(() => {
    if (emailTemplateIdRef && emailTemplate?.id && emailTemplateIdRef.current !== emailTemplate.id) {
      window.location.reload();
    }

    if (emailTemplate && typeof emailTemplate.content === 'object' && emailTemplate.content !== null) {
      const languages = Object.keys(emailTemplate.content);
      setLanguagesConfigured(languages);

      if (languages.length && !languageSelected) setLanguage(languages[0]);
    } else {
      setLanguagesConfigured([]);
      setLanguage('', false);
    }
  }, [emailTemplate]);

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    emailTemplateActions.addLanguage(lng);
    setLanguage(lng, false);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    emailTemplateActions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const saveInControl = () => {
    emailTemplateActions.setValueLanguage(languageSelected, 'subject', watch('subject'));
    emailTemplateActions.setValueLanguage(languageSelected, 'variableContent', watch('variableContent'));
  };

  const setLanguage = (language, sendContent = true) => {
    if (languageSelected && sendContent) {
      saveInControl();
    }

    setLanguageSelected(language);

    const currentSubject = language ? getContentTranslate(emailTemplate?.subject, language) : '';
    const currentVariableContent = language ? getContentTranslate(emailTemplate?.variableContent, language) : '';

    setValue('subject', currentSubject, { shouldDirty: true, shouldValidate: true });
    setValue('variableContent', currentVariableContent, { shouldDirty: true, shouldValidate: true });
  };

  const onClickSaveEmail = () => {
    saveInControl();

    if (languagesConfigured.length > 1 && languagesConfigured.indexOf(languageSelected) === 0) {
      setIsOpenWarningModal(true);
    }
  };

  const onChangeContent = (value) => {
    setValue('variableContent', value, { shouldDirty: true, shouldValidate: true });
  };

  const { clientLabel, expertLabel, sessionLabel, customHeaderPicture, logo, colorConfig } = user.company;

  const targetRolesOptions = Object.keys(t('email_template.target_role')).map((value) => {
    return { label: t(`email_template.target_role.${value}`, { clientLabel, expertLabel }), value };
  });

  const onChangeVariable = (event) => {
    const mailVariable = event.target.value;
    setVariableSelected(mailVariable);
    clipboardCopy(`$$${mailVariable}`);
    setStateAlert({ open: true, message: t('email_template_editor.variables.copied_variable') });
  };

  if (isLoading || isLoadingFooter) return <CircularProgress size="1rem" color="secondary" />;

  const footer = parseJsonTranslate(TemplateEmailList.footer, false, languageSelected);

  const emailHtml = `${watch('variableContent')?.html || ''} ${getContentTranslate(
    emailTemplate.content,
    languageSelected
  )}`.trim();

  const variablesAuthorized = getTemplateEmailVariableAuthorized(emailTemplate.name);

  return (
    <Box sx={themedStyles.container}>
      <Box sx={themedStyles.headerActions}>
        <GoBackButton
          text="email_template_editor.back"
          onClick={() => {
            navigate('/customizaton#email-templates');
          }}
        />
      </Box>

      <Box sx={themedStyles.titleContainer}>
        <Box>
          <Typography sx={themedStyles.subtitle} component="p">
            {t('email_template_editor.title')}
          </Typography>

          <Typography sx={themedStyles.title} component="h1">
            {t(`email_template.template_desc.${emailTemplate?.name}`, { expertLabel, clientLabel, sessionLabel })}
          </Typography>
        </Box>

        <Button onClick={onClickSaveEmail}>{t('email_template_editor.save_button')}</Button>
      </Box>

      <Box>
        <TabLangues
          sx={{ 'button.MuiButtonBase-root': { background: 'inherit' } }}
          hiddenAddButton
          hiddenDeleteButton
          languageSelected={languageSelected}
          languagesEnabled={languagesConfigured}
          onAddLangueClick={onAddLangueClick}
          onDeleteLangue={onDeleteLangue}
          onLangueClick={onLangueClick}
        />

        <Paper sx={themedStyles.card}>
          {emailTemplate?.updatedAt && (
            <Box sx={themedStyles.updatedAt}>
              {t('last_save', { date: formatDateWithSecondes(emailTemplate.updatedAt) })}
            </Box>
          )}

          {languageSelected && (
            <Box sx={themedStyles.content}>
              <Box sx={themedStyles.inputsContainer}>
                <Input
                  ref={null}
                  rules={{ required: 'This field is required' }}
                  control={emailTemplateState.form.control}
                  variant="outlined"
                  label={t('email_from')}
                  name="fromName"
                />

                <MultiSelect
                  label={t('email_template.target_roles_label')}
                  ref={refTargetRoles}
                  disabled
                  name="targetRoles"
                  variant="outlined"
                  control={emailTemplateState.form.control}
                  options={targetRolesOptions}
                />

                <Input
                  ref={null}
                  rules={{ required: 'This field is required' }}
                  control={internalControl}
                  disabled={!languageSelected}
                  variant="outlined"
                  label="Email subject"
                  name="subject"
                />

                {!!variablesAuthorized?.length && (
                  <Box sx={themedStyles.variableSelectorContainer}>
                    <Typography sx={themedStyles.variableTitle}>
                      {t('email_template_editor.variables.title')}
                    </Typography>
                    <FormControl sx={themedStyles.variableSelector}>
                      <InputLabel id="select-variable">{t('email_template_editor.variables.label')}</InputLabel>
                      <Select
                        labelId="select-variable"
                        value={variableSelected}
                        label={t('email_template_editor.variables.label')}
                        onChange={onChangeVariable}>
                        {variablesAuthorized.map((key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {t(`email_template_editor.variables.values.${key}`, { expertLabel, clientLabel })}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                <Typography sx={themedStyles.label} component="p">
                  {t('email_template.contentEditable_label')}
                </Typography>
                <Box sx={themedStyles.editorContainer}>
                  {languageSelected && (
                    <YooptaEditor
                      key={`${languageSelected}_${emailTemplate.id}`}
                      sendHtml
                      disablePlugins={['accordion']}
                      folderUpload={`companies/${user?.companyId}/email-template/${emailTemplate?.id}/uploads/`}
                      style={{ width: '100%' }}
                      value={watch('variableContent')?.yoopta || {}}
                      onChange={onChangeContent}
                    />
                  )}
                </Box>
              </Box>

              <Box sx={themedStyles.mailRender}>
                <Box sx={themedStyles.contentEmail}>
                  <div
                    className="email-template-scope"
                    dangerouslySetInnerHTML={{
                      __html: getEmailTemplate(emailHtml, footer, customHeaderPicture, logo, colorConfig?.primary),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>

      <WarningEditLanguageModal
        languages={languagesConfigured}
        open={isOpenWarningModal}
        onClose={() => setIsOpenWarningModal(false)}
      />
    </Box>
  );
}
