import React from 'react';
import styles from './DashboardCard.styles';
import { Box, useTheme } from '@mui/material';

const DashboardCard = ({ children, isShowBoxShadow = false, isActionNeeded = false, sx = {} }) => {
  const theme = useTheme();
  const themedStyles = styles(theme);

  let containerStyle = !isActionNeeded
    ? { ...themedStyles.container, ...sx }
    : { ...themedStyles.container, ...themedStyles.actionNeededContainer, ...sx };

  if (isShowBoxShadow) {
    containerStyle = { ...containerStyle, ...themedStyles.boxShadowContainer };
  }

  return <Box sx={containerStyle}>{children}</Box>;
};

export default DashboardCard;
