import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useMeQuery } from './queries';

const ThemeContext = createContext();

const darkenColor = (hex, percent) => {
  let r = parseInt(hex.substring(1, 3), 16) * percent;
  let g = parseInt(hex.substring(3, 5), 16) * percent;
  let b = parseInt(hex.substring(5, 7), 16) * percent;

  r = Math.max(0, Math.min(255, Math.round(r)));
  g = Math.max(0, Math.min(255, Math.round(g)));
  b = Math.max(0, Math.min(255, Math.round(b)));

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const ThemeProvider = ({ children }) => {
  const hasToken = !!localStorage.getItem('REACT_TOKEN_AUTH');
  const { data: user } = useMeQuery({
    enabled: hasToken,
    retry: false,
  });

  const getTheme = (primaryColor) => {
    return createTheme({
      palette: {
        primary: {
          light: '#4339cb',
          main: primaryColor,
          dark: darkenColor(primaryColor, 0.85),
          contrastText: '#fff',
          border: '#D9D9D9',
          success: '#00C15A',
          danger: '#D32F2F',
          warning: '#FEBA36',
          grey: '#757575',
          greyMetal: '#475467',
          black: '#29282D',
        },
        secondary: {
          light: '#fec75e',
          main: '#feba36',
          dark: '#b18225',
          contrastText: '#fff',
          grey: '#667085',
        },
        ternary: {
          light: '#394677',
          main: '#081856',
          dark: '#05103c',
          contrastText: '#fff',
        },
        title: {
          main: '#000',
        },
        subtitle: {
          main: '#686868',
        },
        background: {
          paper: '#ffff',
          default: '#ffff',
          secondary: '#FFF6F1',
          disabled: '#f9f9fa',
        },
        card: {
          selected: `${primaryColor}14`,
          hover: `${primaryColor}0A`,
        },
        dashboard: {
          border: '#D0D5DD',
          borderSecondary: '#E5F0FC',
        },
      },
      typography: {
        fontFamily: ['karla'].join(','),
        body1: { fontSize: '14px' },
      },
      fontHeader: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'karla',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '29.64px',
        letterSpacing: '0.25px',
        fontFeatureSettings: " 'clig' off, 'liga' off",
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: primaryColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: primaryColor,
                },
              },
            },
          },
        },
      },
    });
  };

  const [primaryColor, setPrimaryColor] = useState(user?.company?.colorConfig?.primary || '#5236FF');
  const theme = useMemo(() => getTheme(primaryColor), [primaryColor]);

  useEffect(() => {
    if (user?.company?.colorConfig?.primary) {
      setPrimaryColor(user.company.colorConfig.primary);
    }
  }, [user]);

  return (
    <ThemeContext.Provider value={{ theme, setPrimaryColor }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
