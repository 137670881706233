import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useListFormatThemeQuery } from 'hooks/queries';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import { Box, Typography, useTheme } from '@mui/material';
import styles from './SharingLinksCard.styles';
import SharingLinkBlock from './components/SharingLinkBlock';
import { getUrlApplicationLink, getUrlSelfRegistrationLink, getUrlEvergreenLink } from 'utils/company';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';

const SharingLinksCard = ({ company = {} }) => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const theme = useTheme();
  const themedStyles = styles(theme);

  const { selfRegistrationActivated, applicationLink, selfRegistrationLink, evergreenLink } = company;

  const { data: listThemeData } = useListFormatThemeQuery(
    company.id,
    { active: true },
    { enabled: company.selfRegistrationActivated }
  );

  const onClickApplicationForm = () => {
    clipboardLink(getUrlApplicationLink(applicationLink));
  };

  const onClickOpenMentoring = () => {
    clipboardLink(getUrlSelfRegistrationLink(selfRegistrationLink, applicationLink, listThemeData[0].id));
  };

  const onClickEvergreen = () => {
    clipboardLink(getUrlEvergreenLink(evergreenLink));
  };

  const clipboardLink = (link) => {
    clipboardCopy(link).catch((err) => {
      console.error('Copy failed:', err);
    });

    setStateAlert({ open: true, message: t('sharing_links_card.msg_clipboard') });
  };

  return (
    <DashboardCard sx={themedStyles.card}>
      <Box>
        <Typography sx={themedStyles.title}>{t('sharing_links_card.title')}</Typography>
      </Box>
      <SharingLinkBlock
        clientLabel={company.clientLabel}
        expertLabel={company.expertLabel}
        type="application_form"
        onClick={onClickApplicationForm}
      />

      <Box sx={themedStyles.divider} />

      {selfRegistrationActivated && (
        <SharingLinkBlock
          clientLabel={company.clientLabel}
          expertLabel={company.expertLabel}
          type="open_mentoring"
          onClick={onClickOpenMentoring}
        />
      )}
      {!selfRegistrationActivated && (
        <SharingLinkBlock
          clientLabel={company.clientLabel}
          expertLabel={company.expertLabel}
          type="evergreen"
          onClick={onClickEvergreen}
        />
      )}
    </DashboardCard>
  );
};

export default SharingLinksCard;
