const styles = {
  button: {
    height: '24px !important',
    minWidth: '24px !important',
    maxWidth: '24px !important',
    padding: '0 !important',
  },
};

export default styles;
