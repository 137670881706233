const styles = (theme) => ({
  container: {
    padding: '18px',
    height: '72px',
    border: '1px solid',
    borderRadius: '12px',
    borderColor: 'dashboard.border',
    background: 'white',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(0, 0, 0, 0.02) 0px 1px 2px 0px',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    overflow: 'hidden',

    '&:hover': {
      borderColor: 'primary.main',
      cursor: 'pointer',
    },
  },

  date: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: '14px',
  },

  time: {
    fontWeight: 500,
    fontSize: '12px',
    color: theme.palette.primary.greyMetal,
  },

  border: {
    height: '100%',
    borderLeft: `1px solid ${theme.palette.primary.greyMetal}66`,
    width: '0px',
  },

  sessionTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '13px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  themeTitle: {
    fontWeight: 300,
    color: '#667085',
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    padding: '4px',
    borderRadius: '6px',

    ':hover': {
      background: `${theme.palette.primary.main}0A`,
    },
  },

  userAvatar: {
    width: '28px',
    height: '28px',
  },

  userName: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'primary.greyMetal',
  },

  programContainer: {
    flex: 0.7,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '100px',
  },

  progressContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '100px',
  },

  progressBar: {
    borderRadius: '5px',
  },

  progressBarText: {
    color: 'text.secondary',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  chip: {
    background: '#F2F2F2',
    borderColor: '#32313B99',
    height: '20px',
    fontSize: '12px',

    '.MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },

  unplannedSession: {
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    color: 'primary.grey',
    width: '42px',
    textAlign: 'center',
  },

  calendarIcon: {
    width: '16px',
    marginRight: '8px',
  },

  camIcon: {
    width: '22px',
    marginRight: '6px',
  },

  unplannedButtonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'right',
  },
});

export default styles;
