/* eslint-disable react/react-in-jsx-scope */
/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
import React from 'react';
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import ActiveFilterOperators from 'ui/components/DataGrid/Filters/ActiveFilter/ActiveFilter';
import TypeFilterOperators from 'ui/components/DataGrid/Filters/TypeFilter/TypeFilter';
import LanguageFilterOperators from 'ui/components/DataGrid/Filters/LanguageFilter/LanguageFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

const tableSchemaBase = [
  { field: 'user', headerName: 'name', width: 220, fontSize: '2px', type: 'user', resizable: true },
  {
    field: 'programsInProgress',
    headerName: 'programs_in_progress',
    type: 'text',
    minWidth: 130,
    resizable: true,
    flex: 1,
  },
  {
    field: 'max_capacity',
    sortable: false,
    filter: false,
    filterable: false,
    headerName: 'max_capacity',
    width: 130,
    resizable: true,
    disableColumnMenu: true,
  },
  {
    field: 'active',
    headerName: 'activated',
    type: 'text',
    filterOperators: ActiveFilterOperators,
    minWidth: 100,
    resizable: true,
    flex: 1,
  },
  {
    field: 'internalCoach',
    headerName: 'type',
    type: 'text',
    minWidth: 100,
    filterOperators: TypeFilterOperators,
    resizable: true,
    flex: 1,
  },
  {
    field: 'scopeName',
    headerName: 'scope',
    type: 'text',
    width: 140,
    filterOperators: ScopeFilterOperators,
    resizable: true,
  },
  {
    field: 'seniorityLevel',
    headerName: 'seniority_level',
    sortable: false,
    filter: false,
    filterable: false,
    type: 'text',
    width: 160,
    resizable: true,
    disableColumnMenu: true,
  },
  {
    field: 'languagesSpoken',
    headerName: 'languages',
    type: 'languages',
    width: 180,
    filterOperators: LanguageFilterOperators,
    resizable: true,
  },
  {
    field: 'registrationDate',
    headerName: 'registration_date',
    resizable: true,
    minWidth: 160,
    type: 'text',
    flex: 1,

    filterOperators: DateFilterOperators,
  },
  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 120,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsExperts',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'actionsRhValidationSelfRegistration',
    headerName: '',
    sortable: false,
    filter: false,
    filterable: false,
    type: 'actionsRhValidationSelfRegistration',
    headerAlign: 'center',
    align: 'center',
    minWidth: 340,
    resizable: true,
    disableColumnMenu: true,
    flex: 2,
  },
];

export { tableSchemaBase };
