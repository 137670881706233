const styles = {
  container: {
    minHeight: 'calc(100vh - 130px)',
    background: 'white',
    padding: '30px 60px',
    display: 'flex',
    gap: '24px',
  },
  welcomeTitle: {
    fontSize: '24px',
    fontWeight: '600',
  },
  resourcesTitle: {
    fontSize: '16px',
    fontWeight: '500',
  },
};

export default styles;
