import React, { useState } from 'react';
import { Avatar, Box, Typography, Chip } from '@mui/material';
import DashboardCard from 'ui/components/DashboardCard/DashboardCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import parse from 'html-react-parser';
import { roleList } from 'utils/constUtils';

import { smallDateWithTime } from 'utils/dateUtils';

import { useSpecialCompanyResourcesQuery } from 'hooks/queries';

import { useTranslation, Trans } from 'react-i18next';

import styles from './HighlightedResourceCard.styles';
import Button from 'ui/components/Button/Button';

const HighlightedResourceCard = ({ company, role, deleteIcon = false, isLargeVersion = false, sxContainer = {} }) => {
  const { t } = useTranslation();
  const { data: specialResources } = useSpecialCompanyResourcesQuery();

  const typeCard = specialResources?.length ? 'default' : 'empty_state';

  const resource = specialResources?.[0] || {};

  const userLabels = {
    [roleList.COACH]: company.expertLabel,
    [roleList.COACHEE]: company.clientLabel,
    [roleList.RH]: 'RH',
    [roleList.RH_MANAGER]: 'RH',
  };

  const userLabel = userLabels[role] || '';

  const onClickResource = () => {
    if (typeCard === 'empty_state') {
      window.open(`${process.env.REACT_APP_PATHLINE_RESSOURCES_LINK}`, '_blank');
    } else {
      window.open(resource.path, '_blank');
    }
  };

  const bottomContentStyle =
    typeCard === 'empty_state' ? styles.bottomContent : { height: '100%', ...styles.bottomContent };

  const textContentStyle = resource.picture ? styles.textContentWithPicture : styles.textContentWithoutPicture;

  const firstDescriptionEmptyState = () => {
    if (isLargeVersion) {
      return (
        <Box sx={{ ...styles.description, height: '100%' }}>
          <Typography>
            <Trans
              i18nKey={`highlighted_resource_card.${typeCard}.description`}
              components={{ strong: <Typography sx={{ fontWeight: 600, display: 'block' }} /> }}
            />
          </Typography>

          <Typography sx={{ marginTop: '12px' }}>
            <Trans
              i18nKey={`highlighted_resource_card.${typeCard}.second_description`}
              components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline' }} /> }}
            />
          </Typography>
        </Box>
      );
    }

    return (
      <Typography sx={styles.description}>
        <Trans
          i18nKey={`highlighted_resource_card.${typeCard}.description`}
          components={{ strong: <Typography sx={{ fontWeight: 600, display: 'block' }} /> }}
        />
      </Typography>
    );
  };

  const typeRole = userLabel === 'RH' ? 'admin' : 'default';
  return (
    <DashboardCard isShowBoxShadow sx={{ height: '100%' }}>
      <Box sx={{ ...styles.container, ...sxContainer }}>
        <Box sx={{ display: 'flex', gap: '12px', position: 'relative' }}>
          <Typography sx={styles.title}>
            <Trans
              i18nKey={`highlighted_resource_card.${typeCard}.title.${typeRole}`}
              components={{
                strong: (
                  <Typography sx={{ display: 'inline', fontWeight: 600, fontSize: '16px', color: 'primary.main' }} />
                ),
              }}
              values={{ userLabel }}
            />
          </Typography>

          {typeCard !== 'empty_state' && resource.date && (
            <Chip variant="outlined" label={smallDateWithTime(resource.date)} sx={styles.timeChip} />
          )}

          {typeCard === 'empty_state' && !deleteIcon && <AccessTimeIcon sx={styles.icon} />}
          {typeCard !== 'empty_state' && !deleteIcon && <VideocamOutlinedIcon sx={styles.icon} />}
        </Box>

        {typeCard === 'empty_state' && firstDescriptionEmptyState()}

        {resource.picture && typeCard !== 'empty_state' && (
          <Box width={'100%'} component="img" src={resource.picture} />
        )}

        <Box sx={bottomContentStyle}>
          {typeCard === 'empty_state' && !isLargeVersion && (
            <Typography sx={styles.secondDescription}>
              <Trans
                i18nKey={`highlighted_resource_card.${typeCard}.second_description`}
                components={{ strong: <Typography sx={{ fontWeight: 600, display: 'inline' }} /> }}
              />
            </Typography>
          )}

          {typeCard === 'empty_state' && isLargeVersion && <Box />}

          <Box sx={textContentStyle}>
            {typeCard !== 'empty_state' && (
              <Box sx={{ ...styles.description, width: '100%' }}>{parse(resource.description)}</Box>
            )}

            <Box
              sx={
                typeCard !== 'empty_state' && !resource.picture
                  ? { height: '100%', alignContent: 'end' }
                  : typeCard === 'empty_state'
                  ? {}
                  : { width: '100%', textAlign: 'right' }
              }>
              <Button variant="secondary" size="xlg" isGrey sx={styles.button} onClick={onClickResource}>
                {t(`highlighted_resource_card.${typeCard}.button.${typeRole}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default HighlightedResourceCard;
