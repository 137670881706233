import React, { useState } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';

// UI Local Lib dependencies
import DataGrid from 'ui/components/DataGrid';
import DeleteModal from '../../../DeleteModal';

// Hooks
import useTemplateEmailCompany from 'hooks/usecase/useTemplateEmailCompany';

// Configs
import { tableSchemaBase as columns } from './EmailTemplates.config';

// Icons
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import CreateEmailTemplate from './components/Modals/CreateEmailTemplate';
import EditEmailTemplate from './components/Modals/EditEmailTemplate';
import { useTranslation } from 'react-i18next';

const EmailTemplates = ({ user, company }) => {
  const { t } = useTranslation();

  // States
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let { companyId } = useParams();
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25 });

  const {
    actions,
    watch,
    control,
    reset,
    onRemoveTemplateEmail,
    onAddTemplateEmail,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingTemplateEmail,
    onEditTemplateEmail,
    editingTemplateEmail,
    submitEditMarketingPicture,
    submitEditBackgroundPicture,
    onDuplicateTemplateEmail,
    isLoading,
    TemplateEmails: dataEmails,
    setSelectedTabCreate,
    selectedTabCreate,
    selectedTabEdit,
    setSelectedTabEdit,
    optionsTabCreate,
    setOptionsTabCreate,
    optionsTabEdit,
    setOptionsTabEdit,
    totalPages,
    totalItems,
  } = useTemplateEmailCompany(companyId, queryParams);

  // Helper
  const updateList = (emails) =>
    emails.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        createdAt: dayjs(currentItem.createdAt).format('DD-MM-YYYY'),
      };
      acc.push(modifiedItem);

      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      companyId: companyId,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, companyId: companyId, ...queryParamsSort });
  };

  const handleOpenModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenAddModal(false);
    reset();
  };

  const handleCloseEditModal = () => {
    setEditingTemplateEmail(false);
    reset();
  };

  return (
    <Stack sx={{ minHeight: '630px', backgroundColor: 'white', paddingLeft: '90px' }}>
      <Stack sx={{ paddingBottom: '9px' }}>
        <Button
          endIcon={<AddIcon />}
          onClick={handleOpenModal}
          sx={{
            width: '114px',
            height: '50px',
            borderRadius: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
            fontWeight: 500,
            fontSize: '15px',
            boxShadow: '0px 3px 5px -1px #00000033',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}>
          {t('add')}
        </Button>
      </Stack>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={dataEmails ? updateList(dataEmails) : []}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onShow={() => {}}
        onEdit={(company) => {
          setEditingTemplateEmail(company);
        }}
        onRowClicked={(emailTemplate, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingTemplateEmail(emailTemplate.row);
          }
        }}
        onDuplicate={(data) => {
          // eslint-disable-next-line no-unused-vars
          const { id, ...rest } = data;
          onDuplicateTemplateEmail(rest);
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        loading={isLoading}
        totalItems={totalItems}
        totalPages={totalPages ? totalPages : 0}
      />
      {/* Add modal */}
      {isOpenAddModal && (
        <CreateEmailTemplate
          actions={actions}
          companyId={companyId}
          control={control}
          watch={watch}
          reset={reset}
          isOpen={isOpenAddModal}
          setOpen={handleCloseModal}
          onAdd={onAddTemplateEmail}
          submitEditBackgroundPicture={submitEditBackgroundPicture}
          submitEditMarketingPicture={submitEditMarketingPicture}
          selectedTab={selectedTabCreate}
          setSelectedTab={setSelectedTabCreate}
          optionsTab={optionsTabCreate}
          setOptionsTab={setOptionsTabCreate}
          user={user}
          expertLabel={company.expertLabel}
          clientLabel={company.clientLabel}
          customHeaderPicture={company.customHeaderPicture}
          companyLogo={company.logo}
          companyColorConfig={company.colorConfig}
        />
      )}
      {/* Edit modal */}
      {editingTemplateEmail && (
        <EditEmailTemplate
          companyId={companyId}
          control={control}
          actions={actions}
          watch={watch}
          reset={reset}
          isOpen={editingTemplateEmail}
          setOpen={handleCloseEditModal}
          submitEditBackgroundPicture={submitEditBackgroundPicture}
          submitEditMarketingPicture={submitEditMarketingPicture}
          onEdit={onEditTemplateEmail}
          selectedTab={selectedTabEdit}
          setSelectedTab={setSelectedTabEdit}
          optionsTab={optionsTabEdit}
          setOptionsTab={setOptionsTabEdit}
          user={user}
          expertLabel={company.expertLabel}
          clientLabel={company.clientLabel}
          customHeaderPicture={company.customHeaderPicture}
          companyLogo={company.logo}
          colorConfig={company.colorConfig}
        />
      )}
      <DeleteModal
        title="Delete Email Template"
        description="Are you sure you want to delete this email template"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemoveTemplateEmail(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

export default EmailTemplates;
