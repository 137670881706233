import React, { useEffect, useState } from 'react';
import MuiPagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/system/Stack';
import { IconButton, Typography, Tooltip, Box, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

// Custom cells
import CellActionsExperts from './Cells/CellActionsExperts';
import CellActionsRhValidationSelfRegistration from './Cells/CellActionsRhValidationSelfRegistration';
import CellActionsCoachee from './Cells/CellActionsCoachee';
import CellActionsMainCompanyAdmin from './Cells/CellActionsMainCompanyAdmin';
import CellActionsButtons from './Cells/CellActionsButtons';
import CellActionsCompanies from './Cells/CellActionsCompanies';
import CellActionsHome from './Cells/CellActionsHome';
import CellActionsProgramRh from './Cells/CellActionsProgramRh';
import CellActionsBackoffice from './Cells/CellActionsBackoffice';
import CellActionEditButton from './Cells/CellActionEditButton';
import CellActionsAdmins from './Cells/CellActionsAdmins';
import CellActionsSimple from './Cells/CellActionsSimple';
import CellText from './Cells/CellText';
import CellSmallText from './Cells/CellSmallText';
import CellAdminNote from './Cells/CellAdminNote';
import CellSmallTextWithTooltip from './Cells/CellSmallTextWithTooltip';
import CellUser from './Cells/CellUser';
import CellTopic from './Cells/CellTopic';
import CellStatus from './Cells/CellStatus';
import CellStatusEvergreen from './Cells/CellStatusEvergreen';
import CellUpcomingSessions from './Cells/CellUpcomingSessions';
import CellEvergreenClosingDate from './Cells/CellEvergreenClosingDate';
import CellCoach from './Cells/CellCoach';
import CellLanguages from './Cells/CellLanguages';
import CellInternalCoach from './Cells/CellInternalCoach';
import CellGender from './Cells/CellGender';
import CellChip from './Cells/CellChip';
import CellAvatar from './Cells/CellAvatar';
import CellLinearProgress from './Cells/CellLinearProgress';
import CellThemeStatus from './Cells/CellThemeStatus';
import CellSmallModernText from './Cells/CellSmallModernText';
import CellModernNumber from './Cells/CellModernNumber';
import { DataGridPro, GridPagination, GridRow, GridColumnMenu } from '@mui/x-data-grid-pro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { parseJsonTranslate } from 'utils/parse';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const imageNoData = `${process.env.PUBLIC_URL}/uploads/images/file.png`;

// Sub components
// eslint-disable-next-line react/prop-types
function Pagination({ className, pageCount, currentPage, onChangePage }) {
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={currentPage}
      onChange={(event, newPage) => {
        onChangePage(newPage);
      }}
    />
  );
}

const UnsortedIcon = () => {
  return (
    <ArrowDownwardIcon className="icon" sx={{ visibility: 'visible', opacity: 0.5, width: '18px', height: '18px' }} />
  );
};

const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
};

function CustomPagination(props) {
  return (
    <GridPagination
      ActionsComponent={(paginationProps) => (
        <Pagination
          pageCount={props.pageCount}
          currentPage={props.currentPage}
          onChangePage={props.onChangePage}
          {...paginationProps}
        />
      )}
      {...props}
    />
  );
}

// Entities & constants
const ColumnType = {
  Text: 'text',
  SmallText: 'smallText',
  SmallTextWithTooltip: 'smallTextWithTooltip',
  ActionsExperts: 'actionsExperts',
  ActionsCoachee: 'actionsCoachee',
  ActionsCompanies: 'actionsCompanies',
  ActionsSimple: 'actionsSimple',
  ActionsBackoffice: 'actionsBackoffice',
  ActionsBackofficeWithOldEdit: 'actionsBackofficeWithOldEdit',
  ActionsAdmins: 'actionsAdmins',
  ActionsButtons: 'actionsButtons',
  ActionsHome: 'actionsHome',
  ActionsProgramRh: 'actionsProgramRh',
  ActionMainCompanyAdmin: 'actionMainCompanyAdmin',
  ActionEditButton: 'actionEditButton',
  User: 'user',
  Topic: 'topic',
  Status: 'status',
  LinearProgress: 'linearProgress',
  Coach: 'coach',
  EvergreenCoach: 'evergreenCoach',
  Avatar: 'avatar',
  Languages: 'languages',
  InternalCoach: 'internalCoach',
  EvergreenStatus: 'evergreenStatus',
  UpcomingSessions: 'upcomingSessions',
  EvergreenClosingDateConfig: 'evergreenClosingDateConfig',
  Gender: 'gender',
  Chip: 'chip',
  ActionsRhValidationSelfRegistration: 'actionsRhValidationSelfRegistration',
  ThemeStatus: 'themeStatus',
  SmallModernText: 'smallModernText',
  ModernNumber: 'modernNumber',
  AdminNote: 'adminNote',
};

const DataGrid = (props) => {
  const theme = useTheme();
  /* ***************************** LOCAL VARIABLES **************************** */
  const {
    user,
    columns,
    rows,
    onDelete,
    onDeactivate,
    onEditNote,
    onReactivate,
    onChangeIsMainCompanyAdmin,
    onEdit,
    onEditOld,
    onDuplicate,
    onShow,
    onFilter,
    onSort,
    onAdd,
    onPaginationChange,
    onRhValidationRefuse,
    onRhValidationAccept,
    formPath,
    onRowClicked,
    role,
    totalPages,
    loading,
    totalItems,
    asCoachee,
    isAuthorizeScroll = true,
    isShowBorder = true,
    isShowNoteAdmin = false,
    newDesign = true,
    isShowPagination = true,
    onAnswerRequest,
    isDisablesRowClick = false,
    currentPage: currentPageParent,
    sxDataGrid = {},
    ...restProps
  } = props;
  const { t } = useTranslation();

  // Pagination
  const SIZES = [25, 50, 100];

  //HEADER_STYLE_COLUMN
  const HEADER_STYLE_COLUMN = {
    overflow: 'visible !important',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'Karla',
    fontWeight: 700,
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
  };

  // State
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(25);

  useEffect(() => {
    setCurrentPage(currentPageParent);
  }, [currentPageParent]);

  // Get list with filter params

  const onFilterChange = (filter) => {
    if (filter?.items[0]?.value) {
      setCurrentPage(1);
    }
    onFilter(filter, currentPage, currentSize);
  };

  // Get list with pagination params
  const onPaginationChange1 = (page) => {
    onPaginationChange(page, currentSize);
    setCurrentPage(page);
  };

  // Get list with sort params
  const onSortChange = (model) => {
    if (model.length !== 0) {
      onSort(
        {
          orderBy: model[0].field,
          orderDirection: model[0].sort.toUpperCase(),
        },
        currentPage,
        currentSize
      );
    } else {
      onSort({ orderBy: '', orderDirection: '' }, currentPage, currentSize);
    }
  };

  // Translate i18n of header
  const translatedColumns = columns.map((column) => ({
    ...column,
    headerName: column.translateHeaderName === false ? column.headerName : t(column.headerName),
  }));

  // Custom column
  function getAddColumnCta() {
    return (
      <Stack>
        <Typography style={{ fontSize: '18px' }} fontWeight="fontWeightBold" variant="h6">
          {t('actions')}
        </Typography>
      </Stack>
    );
  }

  // Custom column
  function columnMainCompanyAdmin() {
    return (
      <Stack>
        <Typography style={{ fontSize: '18px' }} fontWeight="fontWeightBold" variant="h6">
          {t('actions')}
        </Typography>
      </Stack>
    );
  }

  /**
   * Provides a rendering function to provide to custom table cells
   * @param {Function} Component Functional component of kind `CellComp`
   * @param {String} valueProp Name of the value prop to be passed to the
   * component (depending on component signature)
   * @returns
   */
  function getCellRenderer(Component, valueProp) {
    return function render({ row, colDef }) {
      let value;
      if (row[colDef.field] && colDef.type) {
        switch (colDef.type) {
          case 'text':
            value = typeof row[colDef.field] === 'object' ? parseJsonTranslate(row[colDef.field]) : row[colDef.field];
            break;
          case 'bigText':
            value = typeof row[colDef.field] === 'object' ? parseJsonTranslate(row[colDef.field]) : row[colDef.field];
            break;
          case 'chip':
            value = row[colDef.field];
            break;
          case 'user':
            value = row[colDef.field];
            break;
          case 'date':
            value = row[colDef.field];
            break;
          default:
            // Row value is a serialized object
            // @TODO: Better handled with try catch to check if it's
            // an actual object, otherwise fallback to text
            value = row[colDef.field];
            break;
        }
      }
      // Has no content or not a specific or supported type
      else {
        value = row[colDef.field];
      }
      const cellProps = { [valueProp]: value };
      return <Component {...cellProps} />;
    };
  }

  const adaptedColumns = translatedColumns.map((c) => {
    // Get information of each column
    let adaptedCol = { ...c };
    switch (c.type) {
      case undefined:
        if (!c.type || c.type === 'text') {
          adaptedCol.selector = function render(row) {
            return row[c.field];
          };
        }
        break;
      case null:
        adaptedCol.selector = undefined;
        break;
      case ColumnType.ActionsExperts: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsExperts
            enableDelete={
              !row.hasSubscriptions &&
              role === 'RH_MANAGER' &&
              (!row.availableRoles || (row.availableRoles.length === 1 && row.availableRoles[0] == 'COACH'))
            }
            onAdd={onAdd}
            onShow={onShow}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsRhValidationSelfRegistration: {
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsRhValidationSelfRegistration
            onRefuse={() => onRhValidationRefuse(row)}
            onAccept={() => onRhValidationAccept(row)}
          />
        );
        break;
      }

      case ColumnType.ThemeStatus: {
        adaptedCol.renderCell = ({ row }) => <CellThemeStatus status={row.active} />;
        break;
      }

      case ColumnType.ActionsCompanies: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsCompanies onShow={onShow} onDelete={onDelete} onEdit={onEdit} pathname={formPath} rowData={row} />
        );
        break;
      }

      case ColumnType.ActionsSimple: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsSimple onShow={onShow} onDelete={onDelete} onEdit={onEdit} pathname={formPath} rowData={row} />
        );
        break;
      }
      case ColumnType.ActionsBackoffice: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsBackoffice
            onShow={onShow}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsBackofficeWithOldEdit: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsBackoffice
            onShow={onShow}
            onDelete={onDelete}
            onEdit={onEdit}
            onEditOld={onEditOld}
            onDuplicate={onDuplicate}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsAdmins: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsAdmins
            isShowNoteAdmin={isShowNoteAdmin}
            onShow={onShow}
            onDelete={onDelete}
            onEdit={onEdit}
            onEditNote={onEditNote}
            onDuplicate={onDuplicate}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsCoachee: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsCoachee
            enableDelete={row.source !== 'User'}
            onEdit={onEdit}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionMainCompanyAdmin: {
        adaptedCol.renderHeader = () => (
          <Typography
            style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
            fontWeight="fontWeightBold"
            variant="h6"
            noWrap>
            {t(adaptedCol.headerName)}

            <Tooltip title={t('is_main_company_admin_tooltip')}>
              <IconButton size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        );
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsMainCompanyAdmin
            isMainCompanyAdmin={row.isMainCompanyAdmin}
            onChange={({ activated }) => onChangeIsMainCompanyAdmin({ activated, user: row })}
          />
        );
        break;
      }
      case ColumnType.Avatar: {
        adaptedCol.renderCell = getCellRenderer(CellAvatar, 'logo');
        break;
      }
      case ColumnType.ActionsHome: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsHome
            role={role}
            enableDelete={row.source !== 'User'}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
            asCoachee={asCoachee}
            onAnswerRequest={onAnswerRequest}
          />
        );
        break;
      }
      case ColumnType.ActionsProgramRh: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsProgramRh
            onDeactivate={onDeactivate}
            onEditNote={onEditNote}
            onReactivate={onReactivate}
            rowData={row}
            user={user}
          />
        );
        break;
      }
      case ColumnType.ActionsButtons: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsButtons
            addButtonDisabled={row.addButtonDisabled || false}
            addButtonTooltip={row.addButtonTooltip || ''}
            hiddenAddButton={c.hiddenAddButton || false}
            onAdd={onAdd}
            onShow={onShow}
            onDelete={onDelete}
            pathname={formPath}
            expertLabel={row.expertLabel}
            sessionLabel={row.sessionLabel}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionEditButton: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => <CellActionEditButton row={row} onEdit={onEdit} />;
        break;
      }
      case ColumnType.User: {
        adaptedCol.renderCell = getCellRenderer(CellUser, 'user');
        break;
      }
      case ColumnType.Topic: {
        adaptedCol.renderCell = getCellRenderer(CellTopic, 'theme');
        break;
      }
      case ColumnType.Languages: {
        adaptedCol.renderCell = getCellRenderer(CellLanguages, 'languages');
        break;
      }
      case ColumnType.Chip: {
        adaptedCol.renderCell = getCellRenderer(CellChip, 'text');
        break;
      }
      case ColumnType.Gender: {
        adaptedCol.renderCell = getCellRenderer(CellGender, 'gender');
        break;
      }
      case ColumnType.InternalCoach: {
        adaptedCol.renderCell = getCellRenderer(CellInternalCoach, 'type');
        break;
      }
      case ColumnType.Status: {
        adaptedCol.renderCell = ({ row }) => <CellStatus expertLabel={row.expertLabel} status={row.status} />;
        break;
      }
      case ColumnType.EvergreenStatus: {
        adaptedCol.renderCell = ({ row }) => <CellStatusEvergreen status={row?.evergreenCoachesStatus?.status} />;
        break;
      }
      case ColumnType.UpcomingSessions: {
        adaptedCol.renderCell = ({ row }) => <CellUpcomingSessions upcomingSessions={row.upcoming_sessions} />;
        break;
      }
      case ColumnType.EvergreenClosingDateConfig: {
        adaptedCol.renderCell = ({ row }) => <CellEvergreenClosingDate config={row?.evergreenClosingDateConfig} />;
        break;
      }
      case ColumnType.LinearProgress: {
        adaptedCol.renderCell = getCellRenderer(CellLinearProgress, 'progress');
        break;
      }

      case ColumnType.Coach: {
        adaptedCol.renderCell = getCellRenderer(CellCoach, 'coach');
        break;
      }

      case ColumnType.EvergreenCoach: {
        const coachPosition = c.field.replace('evergreenCoach', '');
        adaptedCol.renderCell = ({ row }) => {
          if (row?.evergreenCoachesStatus?.coachesStatus && !row?.evergreenCoachesStatus?.coachesStatus.length) {
            return;
          }

          const coachStatus = row?.evergreenCoachesStatus?.coachesStatus?.find(
            (status) => status.coachOrder == coachPosition
          );

          return <CellCoach coach={coachStatus?.coach} evergreenData={coachStatus} />;
        };
        break;
      }
      case ColumnType.Text: {
        adaptedCol.renderCell = getCellRenderer(CellText, 'text');
        break;
      }
      case ColumnType.SmallText: {
        adaptedCol.renderCell = getCellRenderer(CellSmallText, 'text');
        break;
      }
      case ColumnType.SmallTextWithTooltip: {
        adaptedCol.renderCell = getCellRenderer(CellSmallTextWithTooltip, 'text');
        break;
      }
      case ColumnType.SmallModernText: {
        adaptedCol.renderCell = getCellRenderer(CellSmallModernText, 'text');
        break;
      }
      case ColumnType.ModernNumber: {
        adaptedCol.renderCell = getCellRenderer(CellModernNumber, 'text');
        break;
      }
      case ColumnType.AdminNote: {
        adaptedCol.renderCell = ({ row }) => {
          return <CellAdminNote adminNote={row.adminNote} />;
        };
        break;
      }
      default:
        adaptedCol.cell = getCellRenderer(CellText, 'text');
        break;
    }

    return adaptedCol;
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const paginationStyle = !isShowPagination
    ? {
        '& .MuiDataGrid-footerContainer': { display: 'none' },
      }
    : {};

  const sxNewDesign = newDesign
    ? {
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '&': {
          // MuiDataGrid-root
          borderBottomLeftRadius: '8px !important',
          borderBottomRightRadius: '8px !important',
        },

        '& .MuiDataGrid-virtualScroller': {
          overflow: isAuthorizeScroll ? 'auto' : 'hidden',
        },

        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#f7f9fc',
          minHeight: '44px !important',
          maxHeight: '44px !important',
          lineHeight: '44px !important',
        },

        '& .MuiDataGrid-columnHeader': {
          height: '44px !important',

          '&:focus-within, &:focus-visible, &:focus': {
            outline: 'none',
          },
        },

        '& .MuiDataGrid-columnSeparator': {
          display: 'none !important',
        },

        '& .MuiDataGrid-columnHeaderTitle': {
          color: '#5f6b7a',
          fontWeight: 500,
          fontSize: '12px',
          textTransform: 'none',
        },

        '& .MuiDataGrid-row': {
          height: '68px !important',
          maxHeight: '68px !important',
          minHeight: '68px !important',

          '&:hover': {
            background: `${theme.palette.primary.main}03`,
          },
        },

        '& .MuiDataGrid-cell': {
          height: '68px !important',
          maxHeight: '68px !important',
          minHeight: '68px !important',
          borderColor: `${theme.palette.dashboard.borderSecondary} !important`,
        },
      }
    : {};

  const borderStyle = isShowBorder
    ? {
        '& .MuiDataGrid-main': {
          borderTopLeftRadius: '8px !important',
          borderTopRightRadius: '8px !important',
          border: '1px solid',
          borderColor: 'dashboard.borderSecondary',
        },
      }
    : {};

  return (
    <DataGridPro
      rows={rows}
      columns={adaptedColumns}
      pagination
      rowCount={totalItems ? totalItems : null}
      slots={{
        row: (props) => {
          return (
            <Tooltip placement="bottom" title={props?.row?.tooltip || ''}>
              <GridRow {...props} />
            </Tooltip>
          );
        },
        pagination: (paginationProps) => (
          <CustomPagination
            {...paginationProps}
            pageCount={totalPages}
            currentPage={currentPage}
            onChangePage={(page) => {
              setPaginationModel({ pageSize: currentSize, page: page - 1 });
              setCurrentPage(page);
              onPaginationChange1(page);
            }}
          />
        ),
        loadingOverlay: LinearProgress,
        columnMenu: CustomColumnMenu,
        columnUnsortedIcon: UnsortedIcon,
      }}
      loading={loading}
      filterMode="server"
      sortingMode="server"
      paginationMode="server"
      slotProps={{
        columnsPanel: {
          disableHideAllButton: true,
          disableShowAllButton: true,
        },
        filterPanel: {
          sx: { width: '470px' },
          filterFormProps: {
            operatorInputProps: {
              disabled: true,
              sx: { display: 'none' },
            },
            columnInputProps: {
              sx: { paddingRight: '20px' },
            },
            valueInputProps: {
              sx: { width: '100%' },
            },
          },
        },
      }}
      disableColumnPinning
      hideFooterSelectedRowCount
      onRowClick={(...args) => {
        !isDisablesRowClick && onRowClicked(...args);
      }}
      onFilterModelChange={onFilterChange}
      onPaginationModelChange={({ pageSize }) => {
        if (pageSize !== paginationModel.pageSize) {
          onPaginationChange(1, pageSize);
          setPaginationModel({ pageSize: pageSize, page: 0 });
          setCurrentSize(pageSize);
          setCurrentPage(1);
        } else {
          onPaginationChange(currentPage, pageSize);
          setCurrentSize(pageSize);
        }
      }}
      paginationModel={paginationModel}
      onSortModelChange={onSortChange}
      initialState={{
        pagination: { paginationModel: paginationModel },
        noRowsOverlay: (
          <Stack sx={{ height: '45px', padding: 25 }} height={30} alignItems="center" justifyContent="center">
            Local filter returns no result
          </Stack>
        ),
      }}
      pageSizeOptions={SIZES}
      sx={{
        overflowX: 'none',
        boxShadow: 0,
        border: 0,
        borderColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        '.MuiDataGrid-columnHeaderTitle': HEADER_STYLE_COLUMN,
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: isDisablesRowClick ? 'default' : 'pointer',
        },
        '& .MuiDataGrid-sortIcon': {
          opacity: 1,
          color: 'secondary.main',
        },
        '& .MuiDataGrid-menuIconButton': {
          opacity: 1,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: 'normal',
        },
        ...paginationStyle,
        ...borderStyle,
        ...sxNewDesign,
        ...sxDataGrid,
      }}
      localeText={{
        columnMenuLabel: t('columnMenuLabel'),
        columnMenuShowColumns: t('columnMenuShowColumns'),
        columnMenuFilter: t('columnMenuFilter'),
        columnMenuHideColumn: t('columnMenuHideColumn'),
        columnMenuUnsort: t('columnMenuUnsort'),
        columnMenuManageColumns: t('columnMenuManageColumns'),
        columnMenuSortAsc: t('columnMenuSortAsc'),
        columnMenuSortDesc: t('columnMenuSortDesc'),
        columnTitle: t('columnTitle'),
        filterPanelAddFilter: t('filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
        filterPanelOperators: t('filterPanelOperators'),
        filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('filterPanelOperatorOr'),
        filterPanelColumns: t('filterPanelColumns'),
        filterPanelRemoveAll: t('filterPanelRemoveAll'),
        filterPanelInputLabel: t('filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
        toolbarDensity: t('toolbarDensity'),
        toolbarDensityLabel: t('toolbarDensityLabel'),
        toolbarDensityCompact: t('toolbarDensityCompact'),
        toolbarDensityStandard: t('toolbarDensityStandard'),
        toolbarDensityComfortable: t('toolbarDensityComfortable'),
        status: t('status'),
        program_status_draft: t('program_status_draft'),
        program_status_pending: t('program_status_pending'),
        program_status_in_progress: t('program_status_in_progress'),
        program_status_done: t('program_status_done'),
        noRowsLabel: (
          <Stack alignItems={'center'}>
            {imageNoData ? <Box onClick={() => {}} component="img" src={imageNoData} /> : null}
            <Typography>{'No data'}</Typography>
          </Stack>
        ),
      }}
      {...restProps}
    />
  );
};

export default DataGrid;
