import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import Button from 'ui/components/Button/Button';

const ModalSelectLangues = ({ languages = [], open, onClose }) => {
  const { t } = useTranslation();

  const currentLanguage = t(`lang_${languages[0]}`);
  const others = languages.slice(1);
  const othersLanguages = others.map((language) => `"${t(`lang_${language}`)}"`).join(', ');

  return (
    <ViewModal
      customStyleBox={{
        maxWidth: '500px',
        height: 'max-content',
        borderRadius: '16px',
      }}
      open={open}
      onClose={onClose}>
      <Box>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              paddingBottom: '16px',
            }}>
            {t('warning_edit_language_modal.title')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', margin: 'auto', lineHeight: '25px', fontWeight: 300 }}>
          <Typography variant="p">
            {t('warning_edit_language_modal.desc', { count: others.length, currentLanguage, othersLanguages })}
          </Typography>
        </Box>

        <Box
          sx={{
            paddingTop: '20px',
            textAlign: 'right',
          }}>
          <Button onClick={onClose} variant="secondary">
            {t('warning_edit_language_modal.button')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
};

export default ModalSelectLangues;
