export const isValidFilterConfig = (obj) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) {
    return false;
  }

  if (!Array.isArray(obj.items) || obj.items.length === 0) {
    return false;
  }

  for (const item of obj.items) {
    if (!item?.value || item.value === null || item.value === undefined) {
      return false;
    }
  }

  return true;
};

export const filterObjects = (data, filterConfig) => {
  const { items, logicOperator } = filterConfig;

  return data.filter((item) => {
    if (logicOperator === 'and') {
      return items.every((filter) => matchFilter(item, filter));
    } else if (logicOperator === 'or') {
      return items.some((filter) => matchFilter(item, filter));
    }
    return true;
  });
};

const matchFilter = (item, filter) => {
  if (Array.isArray(item[filter.field])) {
    return item[filter.field].includes(filter.value);
  }
  return item[filter.field] === filter.value;
};
