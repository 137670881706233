import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { useGuests } from 'hooks/usecase/useGuests';
import DeleteModal from 'ui/pages/users/components/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import EditModal from 'ui/components/users/EditGuestModal';
import validation from '../validators/user.validator';

// Configs
import { tableSchemaBase as columns } from './Coachees.config';
import { ADD } from 'litterals';
import { AdminNoteCoachee } from 'ui/components/AdminNoteCoachee/AdminNoteCoachee';

const Coachees = ({ role, companyId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25, companyId: companyId });
  const [guestToDelete, setGuestToDelete] = useState(null);
  const [openAdminNote, setOpenAdminNote] = useState(false);
  const [adminNoteCoacheeSelected, setAdminNoteCoacheeSelected] = useState({});
  const { t } = useTranslation();

  // Hooks
  const {
    guests,
    isLoading,
    control,
    totalPages,
    totalItems,
    handleDeleteGuestBackoffice,
    onEdit,
    reset,
    watch,
    isOpenEditModal,
    setIsOpenEditModal,
    setEditingGuest,
    isEditLoading,
  } = useGuests(queryParams);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, scopeId: filter.value };
            break;
          case 'user':
            // eslint-disable-next-line no-case-declarations
            let queryParamsFilterName = {};
            if (filter && filter.value && filter.value.length > 0) {
              var [premierMot, ...resteChaine] = filter.value.split(' ');
              if (resteChaine.length > 0) {
                queryParamsFilterName = { firstname: premierMot, lastname: resteChaine.join(' ') };
              } else {
                queryParamsFilterName = { firstname: premierMot };
              }
            }
            queryParamsFilter = { ...queryParamsFilter, ...queryParamsFilterName };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'scopeId' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'createdAt':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const onEditNote = (row) => {
    setOpenAdminNote(true);
    setAdminNoteCoacheeSelected(row);
  };

  return (
    <Stack sx={{ height: '630px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '72px' }}>
        {/**  <Button
          onClick={() => {
            // setIsOpenAddModal(true);
          }}
          sx={{ marginTop: '10px', minWidth: '170px', backgroundColor: 'primary.dark' }}
          variant="contained">
          {t(ADD) + ' ' + 'COACHEE'}
        </Button> */}
      </Box>
      <DataGrid
        columns={columns}
        isShowNoteAdmin
        rows={guests}
        role={role}
        totalItems={totalItems}
        onEdit={(user) => {
          setEditingGuest(user);
        }}
        onEditNote={onEditNote}
        onDelete={(item) => {
          setIsOpen(true);
          setGuestToDelete(item);
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => setQueryParams({ ...queryParams, page: page, size: nbrPages })}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
      {/** Modal to edit */}
      <EditModal
        title={'Edit '}
        control={control}
        isOpen={isOpenEditModal}
        onClose={() => {
          reset();
          setIsOpenEditModal(false);
        }}
        onEdit={onEdit}
        validation={validation}
        watch={watch}
        companyId={companyId}
        isEditLoading={isEditLoading}
      />
      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={guestToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          handleDeleteGuestBackoffice({ id: guestToDelete.id, source: guestToDelete.source });
          setIsOpen(false);
        }}
      />

      {openAdminNote && (
        <AdminNoteCoachee
          adminNote={adminNoteCoacheeSelected.adminNote}
          coacheeId={adminNoteCoacheeSelected.id}
          open={openAdminNote}
          source={adminNoteCoacheeSelected.source}
          onClose={() => {
            setAdminNoteCoacheeSelected({});
            setOpenAdminNote(false);
          }}
        />
      )}
    </Stack>
  );
};

export default Coachees;
