const styles = (theme) => ({
  container: {
    borderRadius: '8px',
    background: 'white',
    border: '1px solid',
    borderColor: 'dashboard.border',
  },
  actionNeededContainer: {
    background: '#6C63FF08',
  },
  boxShadowContainer: {
    boxShadow: `${theme.palette.primary.main}1A 0px 6px 25px 0px`,
    border: 'none',
  },
});

export default styles;
