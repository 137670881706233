const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: `${theme.palette.primary.border}1A`,
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  message: {
    fontWeight: 300,
    fontSize: '18px',
    color: 'rgb(162, 162, 162)',
    maxWidth: '70%',
  },
});

export default styles;
