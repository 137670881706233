import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const TargetRolesFilter = ({ item, applyValue, clientLabel, expertLabel }) => {
  const { t } = useTranslation();

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: 42,
        pt: '2px',
      }}>
      <Select
        sx={{ width: '100%', height: '20px' }}
        value={item.value}
        onChange={(event) => handleFilterChange(event.target.value)}>
        {Object.keys(t('email_template.target_role')).map((key) => {
          return (
            <MenuItem key={key} value={key}>
              {t(`email_template.target_role.${key}`, { clientLabel, expertLabel })}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

const TargetRolesFilterOperation = (clientLabel, expertLabel) => {
  return [
    {
      label: 'Above',
      value: 'above',
      getApplyFilterFn: (filterItem) => {},
      InputComponent: TargetRolesFilter,
      InputComponentProps: { clientLabel, expertLabel },
    },
  ];
};

export default TargetRolesFilterOperation;
