import React from 'react';

import Button from '../Button/Button';
import { Box } from '@mui/system';

import styles from './ButtonFullScreen.styles';

const fullscreenSvg = `${process.env.PUBLIC_URL}/uploads/icons/fullscreen.svg`;

const ButtonFullScreen = ({ onClick }) => {
  return (
    <Button sx={styles.button} isGrey variant="secondary" onClick={onClick}>
      <Box component="img" src={fullscreenSvg} />
    </Button>
  );
};

export default ButtonFullScreen;
