/* -------------------------------------------------------------------------- */
/*                                Email Template                              */
/* -------------------------------------------------------------------------- */

/**
 * Defines an email template where occurrences of ${customContent} are replaced with $customContent$.
 *
 * @const {customContent}
 */

const DEFAULT_HEADER = 'https://pathline-prod-bucket.s3.fr-par.scw.cloud/email_pictures/email_background.png';
const DEFAULT_COLOR = '#5236FF';

export const getEmailTemplate = (content, footer, header, companyLogo, companyColor = DEFAULT_COLOR) => {
  const companyLogoHtml = companyLogo
    ? `
    <tr>
      <td style="padding: 20px 0px 0px 0px;">
        <img style="max-height: 75px; max-width: 130px; margin-right:auto; margin-left:auto;" title="Banner" src="${companyLogo}" />
      </td>
    </tr>`
    : '';

  return ` <!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <title>Pathline</title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style type="text/css">
      .email-template-scope * {
        font-family: "arial";
      }

      .email-template-scope body {
        margin: 0;
        background-color: red;
      }

      .email-template-scope table {
        border-spacing: 0;
        width: 100%;
      }

      .email-template-scope p, li, span {
        font-size: 13px;
      }

      .email-template-scope img {
        border: 0;
        height: auto;
        display: block;
      }

      .email-template-scope a {
        padding: 3px;
      }

      .email-template-scope .wrapper {
        width: 100%;
        table-layout: fixed;
        background-color: #EFEFEF;
        padding-bottom: 60px;
      }

      .email-template-scope .main {
        background-color: #ffffff;
        margin: 0 auto;
        max-width: 600px;
        border-spacing: 0;
        font-family: sans-serif;
        color: #4a4a4a;
      }

      .email-template-scope .coordonnees {
        font-size: 12px;
        color: #585858;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        padding-top: 10px;
        border: 50%;
      }

      .email-template-scope .border {
        border-top: 2px solid #c3c2c2;
        width: 60%;
        margin-left: 17%;
      }

      .email-template-scope .border_full {
        border: 1px solid #c3c2c2;
      }

      .email-template-scope .row {
        padding: 30px;
        line-height: 16px;
        font-size: 11px;
        color: #585858;
        font-family: Tahoma, Geneva, sans-serif;
        position: relative;
        text-align: justify;
      }

      .email-template-scope .logo {
        padding: 30px 0px 0px 30px;
      }

      .email-template-scope .row-text {
        padding: 0px 0px 0px 30px;
      }

      .email-template-scope .footer-logo {
        border: 2px solid red;
        text-align: center;
        vertical-align: middle;
        width: 86px
      }

      .email-template-scope p:empty {
        display: block;
        min-height: 1em;
      }
    </style>
  </head>
  <body>
    <center class="wrapper">
      <table class="main" width="600">
        <tr>
          <td style="border:4px solid ${companyColor}; background-color:${companyColor}"></td>
        </tr>
        <tr>
          <td style="padding:0px">
            <img width="600" title="Banner" src="${header || DEFAULT_HEADER}" />
          </td>
        </tr>
        ${companyLogoHtml}
        <tr>
          <td class="row-text">
            ${content}
          </td>
        </tr>
        ${footer}
      </table>
    </center>
  </body>
</html> 
`;
};
