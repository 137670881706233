import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

export const ThemeStatusChip = ({ status, style = {} }) => {
  const { t, i18n } = useTranslation();

  const statusToString = status?.toString();

  const i18nStatusKey = `theme_status_chip.${statusToString}`;

  const statusLabel = i18n.exists(i18nStatusKey) ? t(i18nStatusKey) : statusToString;

  const statusColor = {
    true: '#067647',
    false: '#344054',
  };

  const bgColor = {
    true: '#ECFDF3',
    false: '#F2F2F2',
  };

  const borderColor = {
    true: '#0A7A3D99',
    false: '#32313B99',
  };

  return (
    <Chip
      variant="outlined"
      label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{statusLabel}</Box>}
      style={{
        backgroundColor: bgColor[statusToString],
        color: statusColor[statusToString],
        borderColor: borderColor[statusToString],
        minWidth: '48px',
        height: '20px',
        fontSize: '12px',
        ...style,
      }}
      sx={{ '.MuiChip-label': { paddingLeft: '8px', paddingRight: '8px' } }}
    />
  );
};
