import { get, put, post, del } from './request';

class Theme {
  static create(data) {
    return post('/themes', data).then((res) => res.data);
  }

  static duplicate(themeId) {
    return post(`/themes/${themeId}/duplicate`).then((res) => res.data);
  }

  static getById(themeId) {
    return get(`/themes/${themeId}`).then((res) => res.data);
  }

  static list() {
    return get('/themes').then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/themes/${id}`, data).then((res) => res.data);
  }

  static delete(id) {
    return del(`/themes/${id}`).then((res) => res.data);
  }
}

export default Theme;
