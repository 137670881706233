import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// UI Local Lib dependencies
import DataGrid from 'ui/components/DataGrid';
import DeleteModal from '../../../DeleteModal';
import CreateTheme from './components/Modals/CreateTheme';
import EditTheme from './components/Modals/EditTheme';

// Hooks
import useThemes from 'hooks/usecase/useThemes';

// Configs
import { tableSchemaBase as columns } from './Themes.config';

// Icons
import AddIcon from '@mui/icons-material/Add';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { parseJsonTranslate } from 'utils/parse';

// Global State
import useStore from 'store';

// Styles
import styles from './Themes.styles';

const Themes = ({ user }) => {
  // States
  const [queryParams, setQueryParams] = useState({ page: 1, size: 5 });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { companyId } = useParams();
  // Hook
  const {
    themes,
    control,
    isLoading,
    onRemoveTheme,
    onAddTheme,
    isOpenAddModal,
    setIsOpenAddModal,
    reset,
    submitEditMarketingPicture,
    submitEditBackgroundPicture,
    handleCloseModalAdd,
    themeAdded,
    watch,
    onEditTheme,
    setEditingTheme,
    editingTheme,
    onDuplicateTheme,
    tabOptions,
    setTabOptions,
    setSelectedTabCreate,
    selectedTabCreate,
    selectedTabEdit,
    setSelectedTabEdit,
    tabOptionsEdit,
    setTabOptionsEdit,
  } = useThemes(companyId);

  const {
    completThemes,
    themes: themesState,
    setThemes,
    currentPage,
    size,
    setSize,
    setCurrentPage,
    totalPages,
    setCompleteThemes,
    setTotalPages,
    setAllThemes,
    allThemes,
  } = useStore();

  // Refs

  // Helper
  const updateList = (companies) =>
    companies.reduce((acc, currentItem) => {
      if (currentItem.companyId === companyId) {
        const modifiedItem = {
          ...currentItem,
          active: JSON.stringify(currentItem.active).toUpperCase(),
          marketingPicture: currentItem.marketingPicture + `?${Date.now()}`,
        };
        acc.push(modifiedItem);
      }

      return acc;
    }, []);

  function getPaginatedItems(items, pageSize, pageNumber) {
    let filteredItems = items;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    return paginatedItems;
  }

  function calculateTotalPages(items, itemsPerPage) {
    // Vérifier si les paramètres sont valides
    if (!Array.isArray(items) || itemsPerPage < 1) {
      return 0;
    }

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(items.length / itemsPerPage);

    return totalPages;
  }

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    // zustand : Filter using state management
    if (!queryParamsFilter.name) {
      setCompleteThemes(allThemes, size);
      setThemes(allThemes.slice(0, size));
      setTotalPages(calculateTotalPages(allThemes, size));
    } else {
      const filteredItems = allThemes.filter(
        (item) =>
          item.name
            .toLowerCase()
            .includes(
              queryParamsFilter.name && queryParamsFilter.name !== undefined ? queryParamsFilter.name.toLowerCase() : ''
            ) && item.companyId === companyId
      );
      const startIndex = (currentPage - 1) * size;
      const endIndex = startIndex + size;
      const paginatedItems = filteredItems.slice(startIndex, endIndex);
      setTotalPages(calculateTotalPages(filteredItems, size));
      setThemes(paginatedItems);
      setCompleteThemes(filteredItems, size);
    }
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const handleOpenModal = () => {
    setIsOpenAddModal(true);
    setSelectedTab('tab-1');
  };

  const handleCloseModal = () => {
    setIsOpenAddModal(false);
    reset();
  };

  const handleCloseEditModal = () => {
    setEditingTheme(false);
    reset({
      name: '',
      active: false,
      groupLabel: '',
      type: 'MENTORING',
      nbSessions: 0,
      content1: '',
      content2: '',
      content3: '',
      description: '<p></p>',
      marketingPicture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/2560px-Placeholder_view_vector.svg.png',
      backgroundPicture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/2560px-Placeholder_view_vector.svg.png',
    });
  };

  useEffect(() => {
    if (themes) {
      setThemes(updateList(themes).slice(0, 10));
      setCompleteThemes(updateList(themes), size);
      setAllThemes(updateList(themes), size);
    }
  }, [themes]);

  // zustand : Reset stat management page size
  useEffect(() => {
    return () => {
      setSize(10);
      setCurrentPage(1);
    };
  }, []);

  const themesStateTranslated = themesState?.map((theme) => {
    return { ...theme, name: parseJsonTranslate(theme.name) };
  });

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.containerAddButton}>
        <Button endIcon={<AddIcon />} onClick={handleOpenModal} sx={styles.addButton}>
          {t('add')}
        </Button>
      </Stack>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        // rows={themes ? updateList(themes) : []}
        rows={themes ? themesStateTranslated : []}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onShow={() => {}}
        onEdit={(theme) => {
          setSelectedTab('tab-1');
          navigate(`/customizaton/theme/edit/${theme.id}`);
        }}
        onEditOld={(theme) => {
          setSelectedTab('tab-1');
          setEditingTheme(theme);
        }}
        onRowClicked={(theme, event) => {
          if (!event.defaultMuiPrevented) {
            setSelectedTab('tab-1');
            navigate(`/customizaton/theme/edit/${theme.id}`);
          }
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onDuplicate={(data) => {
          onDuplicateTheme(data.id);
        }}
        totalItems={themes ? updateList(completThemes).length : 10}
        totalPages={totalPages ? totalPages : 1}
        onPaginationChange={(page, nbrPages) => {
          setSize(nbrPages);
          setThemes(getPaginatedItems(completThemes, nbrPages, page));
          setTotalPages(calculateTotalPages(completThemes, nbrPages));
        }}
        loading={isLoading}
      />
      {/* Add modal */}
      <CreateTheme
        themeAdded={themeAdded}
        companyId={companyId}
        control={control}
        watch={watch}
        reset={reset}
        isOpen={isOpenAddModal}
        setIsOpen={handleCloseModal}
        onAddTheme={onAddTheme}
        submitEditBackgroundPicture={submitEditBackgroundPicture}
        submitEditMarketingPicture={submitEditMarketingPicture}
        selectedTab={selectedTabCreate}
        setSelectedTab={setSelectedTabCreate}
        tabOptions={tabOptions}
        setTabOptions={setTabOptions}
        handleClose={handleCloseModalAdd}
        user={user}
      />
      {/* Edit modal */}
      <EditTheme
        companyId={companyId}
        control={control}
        watch={watch}
        reset={reset}
        isOpen={editingTheme}
        setIsOpen={handleCloseEditModal}
        submitEditBackgroundPicture={submitEditBackgroundPicture}
        submitEditMarketingPicture={submitEditMarketingPicture}
        selectedTab={selectedTabEdit}
        setSelectedTab={setSelectedTabEdit}
        onEditTheme={onEditTheme}
        tabOptions={tabOptionsEdit}
        setTabOptions={setTabOptionsEdit}
        user={user}
      />

      <DeleteModal
        title="Delete theme"
        description="Are you sure you want to delete this theme"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemoveTheme(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

export default Themes;
