const deleteLanguagePropertiesInForm = (language, translatedProperties, watch, setValue) => {
  for (const property of translatedProperties) {
    const value = watch(property);
    delete value[language];

    setValue(property, value);
  }
};

const addLanguagePropertiesInForm = (language, translatedProperties, watch, setValue) => {
  if (!language) return;

  for (const property of translatedProperties) {
    let value = watch(property) || {};
    value = typeof value !== 'object' ? {} : value;
    value[language] = '';

    setValue(property, value);
  }
};

const setValueLanguageInForm = (language, property, content, watch, setValue) => {
  if (!language) return;

  let value = watch(property) || {};
  value = typeof value !== 'object' ? {} : value;
  value[language] = content;

  setValue(property, value);
};

export { addLanguagePropertiesInForm, deleteLanguagePropertiesInForm, setValueLanguageInForm };
