import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import styles from './ContainerEmpty.styles';

const ContainerEmpty = ({ message, children }) => {
  const theme = useTheme();
  const themedStyles = styles(theme);

  return (
    <Box sx={themedStyles.container}>
      <Typography sx={themedStyles.message}>{message}</Typography>
      {children}
    </Box>
  );
};

export default ContainerEmpty;
