import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useMeQuery, useListFormatThemeQuery } from 'hooks/queries';
import ThemesCard from 'ui/pages/home/components/ThemesCard/ThemesCard';
import RolesDropdown from 'ui/components/RolesDropdown/RolesDropdown';
import { useTranslation } from 'react-i18next';

import styles from './ThemesWithPrograms.styles';

const ThemesWithPrograms = () => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  const { data: listThemeData, isLoading: isLoadingThemes } = useListFormatThemeQuery(user.company.id);

  return (
    <Stack sx={styles.container}>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Typography sx={styles.welcomeTitle}>{t('home_page.user.title', { userName: user.firstname })}</Typography>

        <RolesDropdown user={user} />
      </Box>

      <ThemesCard
        sxCard={{ height: 'calc(100vh - 200px)' }}
        isAuthorizeScroll
        isLoading={isLoadingThemes}
        themes={listThemeData}
      />
    </Stack>
  );
};

export default ThemesWithPrograms;
