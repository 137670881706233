const styles = {
  card: {
    maxHeight: '312px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    overflowY: 'scroll',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid',
    borderColor: 'dashboard.borderSecondary',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    height: '32px',
    alignContent: 'center',
  },
  line: {
    alignItems: 'center',
    fontSize: '14px',
    padding: '4px 8px',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    display: 'flex',

    '&:hover': {
      backgroundColor: 'card.selected',
      cursor: 'pointer',
      color: 'primary.main',
    },
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'auto',
  },
  buttonGoTo: {
    height: '24px',
    padding: '0',
    width: '24px',
    minWidth: '24px !important',
  },
  buttonGoToDetail: {
    boxSizing: 'content-box',
    padding: '0 2px !important',
    height: '22px',
    background: 'white',
    borderColor: 'primary.main',
    color: 'primary.main',

    '&:hover': {
      background: 'white',
      color: 'primary.main',
    },
  },
};

export default styles;
