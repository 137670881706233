import React, { useRef, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { NOT_SELECTED } from 'litterals';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import parse from 'html-react-parser';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const CoachCardMini = ({
  id,
  adminNote,
  firstname,
  lastname,
  picture,
  onClickDelete,
  onClick,
  sx = {},
  isDataGrid,
}) => {
  const { t } = useTranslation();
  const chipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const measureTextWidth = (text) => {
      const tempElement = document.createElement('span');

      tempElement.innerText = text;
      document.body.appendChild(tempElement);
      const width = tempElement.offsetWidth;
      document.body.removeChild(tempElement);
      return width + 40;
    };

    if (chipRef.current) {
      const chipElement = chipRef.current;
      const combinedWidth = measureTextWidth(`${firstname} ${lastname}`);
      if (adminNote) {
        setIsTooltipVisible(true);
      } else {
        const isOverflowing = parseInt(chipElement.clientWidth, 10) < parseInt(combinedWidth, 10);
        setIsTooltipVisible(isOverflowing);
      }
    }
  }, [firstname, lastname]);

  const tooltipTitle = () => {
    if (isTooltipVisible) {
      if (adminNote) return parse(adminNote);

      return `${firstname} ${lastname}`;
    }

    return '';
  };

  const name = `${firstname} ${lastname}`;
  const bgcolor = stringToColor(name);
  const initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase();

  return (
    <>
      {isDataGrid ? (
        <>
          {' '}
          {isTooltipVisible ? (
            <Tooltip title={tooltipTitle()} arrow>
              <Chip
                ref={chipRef}
                label={
                  firstname || lastname ? `${firstname ? firstname : ''} ${lastname ? lastname : ''}` : t(NOT_SELECTED)
                }
                onDelete={onClick}
                color="info"
                sx={{ height: '30px', margin: '3px 0', ...sx }}
                avatar={<Avatar src={picture} />}
                variant="outlined"
                onClick={onClick}
                style={{
                  color: '#000000DE',
                  border: 'none',
                  minWidth: '10vw',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                deleteIcon={<RemoveRedEyeIcon fontSize="small" style={{ color: theme.palette.primary.main }} />}
              />
            </Tooltip>
          ) : (
            <Tooltip title={isTooltipVisible ? `${firstname} ${lastname}` : ''} arrow>
              <Chip
                ref={chipRef}
                label={
                  firstname || lastname ? `${firstname ? firstname : ''} ${lastname ? lastname : ''}` : t(NOT_SELECTED)
                }
                onDelete={onClick}
                color="info"
                sx={{ height: '30px', margin: '3px 0', ...sx }}
                avatar={<Avatar src={picture} />}
                variant="outlined"
                onClick={onClick}
                style={{
                  color: '#000000DE',
                  border: 'none',
                  minWidth: '10vw',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                deleteIcon={<RemoveRedEyeIcon fontSize="small" style={{ color: theme.palette.primary.main }} />}
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Chip
            ref={chipRef}
            label={
              firstname || lastname ? `${firstname ? firstname : ''} ${lastname ? lastname : ''}` : t(NOT_SELECTED)
            }
            color="info"
            sx={{ height: '30px', margin: '3px 0', '& .MuiChip-avatar': { color: 'white' }, ...sx }}
            variant="outlined"
            avatar={picture ? <Avatar src={picture} /> : <Avatar sx={{ bgcolor, color: 'white' }}>{initials}</Avatar>}
            onDelete={onClickDelete ? () => onClickDelete(id) : undefined}
          />
        </>
      )}
    </>
  );
};

export default CoachCardMini;
